import React from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GenericFileUpload } from "../../controls/forms/GenericFileUpload";

export const DocumentActionFormatter = (
  cellContent,
  rowData,
  rowIndex,
  {
    handleDownload,
    workflow,
    productCode,
    handleDocumentFetch,
    handleFileSelected,
    uploadingFile,
    isAllowUpload,
    acceptFileType,
    maxSizeOfFile,
    handleDelete,
    userId,
    permissionsObject,
    isWorkflowRoute,
    isEligibleForReUploadMyDocument,
    isEligibleForReUploadOthersDocument,
    isDocumentPermissionBased
  }
) => {
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex flex-row">
        {rowData?.versionCount > 0 && rowData?.versionCount < 2 ? (
          <>
            <OverlayTrigger
              overlay={<Tooltip id="download-tooltip">View</Tooltip>}
            >
              <button
                type="button"
                onClick={() => {
                  handleDownload(rowData, 1, true);
                }}
                className={`btn btn-icon btn-primary btn-sm mb-0 mr-2`}
              >
                <i className="fas fa-eye text-white" />
              </button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={<Tooltip id="download-tooltip">Download</Tooltip>}
            >
              <button
                type="button"
                onClick={() => {
                  handleDownload(rowData, 1);
                }}
                className={`btn btn-icon btn-primary btn-sm mb-0 mr-2`}
              >
                <i className="fas fa-file-download text-white" />
              </button>
            </OverlayTrigger>
          </>
        ) : rowData?.versionCount > 1 ? (
          <>
            <OverlayTrigger
              overlay={<Tooltip id="download-tooltip">View</Tooltip>}
            >
              <Dropdown drop={"start"}>
                <Dropdown.Toggle
                  bsPrefix="dropdown-toggle-none"
                  className={`btn btn-icon btn-primary btn-sm mb-0 mr-2`}
                  id={rowData?.code}
                >
                  <i className="fas fa-eye text-white" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {new Array(rowData?.versionCount)
                    .fill(undefined)
                    .map((value, index) => {
                      const versionNumber = index + 1;
                      const isDisabled = rowData?.deletedVersions.includes(
                        versionNumber
                      );
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            if (!isDisabled) {
                              handleDownload(rowData, index + 1, true);
                            }
                          }}
                          style={{
                            pointerEvents: isDisabled ? "none" : "auto",
                            opacity: isDisabled ? 0.6 : 1
                          }}
                        >
                          {isDisabled
                            ? `Version ${versionNumber} (Deleted)`
                            : `Version ${versionNumber}`}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={<Tooltip id="download-tooltip">Download</Tooltip>}
            >
              <Dropdown drop={"start"}>
                <Dropdown.Toggle
                  bsPrefix="dropdown-toggle-none"
                  className={`btn btn-icon btn-primary btn-sm mb-0 mr-2`}
                  id={rowData?.code}
                >
                  <i className="fas fa-file-download text-white" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {new Array(rowData?.versionCount)
                    .fill(undefined)
                    .map((value, index) => {
                      const versionNumber = index + 1;
                      const isDisabled = rowData?.deletedVersions.includes(
                        versionNumber
                      );
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            if (!isDisabled) {
                              handleDownload(rowData, index + 1);
                            }
                          }}
                          style={{
                            pointerEvents: isDisabled ? "none" : "auto",
                            opacity: isDisabled ? 0.6 : 1
                          }}
                        >
                          {isDisabled
                            ? `Version ${versionNumber} (Deleted)`
                            : `Version ${versionNumber}`}
                        </Dropdown.Item>
                      );
                    })}
                </Dropdown.Menu>
              </Dropdown>
            </OverlayTrigger>
          </>
        ) : null}

        {isAllowUpload &&
        (isDocumentPermissionBased
          ? rowData?.ownership === userId
            ? isEligibleForReUploadMyDocument
            : isEligibleForReUploadOthersDocument
          : true) ? (
          <GenericFileUpload
            code={rowData?.code}
            handleFileSelected={handleFileSelected}
            uploadingFile={uploadingFile}
            acceptFileType={acceptFileType}
            maxSizeOfFile={maxSizeOfFile}
            groupCode={rowData?.groupCode}
            version={rowData?.versionCount}
          />
        ) : null}

        {isWorkflowRoute &&
          permissionsObject?.deletedocument &&
          rowData?.createdBy === userId &&
          (rowData?.versionCount > 1 ? (
            <>
              <OverlayTrigger
                overlay={<Tooltip id="download-tooltip">Delete</Tooltip>}
              >
                <Dropdown drop={"start"}>
                  <Dropdown.Toggle
                    bsPrefix="dropdown-toggle-none"
                    className={`btn btn-icon btn-danger btn-sm mb-0 ml-2`}
                    id={rowData?.code}
                  >
                    <i className="fa fa-trash text-white" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {new Array(rowData?.versionCount)
                      .fill(undefined)
                      .map((value, index) => {
                        const versionNumber = index + 1;
                        const isDisabled = rowData?.deletedVersions.includes(
                          versionNumber
                        );
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              if (!isDisabled) {
                                handleDelete(rowData, index + 1);
                              }
                            }}
                            style={{
                              pointerEvents: isDisabled ? "none" : "auto",
                              opacity: isDisabled ? 0.6 : 1
                            }}
                          >
                            {isDisabled
                              ? `Version ${versionNumber} (Deleted)`
                              : `Version ${versionNumber}`}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </OverlayTrigger>
            </>
          ) : (
            <button
              type="button"
              onClick={() => {
                handleDelete(rowData);
              }}
              className={`btn btn-icon btn-danger btn-sm mb-0 ml-2`}
            >
              <i className="fa fa-trash text-white" />
            </button>
          ))}
      </div>
    </div>
  );
};
