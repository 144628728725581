import React, { useEffect, useState } from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import { Overlay } from "../../controls/forms/Overlay";
import { FormulaViewer } from "../../Dialog/FormulaViewer";
import {capitalizeString} from "../../../_helpers";

const getFieldCSSClasses = (
  touched,
  errors,
  isHideValidations,
  field,
  prevState
) => {
  const classes = ["form-control"];

  if (!isHideValidations) {
    if (touched && errors) {
      classes.push("is-invalid");
    }

    if (touched && !errors) {
      classes.push("is-valid");
    }
  }

  if (prevState && IsValuesDifferent(prevState[field?.name], field?.value)) {
    classes.push("field-modified");
  }

  return classes.join(" ");
};

const IsValuesDifferent = (previousValue, newValue) => {
  if (!previousValue && !newValue) return false;

  if (!previousValue && newValue) return true;

  if (previousValue && !newValue) return true;

  if (previousValue !== newValue) return true;

  return false;
};

export function Input({
  form,
  field, // { name, value, onChange, onBlur }
  // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  defaultValue,
  description,
  meta,
  disabled,
  isReadOnly,
  classes,
  showDescription,
  prevState,
  setFieldValue,
  ...props
}) {
  const { touched, error } = meta;
  const [showComputationDetails, setShowComputationDetails] = useState(false);
  const [displayValue, setDisplayValue] = useState("");
  const [actualValue, setActualValue] = useState([]);

  useEffect(() => {
    if (
      (props.tags || []).find(
        tag => tag === "cardNumberMasking" || tag.code === "cardNumberMasking"
      ) !== undefined || (props.tags || []).find(
            tag => tag === "cardZoneNumberMasking" || tag.code === "cardZoneNumberMasking"
        ) !== undefined
    ) {
      let makeItString = actualValue.join("");
      setFieldValue(field?.name, makeItString);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualValue, displayValue]);

  useEffect(() => {
    if (
      (props.tags || []).find(
        tag => tag === "cardNumberMasking" || tag.code === "cardNumberMasking"
      ) !== undefined || (props.tags || []).find(
            tag => tag === "cardZoneNumberMasking" || tag.code === "cardZoneNumberMasking"
        ) !== undefined
    ) {
      if (!field?.value) {
        setFieldValue(field?.name, field?.value);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getFieldValue = value => {
    if (value === undefined) return "";

    if (
      (props.tags || []).find(
        tag => tag === "cardNumberMasking" || tag?.code === "cardNumberMasking"
      ) !== undefined || (props.tags || []).find(
            tag => tag === "cardZoneNumberMasking" || tag?.code === "cardZoneNumberMasking"
        ) !== undefined
    ) {
      if (!actualValue?.length > 0 && value) {
        setActualValue(value.split(""));
      }
      if (!displayValue && value) {
        setDisplayValue(maskFunction(value));
      }
      return displayValue;
    }

    if (
      (props.tags || []).find(
        tag =>
          tag?.code === "CapitalAlphabetsOnlyWithDotDash" ||
          tag === "CapitalAlphabetsOnlyWithDotDash" ||
          tag === "convertToUpperCase" ||
          tag?.code === "convertToUpperCase"
      )
    ) {
      return value?.toUpperCase();
    }
    if (
        (props.tags || []).find(
            tag =>
                tag?.code === "capitalized" ||
                tag === "capitalized"
        )
    ) {
      return capitalizeString(value);
    }

    return type === "text" || props?.name === "code" ? value || "" : value;
  };

  const decimalTagsLimitations = tags => {
    let limitation = {
      min: undefined,
      wholeNumber: undefined
      // max: undefined
    };
    if (
      (tags || []).find(tag => tag === "Decimal" || tag.code === "Decimal") !==
      undefined
    ) {
      limitation["min"] = 0;
    }
    if (
      (tags || []).find(
        tag => tag === "WholeNumber" || tag.code === "WholeNumber"
      ) !== undefined
    ) {
      limitation["wholeNumber"] = true;
    }
    return limitation;
  };

  const { min, wholeNumber } = decimalTagsLimitations(props?.tags);

  const openLink = () => {
    window.open(
      !field?.value?.includes("https://")
        ? `https://${field?.value}`
        : field?.value,
      "_blank"
    );
  };

  let linkTag = (props?.tags || []).some(tag => tag?.code === "Link");

  const maskFunction = (value) => {
    const maskDigits = (str, start, end) => {
      return str.slice(0, start) + str.slice(start, end).replace(/\d/g, "x") + str.slice(end);
    };

    const maskingRules = {
      cardNumberMasking: { start: 4, end: 12 },
      cardZoneNumberMasking: { start: 6, end: 12 }
    };

    const tag = (props.tags || []).find(
        tag => maskingRules[tag] || maskingRules[tag?.code]
    );

    if (tag) {
      const { start, end } = maskingRules[tag] || maskingRules[tag?.code];
      return maskDigits(value, start, end);
    }

    return value;
  };


  return (
    <div className={linkTag ? "link-field" : ""}>
      {label && <label>{label}</label>}
      {props.required ? <span className="text-danger"> *</span> : null}
      {showDescription ? (
        <Overlay popoverContent={description}>
          <i
            className={
              "fas fa-info-circle icon-nm text-hover-primary mr-n2 float-right"
            }
          />
        </Overlay>
      ) : null}

      {prevState && IsValuesDifferent(prevState[field?.name], field?.value) ? (
        <span className="field-modified-tag">Modified</span>
      ) : null}

      <input
        type={type}
        className={`${getFieldCSSClasses(
          touched,
          error,
          props.isHideValidations,
          field,
          prevState
        )} ${classes} ${type === "number" ? " noscroll" : ""} ${linkTag &&
          "link-input"}`}
        {...field}
        {...props}
        min={min}
        disabled={
          isReadOnly ||
          disabled ||
          field.disabled ||
          (props?.name === "defaultValue" &&
            form?.values["defaultValueType"] === 1)
        }
        value={
          (props?.name ? props.overrideDefaultValues?.[props?.name] : "") ||
          getFieldValue(
            field.value || field.value === 0 || field.value === "0"
              ? field.value
              : ""
          )
        }
        defaultValue={
          (props?.name ? props.overrideDefaultValues?.[props?.name] : "") ||
          defaultValue
        }
        onChange={event => {
          const inputText = event.target.value;
          const capitalAlphabet = (props.tags || []).some(
            tag =>
              tag?.code === "CapitalAlphabetsOnlyWithDotDash" ||
              tag === "CapitalAlphabetsOnlyWithDotDash" ||
              tag === "convertToUpperCase" ||
              tag?.code === "convertToUpperCase"
          );

          const newValue = capitalAlphabet
            ? (inputText || "").toUpperCase()
            : inputText;
          event.target.value = newValue;

          const percentage = (props.tags || []).find(
              tag =>
                  tag === "percentage" || tag?.code === "percentage"
          );

          if (percentage) {
            if (event?.nativeEvent?.inputType === "deleteContentBackward" ) {
              event.target.value = newValue
            } else {
              event.target.value = Number(((newValue || 0) * 100).toFixed(2));
            }
          }

          if (props?.copyValueTo && props?.setOverrideDefaultValues) {
            props.setOverrideDefaultValues({
              ...(props?.overrideDefaultValues || {}),
              [props.copyValueTo]: newValue
            });
          }

          const isCardNumberMasking = (props.tags || []).find(
            tag =>
              tag === "cardNumberMasking" || tag?.code === "cardNumberMasking"
          );
          const isCardZoneNumberMasking = (props.tags || []).find(
            tag =>
              tag === "cardZoneNumberMasking" || tag?.code === "cardZoneNumberMasking"
          );

          if (isCardNumberMasking || isCardZoneNumberMasking) {
            if (newValue?.length > actualValue?.length) {
              // Get the last character and update the array
              const lastCharacter = newValue.charAt(newValue.length - 1);
              setActualValue([...actualValue, lastCharacter]);
            }
            if (newValue?.length < actualValue?.length) {
              const numCharsToRemove = actualValue.length - newValue.length;
              setActualValue(prevNumericKeys =>
                prevNumericKeys.slice(0, -numCharsToRemove)
              );
            }
            setDisplayValue(maskFunction(newValue));
          }

          return props.onChange(event);
        }}
        onKeyPress={event => {
          if (type === "number") {
            if (wholeNumber && event?.charCode === 46) {
              event.preventDefault();
            }

            if (event?.charCode === 101 || event?.charCode === 69) {
              event.preventDefault();
            }
          }
        }}
      />

      {props.mathematicalExpression?.formula ? (
        <>
          <button
            onClick={() => setShowComputationDetails(true)}
            className="formula-icon"
          >
            <Overlay popoverContent="View Computations">
              <i className="fas fa-calculator" />
            </Overlay>
          </button>

          <FormulaViewer
            show={showComputationDetails}
            onHide={() => setShowComputationDetails(false)}
            mathematicalExpression={props.mathematicalExpression}
            label={label}
          />
        </>
      ) : null}

      {linkTag && field?.value?.length > 0 && (
        <button
          onClick={openLink}
          className="external-link"
          style={{ top: `${error && touched ? "42%" : "55%"}` }}
          disabled={!!error}
        >
          <i
            className={`fas fa-external-link-alt ${
              error ? "text-dark-60" : "text-primary"
            } `}
          />
        </button>
      )}

      {prevState && IsValuesDifferent(prevState[field?.name], field?.value) ? (
        <p className="pt-1 mb-0">
          <span style={{ fontWeight: "bolder" }}>Before: </span>
          <span>
            {prevState?.[field?.name] || (
              <i style={{ fontSize: "13px" }}>No Data</i>
            )}
          </span>
        </p>
      ) : null}

      {!props.isHideValidations && withFeedbackLabel && touched && error && (
        <FieldFeedbackLabel
          error={error}
          touched={touched}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </div>
  );
}
