import React, { useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { imageExtensions } from "../../../_helpers/enums";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import FileViewer from "react-file-viewer-multi";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SunEditor from "suneditor-react";
import {
  addDocumentComments,
  fetchDocumentComments
} from "../../../../app/modules/Workflows/_redux/actions";
import { useDispatch } from "react-redux";
import { errorNotification, successNotification } from "../../notifications";

export function Preview({
  viewingDocumentId,
  viewDocument,
  viewingDocumentExtension,
  viewType,
  item,
  handleOpenDocumentInNewTab,
  handleOpenDocumentInNewWindow,
  documentVersion,
  showFullScreenButton = true,
  showNewTabButton = true,
  showNewWindowButton = true
}) {
  const [zoom, setZoom] = useState({});
  const [rotation, setRotation] = useState({});
  const [flag, setFlag] = useState(false);
  const [fullScreen, setFullScreen] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [showGif, setShowGif] = useState({});
  const [rotateGif, setRotateGif] = useState({});
  const [editorVisible, setEditorVisible] = useState(false);
  const [comments, setComments] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (viewDocument) {
      const params = {
        identifier: viewDocument?.id,
        paginationOverride: true
      };
      dispatch(fetchDocumentComments(params))
        .then(response => {
          const matchingItem = response?.data?.items.find(
            item => item?.versionNumber === documentVersion
          );

          const fetchedComments = matchingItem?.notes || "N/A";
          setComments(fetchedComments);
        })
        .catch(error => {
          console.error("Failed to fetch document comments", error);
        });
    }
    // eslint-disable-next-line
  }, [viewDocument, documentVersion]);

  const toggleEditor = () => {
    setEditorVisible(!editorVisible);
  };

  const handleSaveComments = () => {
    setButtonLoader(true);

    const params = {
      versionCount: documentVersion,
      identifier: viewDocument?.id,
      data: { comments }
    };

    dispatch(addDocumentComments(params))
      .then(response => {
        if (response?.status === 200) {
          successNotification("Comments saved successfully");
          setButtonLoader(false);
        }
      })
      .catch(error => {
        errorNotification("Failed to save comments");
        setButtonLoader(false);
      });
  };

  const handleBulbClick = docId => {
    setShowGif(prev => ({ ...prev, [docId]: true }));

    setTimeout(() => {
      setRotateGif(prev => ({ ...prev, [docId]: true }));

      setTimeout(() => {
        setShowGif(prev => ({ ...prev, [docId]: false }));
        setRotateGif(prev => ({ ...prev, [docId]: false }));
      }, 4000);
    }, 2000);
  };

  useEffect(() => {
    if (viewDocument) {
      if (!fullScreen[viewingDocumentId]) {
        FullScreen();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewDocument]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onZoom = type => {
    let currentScale = Number(zoom[viewingDocumentId] || 1);
    if (type === "In") {
      currentScale += 0.5;
    } else {
      currentScale -= 0.5;
    }
    let zooms = {
      [viewingDocumentId]: currentScale < 1 ? 1 : currentScale
    };
    setZoom(zooms);
  };

  const FullScreen = () => {
    setFlag(!flag);
    const fullSize = {
      [item ? item.viewId : viewingDocumentId]: !flag
    };
    setFullScreen(fullSize);
  };

  const onRotate = () => {
    const currentRotation =
      Number(rotation[item ? item.viewId : viewingDocumentId] || 0) + 90;
    const rotations = {
      [item ? item.viewId : viewingDocumentId]:
        currentRotation === 360 ? 0 : currentRotation
    };
    setRotation(rotations);
  };
  const scaleValue = zoom[viewingDocumentId] ? zoom[viewingDocumentId] : "";
  const isPDF = (item?.viewExtension || viewingDocumentExtension) === "pdf";
  return (
    <div
      className="card card-custom card-border w-100 mb-5"
      style={
        fullScreen[item ? item.viewId : viewingDocumentId] === true
          ? { gridColumn: "1/3" }
          : {}
      }
    >
      <div className="card-header">
        <div
          className="card-title"
          style={{ maxWidth: "65%", minHeight: "70px" }}
        >
          {item ? (
            <h3
              className="card-label "
              style={{
                display: "inline-block",
                minWidth: "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%"
              }}
            >
              {item?.name}
            </h3>
          ) : (
            <h3
              className="card-label"
              style={{
                display: "inline-block",
                minWidth: "auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%"
              }}
            >
              {viewDocument?.group}
              <br />
              <small>{viewDocument?.name}</small>
              {documentVersion && (
                <span className="label label-inline label-light-primary ml-2">
                  Version {documentVersion}
                </span>
              )}
            </h3>
          )}
        </div>
        <div className="card-toolbar">
          {isPDF && (
            <OverlayTrigger overlay={<Tooltip id="">Click Me</Tooltip>}>
              <div
                onClick={() =>
                  handleBulbClick(item?.viewId || viewingDocumentId)
                }
                className="ripple-circle btn btn-link text-secondary mr-4"
              >
                <i
                  className="fas fa-lightbulb icon-md"
                  style={{
                    color: "#FFD43B",
                    marginLeft: "2px",
                    marginTop: "2px"
                  }}
                ></i>
              </div>
            </OverlayTrigger>
          )}

          {showFullScreenButton && (
            <OverlayTrigger
              overlay={<Tooltip id="fullscreen-tooltip">Full Screen</Tooltip>}
            >
              <div
                onClick={() => FullScreen()}
                className="btn btn-xs btn-icon btn-success  mr-2"
              >
                {fullScreen[item ? item.viewId : viewingDocumentId] ? (
                  <i className="fa fa-arrows-alt" aria-hidden="true"></i>
                ) : (
                  <i className="fas fa-expand"></i>
                )}
              </div>
            </OverlayTrigger>
          )}

          <OverlayTrigger
            overlay={<Tooltip id="ZoomIn-tooltip">Zoom In</Tooltip>}
          >
            <div
              onClick={() => onZoom("In")}
              className="btn btn-xs btn-icon btn-success  mr-2"
            >
              <i className="fa fa-search-plus" aria-hidden="true"></i>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={<Tooltip id="ZoomOut-tooltip">Zoom Out</Tooltip>}
          >
            <div
              onClick={() => onZoom("out")}
              className="btn btn-xs btn-icon btn-info  mr-2"
            >
              <i className="fa fa-search-minus" aria-hidden="true"></i>
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={<Tooltip id="rotate-tooltip">Rotate</Tooltip>}
          >
            <div
              onClick={() => onRotate()}
              className="btn btn-xs btn-icon btn-danger"
            >
              <i className="fas fa-sync" aria-hidden="true"></i>
            </div>
          </OverlayTrigger>

          {showNewTabButton && (
            <OverlayTrigger
              overlay={
                <Tooltip id="fullscreen-tooltip">Open in new tab</Tooltip>
              }
            >
              <div
                onClick={() =>
                  handleOpenDocumentInNewTab(
                    viewDocument || item,
                    documentVersion
                  )
                }
                className="btn btn-xs btn-icon btn-primary ml-2"
              >
                <i className="fas fa-external-link-alt ml-1"></i>
              </div>
            </OverlayTrigger>
          )}

          {showNewWindowButton && (
            <OverlayTrigger
              overlay={
                <Tooltip id="fullscreen-tooltip">Open in new window</Tooltip>
              }
            >
              <div
                onClick={() =>
                  handleOpenDocumentInNewWindow(
                    viewDocument || item,
                    documentVersion
                  )
                }
                className="btn btn-xs btn-icon btn-warning ml-2"
              >
                <i className="fas fa-window-restore ml-1"></i>
              </div>
            </OverlayTrigger>
          )}
          {viewingDocumentId && (
            <OverlayTrigger
              overlay={
                <Tooltip id="comment-tooltip">
                  {editorVisible ? "Hide Comments" : "Show Comments"}
                </Tooltip>
              }
            >
              <div
                onClick={() => toggleEditor()}
                className="btn btn-xs btn-icon btn-secondary ml-2"
              >
                <i className="fas fa-comment ml-1"></i>
              </div>
            </OverlayTrigger>
          )}
        </div>
      </div>
      {viewType === "multiView" && item ? (
        <ScrollContainer className="scroll-container">
          <div
            className={`card-body rotate${
              !imageExtensions.includes(item?.viewExtension)
                ? `-${item?.viewExtension}`
                : ""
            }-${rotation[item?.viewId] || 0}`}
            style={
              showGif[item?.viewId]
                ? { display: "none" }
                : scaleValue === 1
                ? {
                    maxHeight: "70vh",
                    overflowY: isPDF ? "auto" : "hidden hidden"
                  }
                : {
                    overflowY: isPDF ? "auto" : "hidden hidden",
                    maxHeight: "70vh",
                    transform: `scale(${scaleValue}, ${scaleValue})`,
                    transformOrigin: "left top",
                    cursor: isPDF ? "grab" : "default"
                  }
            }
            onMouseDown={e => {
              if (!isPDF) return;

              const element = e.currentTarget;
              element.style.cursor = "grabbing";
              let startX = e.clientX;
              let startY = e.clientY;
              const scrollLeft = element.scrollLeft;
              const scrollTop = element.scrollTop;

              const onMouseMove = e => {
                const x = e.clientX - startX;
                const y = e.clientY - startY;
                element.scrollLeft = scrollLeft - x;
                element.scrollTop = scrollTop - y;
              };

              const onMouseUp = () => {
                element.style.cursor = "grab";
                document.removeEventListener("mousemove", onMouseMove);
                document.removeEventListener("mouseup", onMouseUp);
              };

              document.addEventListener("mousemove", onMouseMove);
              document.addEventListener("mouseup", onMouseUp);
            }}
          >
            {imageExtensions.includes(item?.viewExtension) ? (
              <div
                style={{
                  width: "500px",
                  height: "500px",
                  // overflow: "hidden",
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <img
                  className="card-img"
                  src={item?.viewId}
                  alt=""
                  style={{
                    maxWidth: "fit-content",
                    maxHeight: "100%",
                    marginBottom: "auto",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }}
                  // style={{ margin: "70px 0" }}
                />
              </div>
            ) : isPDF ? (
              <Document
                file={item?.viewId}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                {Array.from(Array(numPages), (_, index) => (
                  <Page
                    key={index}
                    pageNumber={index + 1}
                    scale={1}
                    rotate={rotation[item?.viewId] || 0}
                    className={`${
                      fullScreen[item?.viewId] === true
                        ? "d-flex justify-content-center align-items-center"
                        : ""
                    }`}
                  />
                ))}
              </Document>
            ) : (
              <FileViewer
                fileType={item?.viewExtension}
                filePath={item?.viewId}
                onError={error => {
                  console.log(error);
                }}
              />
            )}
          </div>
        </ScrollContainer>
      ) : (
        <div>
          {editorVisible && (
            <div
              style={{
                marginBottom: "20px",
                marginLeft: "20px",
                marginRight: "20px"
              }}
            >
              <SunEditor
                setContents={comments}
                placeholder="Enter Comment*"
                setOptions={{
                  hideToolbar: true,
                  height: "150px"
                }}
                onChange={value => {
                  setComments(value);
                }}
              />
              <div className="d-flex justify-content-end mt-3 pr-2">
                <button
                  className={`btn btn-primary ${
                    buttonLoader ? "spinner spinner-white spinner-right" : ""
                  }`}
                  onClick={handleSaveComments}
                  disabled={buttonLoader}
                >
                  {buttonLoader ? "Saving..." : "Save Comment"}
                </button>
              </div>
            </div>
          )}

          <ScrollContainer className="scroll-container">
            <div
              className={`card-body rotate${
                !imageExtensions.includes(viewingDocumentExtension)
                  ? `-${viewingDocumentExtension}`
                  : ""
              }-${rotation[viewingDocumentId] || 0} ${
                isPDF && scaleValue > 1.5 ? "d-flex" : ""
              }`}
              style={
                showGif[viewingDocumentId]
                  ? { display: "none" }
                  : scaleValue === 1
                  ? {}
                  : {
                      transform:
                        (imageExtensions.includes(viewingDocumentExtension) ||
                          !isPDF) &&
                        `scale(${scaleValue}, ${scaleValue})`,
                      transformOrigin: imageExtensions.includes(
                        viewingDocumentExtension
                      )
                        ? "center center"
                        : !isPDF && "left top"
                    }
              }
            >
              {imageExtensions.includes(viewingDocumentExtension) ? (
                <div
                  style={{
                    width: "500px",
                    height: "500px",
                    overflow: "hidden",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto"
                  }}
                >
                  <img
                    className="card-img"
                    src={viewingDocumentId}
                    alt=""
                    style={{ maxWidth: "fit-content", maxHeight: "100%" }}
                  />
                </div>
              ) : isPDF ? (
                <Document
                  file={viewingDocumentId}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from(Array(numPages), (_, index) => (
                    <Page
                      key={index}
                      pageNumber={index + 1}
                      rotate={rotation[viewingDocumentId] || 0}
                      scale={scaleValue ? scaleValue : 1}
                      className={`${
                        fullScreen[viewingDocumentId] === true
                          ? "d-flex justify-content-center align-items-center"
                          : ""
                      }`}
                      style={{
                        transformOrigin: "center center"
                      }}
                    />
                  ))}
                </Document>
              ) : (
                <FileViewer
                  fileType={viewingDocumentExtension}
                  filePath={viewingDocumentId}
                  onError={error => {
                    console.log(error);
                  }}
                />
              )}
            </div>
          </ScrollContainer>
        </div>
      )}

      {showGif[item?.viewId || viewingDocumentId] && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%"
          }}
        >
          <img
            src={toAbsoluteUrl("/media/bg/drag.gif")}
            alt="GIF"
            style={{
              width: "auto",
              height: "50%",
              transform: rotateGif[item?.viewId || viewingDocumentId]
                ? "rotate(90deg)"
                : "rotate(0deg)",
              transition: "transform 2s"
            }}
          />
        </div>
      )}
    </div>
  );
}
