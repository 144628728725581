import React, { useEffect, useState } from "react";
import { imageForDocument } from "../../grids/UIHelpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Preview } from "./Preview";
import { chunk } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@material-ui/core";
import { Overlay } from "./Overlay";

export function Multiview({
  viewingDocumentId,
  viewDocument,
  viewingDocumentExtension,
  selectedDocuments,
  handleCheckbox,
  viewType,
  documentsList,
  loadMore,
  documentLoader,
  handleOpenDocumentInNewTab,
  handleOpenDocumentInNewWindow,
  groupList,
  checkListDictionary
}) {
  const [combinedDocuments, setCombinedDocuments] = useState([]);

  useEffect(() => {
    if (documentsList?.length) {
      setCombinedDocuments(chunk(documentsList, 2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentsList]);

  const getGroup = rowData => {
    let group;
    if (groupList?.length > 0)
      group =
        (groupList || []).find(item => item?.code === rowData?.groupCode)
          ?.name ||
        (rowData?.code?.includes("checklist")
          ? checkListDictionary[rowData?.additionalInformation]?.name ||
            checkListDictionary[rowData?.additionalInformation]
          : "N/A");

    return group;
  };

  return (
    <>
      {documentLoader ? (
        <div className="text-center">
          <CircularProgress className="spinner-lg" />
        </div>
      ) : (
        <div className="row">
          <div
            className="col-lg-10 md-10 sm-12"
            style={{
              maxHeight: "58vh",
              overflow: "scroll",
              overflowX: "hidden",
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridColumnGap: "38px"
            }}
          >
            {selectedDocuments
              ? selectedDocuments.map(item => {
                  return (
                    <Preview
                      viewType={viewType}
                      item={item}
                      handleOpenDocumentInNewTab={handleOpenDocumentInNewTab}
                      handleOpenDocumentInNewWindow={
                        handleOpenDocumentInNewWindow
                      }
                      documentVersion={item?.versionCount}
                    />
                  );
                })
              : null}
          </div>

          <div className="col-lg-2 md-2 sm-12">
            <InfiniteScroll
              dataLength={combinedDocuments.length}
              next={loadMore}
              hasMore={true}
              scrollableTarget="scrollable_Div"
            >
              <div
                id="scrollable_Div"
                style={{ maxHeight: "58vh", overflowX: "hidden" }}
              >
                {combinedDocuments
                  ? combinedDocuments?.map(row => (
                      <>
                        {row.map(item => {
                          const image =
                            imageForDocument.find(
                              a => a?.extension === document?.extension
                            )?.image || "/media/svg/files/default.svg";
                          return (
                            <div style={{ marginBottom: "5px" }}>
                              <Overlay
                                popoverContent={`${item?.name} (${getGroup(
                                  item
                                )})`}
                              >
                                <div
                                  className="symbol symbol-130  mr-3 align-self-start align-self-xxl-center"
                                  style={{ cursor: "pointer" }}
                                >
                                  <label
                                    className="checkbox"
                                    style={{
                                      position: "absolute",
                                      top: "10px",
                                      left: "8px",
                                      zIndex: "4"
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      checked={
                                        (selectedDocuments || []).find(
                                          data => data?.code === item?.code
                                        )?.isChecked
                                      }
                                      onClick={event => {
                                        handleCheckbox(
                                          item,
                                          event?.target?.checked
                                        );
                                      }}
                                    />
                                    <span
                                      style={{ border: "1px solid gray" }}
                                    ></span>
                                  </label>
                                  <div
                                    className="symbol-label"
                                    style={{
                                      border: "1px solid #e5e5e5",
                                      overflow: "hidden"
                                    }}
                                  >
                                    {item?.thumbnail ? (
                                      <img
                                        src={`data:image/png;base64, ${item?.thumbnail}`}
                                        style={{ width: "100%" }}
                                        alt={item.name}
                                      />
                                    ) : (
                                      <img
                                        src={toAbsoluteUrl(image)}
                                        width="45"
                                        alt=""
                                      />
                                    )}
                                  </div>
                                </div>
                              </Overlay>
                            </div>
                          );
                        })}
                      </>
                    ))
                  : null}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      )}
    </>
  );
}
