import {
  createdDiscrepancyStatusTitles,
  WorkflowStatusTitles,
  WorkflowStatusTitlesWithDiscrepant
} from "../_partials/grids/UIHelpers";
import { allFieldsList } from "./fields";
import { inputTypes } from "./commonStaticVariables";

// constant names to be used for referring any data field
export const fieldConstants = {
  code: "code",
  comments: "comments",
  currency: "currency",
  description: "description",
  institution: "institution",
  name: "name",
  operation: "operation",
  ownership: "ownership",
  pending_status: "pending status",
  requested_status: "requested status",
  roles: "roles",
  status: "status",
  actions: "actions"
};

// id column mapping to be used in DataTable rendering
export const columnIdMapping = {
  entities: {
    [fieldConstants.name]: { id: "entityName", label: "Name" },
    [fieldConstants.description]: { id: "description", label: "Description" },
    [fieldConstants.code]: { id: "entityCode", label: "Code" },
    [fieldConstants.pending_status]: {
      id: "scrutinizerStatus",
      label: "Pending Status"
    },
    [fieldConstants.ownership]: { id: "ownership", label: "Ownership" },
    [fieldConstants.status]: { id: "status", label: "Status" },
    [fieldConstants.institution]: {
      id: "institutionId",
      label: "Institution ID"
    },
    [fieldConstants.actions]: { id: "actions", label: "Actions" }
  },
  [fieldConstants.name]: { id: "name", label: "Name" },
  [fieldConstants.code]: { id: "code", label: "Code" },
  [fieldConstants.description]: { id: "description", label: "Description" },
  [fieldConstants.pending_status]: {
    id: "scrutinizerStatus",
    label: "Pending Status"
  },
  [fieldConstants.ownership]: { id: "ownership", label: "Ownership" },
  [fieldConstants.status]: { id: "status", label: "Status" },
  [fieldConstants.currency]: { id: "currency", label: "Currency" },
  [fieldConstants.institution]: {
    id: "institutionId",
    label: "Institution ID"
  },
  [fieldConstants.actions]: { id: "actions", label: "Actions" }
};

// columns to be rendered as default in lists
export const defaultColumnData = {
  approved: [
    columnIdMapping[fieldConstants.name],
    columnIdMapping[fieldConstants.code],
    columnIdMapping[fieldConstants.description],
    // columnIdMapping[fieldConstants.pending_status],
    // columnIdMapping[fieldConstants.ownership],
    columnIdMapping[fieldConstants.status],
    columnIdMapping[fieldConstants.actions]
  ],
  pending: [
    columnIdMapping[fieldConstants.name],
    columnIdMapping[fieldConstants.code],
    columnIdMapping[fieldConstants.pending_status],
    columnIdMapping[fieldConstants.ownership],
    columnIdMapping[fieldConstants.status],
    columnIdMapping[fieldConstants.actions]
  ]
};

// defaut fields that are repeated in every module
const defaultFields = {
  add: [fieldConstants.name, fieldConstants.description, fieldConstants.code],
  edit: [
    fieldConstants.name,
    fieldConstants.description,
    fieldConstants.code,
    fieldConstants.status
  ],
  view: [
    fieldConstants.name,
    fieldConstants.description,
    fieldConstants.code,
    fieldConstants.status
  ],
  approved: [...defaultColumnData.approved],
  pending: [...defaultColumnData.pending]
};

// define field sets for each module and their sub module below
export const componentFields = {
  quantum_administration: {
    entities: {
      approved: [
        columnIdMapping["entities"][fieldConstants.name],
        columnIdMapping["entities"][fieldConstants.description],
        columnIdMapping["entities"][fieldConstants.code],
        columnIdMapping["entities"][fieldConstants.status],
        columnIdMapping["entities"][fieldConstants.actions]
      ]
      // pending: [...defaultFields.pending],
      // fieldsDescriptionOverride: {
      //     [fieldConstants.status]: {
      //         type: "multi"
      //     }
      // }
    }
  },
  general: {
    add: [...defaultFields.add],
    edit: [...defaultFields.edit],
    view: [...defaultFields.view],
    approved: [...defaultFields.approved],
    pending: [...defaultFields.pending]
  }
};

export const typesList = [
  { value: 0, label: "Active" },
  // {value: 1, label: "Inactive"},
  { value: 2, label: "Deleted" },
  { value: 3, label: "Awaiting Activation", onlyUser: true },
  { value: 4, label: "Blocked", onlyUser: true }
];

export const inputTypesDictionary = {
  0: {
    id: 0,
    type: "text",
    displayType: "String",
    validationType: "string",
    queryBuilderType: "text",
    hiddenFields: ["masterDataType", "dependentField", "subFields", "belonging"]
  },
  1: {
    id: 1,
    type: "number",
    displayType: "Number",
    validationType: "number",
    queryBuilderType: "number",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  2: {
    id: 2,
    type: "select",
    displayType: "Select",
    validationType: "string",
    queryBuilderType: "select",
    hiddenFields: [
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  3: {
    id: 3,
    type: "multiselect",
    displayType: "Multi Select",
    validationType: "string",
    queryBuilderType: "multiselect",
    onlyRequiredCheck: true,
    hiddenFields: [
      "subFields",
      // "minLength",
      // "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  4: {
    id: 4,
    type: "checkbox",
    displayType: "Checkbox",
    validationType: "string",
    queryBuilderType: "bool",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging",
      "computationType",
      "computationFieldsForForm"
    ]
  },
  5: {
    id: 5,
    type: "radio",
    displayType: "Radio Button",
    validationType: "string",
    queryBuilderType: "select",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging",
      "computationType",
      "computationFieldsForForm",
      "defaultValueType",
      "defaultValue"
    ]
  },
  6: {
    id: 6,
    type: "switch",
    displayType: "Toggle",
    validationType: "boolean",
    queryBuilderType: "boolean",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging",
      "computationType",
      "computationFieldsForForm",
      "defaultValueType",
      "defaultValue"
    ]
  },
  7: {
    id: 7,
    type: "datePicker",
    displayType: "Date Picker",
    validationType: "string",
    queryBuilderType: "date",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  8: {
    id: 8,
    type: "timePicker",
    displayType: "Time Picker",
    validationType: "string",
    queryBuilderType: "time",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  9: {
    id: 9,
    type: "dateTimePicker",
    displayType: "Date Time Picker",
    validationType: "string",
    queryBuilderType: "datetime",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  10: {
    id: 10,
    type: "colorPicker",
    displayType: "Color Picker",
    validationType: "string",
    queryBuilderType: "number",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging",
      "tags",
      "computationType",
      "computationFieldsForForm"
    ]
  },
  11: {
    id: 11,
    type: "dualListBox",
    displayType: "Fields List",
    validationType: "string",
    queryBuilderType: "number",
    hiddenFields: [
      "masterDataType",
      "subFields",
      "dependentField",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging",
      "computationType",
      "computationFieldsForForm",
      "defaultValueType",
      "defaultValue"
    ]
  },
  12: {
    id: 12,
    type: "conditionBuilder",
    displayType: "Condition Builder",
    validationType: "string",
    queryBuilderType: "text",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging",
      "computationType",
      "computationFieldsForForm"
    ]
  },
  13: {
    id: 13,
    type: "creatableSelect",
    displayType: "Creatable Select",
    validationType: "string",
    queryBuilderType: "select",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  14: {
    id: 14,
    type: "list",
    displayType: "List",
    validationType: "array",
    queryBuilderType: "select",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "minLength",
      "maxLength",
      "defaultValueType",
      "defaultValue",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging",
      "helperText",
      "computationType",
      "computationFieldsForForm"
    ]
  },
  15: {
    id: 15,
    type: "fileInput",
    displayType: "File Input",
    validationType: "string",
    queryBuilderType: "text",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging",
      "computationType",
      "computationFieldsForForm"
    ]
  },
  16: {
    id: 16,
    type: "richTextBox",
    displayType: "Rich Text Box",
    validationType: "string",
    queryBuilderType: "text",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  17: {
    id: 17,
    type: "emailEditor",
    displayType: "Email Editor",
    validationType: "string",
    queryBuilderType: "text",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  18: {
    id: 18,
    type: "textArea",
    displayType: "Text Area",
    validationType: "string",
    queryBuilderType: "text",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  19: {
    id: 19,
    type: "mathematicalExpression",
    displayType: "Mathematical Expression",
    validationType: "string",
    queryBuilderType: "text",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging"
    ]
  },
  20: {
    id: 20,
    type: "iconPicker",
    displayType: "Icon Picker",
    validationType: "string",
    queryBuilderType: "text",
    hiddenFields: [
      "masterDataType",
      "dependentField",
      "subFields",
      "minLength",
      "maxLength",
      "fuzzyThreshold",
      "supportFuzzySearch",
      "belonging",
      "tags",
      "computationType",
      "computationFieldsForForm"
    ]
  }
};

export const dropDownOptions = {
  statuses: [
    { name: "Active", code: 0 },
    { name: "Inactive", code: 1 }
  ],
  outcomeBehaviours: [
    { name: "Respect All", code: 0 },
    { name: "Respect Hidden Only", code: 1 },
    { name: "Respect Mandatory Only", code: 2 },
    { name: "Respect ReadOnly Only", code: 3 }
  ],
  conditionsTrigger: [
    { name: "On all actions", code: 0 },
    { name: "On change only", code: 1 },
    { name: "On validate/proceed only", code: 2 }
  ],
  agentType: [
    { name: "Super user", code: 0 },
    { name: "Supervisor", code: 1 },
    { name: "Agent", code: 2 }
  ],
  dataTypes: [
    ...inputTypes.map(input => {
      return {
        name: input.displayType,
        code: input.id
      };
    })
  ],
  belongings: [
    { name: "Application", code: 0 },
    { name: "Customer", code: 1 }
  ],
  types: [
    { name: "Error", code: 0 },
    { name: "Warning", code: 1 }
  ],
  markupTypes: [
    { name: "Fixed", code: 0 },
    { name: "Variable", code: 1 },
    { name: "Manual", code: 2 }
  ],
  dpdTypes: [
    { name: "Due Date", code: 0 },
    { name: "Due Date + Grace", code: 1 }
  ],
  repaymentCriteriaTypes: [
    { name: "Bill", code: 0 },
    { name: "Installment", code: 1 }
  ],
  timePeriodTypes: [
    { name: "Yearly", code: 0 },
    { name: "Quarterly", code: 1 },
    { name: "Monthly", code: 1 }
  ],
  criteriaTypes: [
    { name: "Individual", code: 0 },
    { name: "Overall", code: 1 }
  ],
  quotaTypes: [
    { name: "Number", code: 0 },
    { name: "Percentage", code: 1 }
  ],
  firstInstallmentBehaviourTypes: [
    { name: "Start of Next Cycle", code: 0 },
    { name: "Start of Following Cycle", code: 1 },
    { name: "Next Cycle", code: 2 }
  ],
  kiborTypes: [
    { name: "3 - Months", code: 3 },
    { name: "6 - Months", code: 6 },
    { name: "9 - Months", code: 9 },
    { name: "12 - Months", code: 12 }
  ],
  processingFeesTypes: [
    { name: "Percentage", code: 0 },
    { name: "Fixed Amount", code: 1 },
    { name: "Both(Percentage & Fixed Amount)", code: 2 }
  ],
  processingFeesCriteria: [
    { name: "Whichever is Higher", code: 0 },
    { name: "Whichever is Lower", code: 1 }
  ],
  checkListTypes: [
    { name: "Document", code: 0 },
    { name: "External System", code: 1 },
    { name: "General", code: 2 },
    { name: "Biometric", code: 3 }
  ],
  preCheckTypes: [{ name: "External System", code: 0 }],
  discrepancyTypes: [
    { name: "General", code: 0 },
    { name: "Document", code: 1 }
  ],
  adviceTypes: [
    { name: "General", code: 0 },
    { name: "Document", code: 1 },
    { name: "Both", code: 2 }
  ],
  layouts: [
    { name: "Full", code: 0, css: "col-md-1" },
    { name: "Half", code: 1, css: "col-md-2" }
  ],
  computationOptions: [
    { name: "Add", code: 0 },
    { name: "Subtract", code: 1 },
    { name: "Multiply", code: 2 },
    { name: "Divide", code: 3 },
    { name: "Average", code: 4 },
    { name: "Range", code: 5 },
    { name: "Range In Months", code: 6 },
    { name: "Range In Years", code: 7 },
    { name: "Merge", code: 8 },
    { name: "Range In Number Of Years", code: 9 },
    { name: "Min", code: 10 },
    { name: "Max", code: 11 },
    { name: "Range In Y,M,D", code: 12 },
    { name: "Gender", code: 13 },
    { name: "Merge Values With Spaces", code: 14 },
    { name: "Add Subtract Days in Date", code: 15 }
  ],
  outcomeTypes: [
    { name: "Group", code: 0 },
    { name: "Header", code: 1 },
    { name: "Field", code: 2 }
  ],
  authenticationCriterion: [
    { name: "AND", code: 0 },
    { name: "OR", code: 1 }
  ],
  defaultValueType: [
    { name: "Static", code: 0 },
    { name: "Field", code: 1 }
  ],
  scorePostProcessingOperationType: [
    { name: "Add", code: 0 },
    { name: "Subtract", code: 1 },
    { name: "Divide", code: 2 },
    { name: "Multiply", code: 3 }
  ],
  bulkOperations: [
    { name: "Delete", code: "delete" },
    { name: "Copy To", code: "copyTo" },
    { name: "Move To", code: "moveTo" },
    { name: "Modify Attribute", code: "modify" }
  ],
  filterMethods: [
    { name: "OR", code: "or" },
    { name: "AND", code: "and" }
  ],
  exportTypes: [
    { name: "PDF", code: 0 },
    { name: "xlsx", code: 1 }
  ],
  // filtersForReports: [
  //   {name: "Identifier", code: "identifier"},
  //   {name: "Product", code: 'ProductCode'},
  //   {name: "Start Date", code: 'StartDate'},
  //   {name: 'End Date', code: 'EndDate'},
  //   {name: 'Collector Id', code: 'CollectorId'},
  //   {name: 'Cust/Customer Id', code: 'CustId'},
  //   {name: 'Cycle Cut', code: 'CycleCut'},
  //   {name: 'Age Code Cycle Cut', code: 'AgeCodeAtCycleCut'},
  //   {name: 'Age Code At Allocation', code: 'AgeCodeAtAllocation'},
  //   {name: 'Age Code', code: 'AgeCode'},
  //   {name: 'Queue', code: 'SystemQueue'},
  //   {name: 'City', code: 'City'},
  //   {name: 'Region', code: 'Region'},
  //   {name: 'Status', code: 'ApplicationStatus'},
  //   {name: 'CurrentStage', code: 'CurrentStage'},
  //   {name: 'Assigned To', code: 'AssignedTo'},
  //   {name: 'User By Code', code: 'UserByCode'},
  //   {name: 'Group By Code', code: 'GroupByCode'},
  // ],
  workflowStatus: [
    { code: "", name: "Default" },
    ...(Object.entries(WorkflowStatusTitles).map(([code, name]) => ({
      code,
      name
    })) || [])
  ],
  workflowStatusWithDiscrepant: [
    { code: "", name: "Default" },
    ...(Object.entries(WorkflowStatusTitlesWithDiscrepant).map(
      ([code, name]) => ({
        code,
        name
      })
    ) || [])
  ],
  discrepancyStatus: [
    ...(Object.entries(createdDiscrepancyStatusTitles).map(([code, name]) => ({
      code,
      name
    })) || [])
  ],
  workflowStatusForFilter: [
    ...(Object.entries(WorkflowStatusTitles).map(([code, name]) => ({
      code,
      name
    })) || [])
  ],
  insuranceTypes: [
    { name: "Fixed", code: 0 },
    { name: "Reducing Price", code: 1 },
    { name: "Depreciation", code: 2 }
  ],
  turnAroundTimeType: [
    { name: "Absolute", code: 0 },
    { name: "Relative", code: 1 }
  ],
  turnAroundTimeEvent: [
    { name: "Creation", code: 0 },
    { name: "Completion", code: 1 }
  ],
  turnAroundTimeTreatment: [
    { name: "Escalate", code: 0 },
    { name: "Notify", code: 1 }
  ],
  escalationType: [
    { name: "Stage", code: 0 },
    { name: "Authentication", code: 1 }
  ],
  customerConfigurationTypes: [
    { name: "Fixed", code: "Fixed" },
    { name: "Grid", code: "Grid" }
  ],
  isFileGenerated: [
    { code: "Yes", name: "Generated" },
    { code: "No", name: "Pending" }
  ],
  otpTypes: [
    { name: "AlphaNumeric", code: 0 },
    { name: "Numeric", code: 1 },
    { name: "Letters", code: 2 }
  ],
  otpChannel: [
    { name: "Email", code: 0 },
    { name: "Sms", code: 1 }
  ],
  otpType: [
    { name: "Numeric", code: 0 },
    { name: "AlphaNumeric", code: 1 }
  ],
  notificationType: [
    { name: "Global", code: 0 },
    { name: "Product", code: 1 }
  ],
  eventTypes: [
    { name: "On Boarding", code: 0 },
    { name: "Password Change", code: 1 },
    { name: "Login", code: 2 },
    { name: "Proceed", code: 3 },
    { name: "Reject", code: 4 },
    { name: "Authentication Rejected", code: 5 },
    { name: "Authentication Approved", code: 6 },
    { name: "Escalation", code: 7 },
    { name: "Otp", code: 8 },
    { name: "Stage Reverted", code: 9 },
    { name: "Authentication Move Back", code: 10 },
    { name: "Stage Completion", code: 11 },
    { name: "Awaiting Authentication", code: 12 },
    { name: "Ekyc Invite", code: 13 },
    { name: "Awaiting Next Stage", code: 14 },
    { name: "Ekyc Invite For Agent", code: 15 },
    { name: "Stage Escalation Notify", code: 16 },
    { name: "Save And Exit", code: 17 },
    { name: "Authentication Escalation", code: 18 },
    { name: "Reassign", code: 19 },
    { name: "Create Discrepancy", code: 20 },
    { name: "Recreate Discrepancy", code: 21 },
    { name: "Resolve Discrepancy", code: 22 },
    { name: "Delete Discrepancy", code: 23 },
    { name: "SafeWatch By Name Supplementary", code: 24 },
    { name: "SafeWatch By Name", code: 25 },
    { name: "SafeWatch By Cnic", code: 26 },
    { name: "SafeWatch By Cnic Supplementary", code: 27 },
    { name: "SafeWatch By Company", code: 28 }
  ],
  recipientTypes: [
    { name: "Cascade User", code: 0 },
    { name: "Customer", code: 1 }
  ],
  fieldTypes: [
    { name: "Grid Fields", code: 0 },
    { name: "Fields", code: 1 }
  ],
  installmentTypes: [
    { name: "EMI", code: 0 },
    { name: "UMI", code: 1 }
  ],
  gridExposureSheetsRootParameters: [
    { name: "Current Loan Amount", code: "currentLoanAmount" },
    { name: "Current Loan EMI", code: "currentLoanEMI" }
  ],
  stateWidgetTypeList: [
    { name: "Biometric", code: "Biometric" },
    { name: "Front Camera", code: "FrontCamera" },
    { name: "Back Camera", code: "BackCamera" },
    { name: "Map", code: "map" }
  ],
  placementList: [
    { name: "Before Form", code: "BeforeForm" },
    { name: "After Form", code: "AfterForm" }
  ],
  renderOnList: [
    { name: "Mobile", code: "Mobile" },
    { name: "Web", code: "Web" },
    { name: "Both", code: "Both" }
  ],
  bulkPaymentsList: [
    { name: "Full Payment", code: 0 },
    { name: "Amount", code: 1 },
    { name: "No. of Installments", code: 2 }
  ],
  kiborTenors: [
    { name: "One", code: 1 },
    { name: "Two", code: 2 },
    { name: "Three", code: 3 },
    { name: "Six", code: 6 },
    { name: "Nine", code: 9 }
  ],
  kiborUnits: [
    { name: "Week", code: 0 },
    { name: "Month", code: 1 },
    { name: "Year", code: 2 }
  ],
  fieldLayout: [
    { name: "0", code: 0 },
    { name: "1", code: 1 },
    { name: "2", code: 2 },
    { name: "3", code: 3 }
  ],
  purgingTreatment: [
    { name: "Reject", code: 0 },
    { name: "Cancel", code: 1 }
  ],
  isGrid: [
    { name: "Table List Form", code: true },
    { name: "Main Form", code: false }
  ],
  documentTypes: [
    { name: "Checklist", code: 0 },
    { name: "Additional Document", code: 1 }
  ],
  schemeTypes: [
    { name: "Static", code: 0 },
    { name: "Dynamic", code: 1 }
  ],
  resolutionTypes: [
    { name: "Group", code: 0 },
    { name: "Stage", code: 1 }
  ],
  screeningConditionTypes: [
    { name: "Conditional", code: 0 },
    { name: "List", code: 1 }
  ],
  entryTypes: [
    { name: "Credit", code: 0 },
    { name: "Debit", code: 1 }
  ],
  fuzzyOperator: [
    { name: "Equal To", code: 0 },
    { name: "Greater Than", code: 1 },
    { name: "Greater Than Or Equal", code: 2 },
    { name: "Less Than", code: 3 },
    { name: "Less Than Or Equal", code: 4 }
  ],
  paymentTypes: [
    { name: "Markup", code: "markup" },
    { name: "Principal", code: "principal" },
    { name: "Penalty", code: "penalty" },
    { name: "Fees", code: "fees" }
  ],
  reportSources: [
    { name: "Jasper", code: 0 },
    { name: "Comet", code: 1 }
  ],
  incentiveLevelTypes: [
    { name: "Count Based", code: 0 },
    { name: "Percentage Based", code: 1 }
  ],
  incentiveLevelVariantTypes: [
    { name: "Fixed Amount", code: 0 },
    { name: "Percentage of Ticket Size", code: 1 }
  ],
  incentiveLevelVariantSlabCalculationBasedOn: [
    { name: "Unit", code: 0 },
    { name: "Slab", code: 1 }
  ],
  mobileUploadDropdownOptions: [
    { name: "Camera", code: 0 },
    { name: "Browse", code: 1 },
    { name: "Both", code: 2 }
  ]
};

export const createFieldRestriction = (required, disabled, hidden) => ({
  required,
  disabled,
  hidden
});

export const staticFieldRestrictions = {
  status: {
    add: createFieldRestriction(true, false, true),
    clone: createFieldRestriction(true, false, true),
    edit: createFieldRestriction(true, true, false),
    view: createFieldRestriction(true, true, false)
  },
  entityStatus: {
    add: createFieldRestriction(true, false, false),
    clone: createFieldRestriction(true, false, false),
    edit: createFieldRestriction(true, true, false),
    view: createFieldRestriction(true, true, false)
  },
  name: {
    add: createFieldRestriction(true, false, false),
    clone: createFieldRestriction(true, false, false),
    edit: createFieldRestriction(true, false, false),
    view: createFieldRestriction(true, true, false)
  },
  code: {
    add: createFieldRestriction(true, false, false),
    clone: createFieldRestriction(true, false, false),
    edit: createFieldRestriction(true, true, false),
    view: createFieldRestriction(true, true, false)
  },
  entityCode: {
    add: createFieldRestriction(true, false, false),
    clone: createFieldRestriction(true, false, false),
    edit: createFieldRestriction(true, true, false),
    view: createFieldRestriction(true, true, false)
  },
  dataType: {
    add: createFieldRestriction(true, false, false),
    clone: createFieldRestriction(true, false, false),
    edit: createFieldRestriction(true, true, true),
    view: createFieldRestriction(true, true, true)
  }
};

export const codeRegexPattern = /[^a-zA-Z0-9\s.\-_]/g;

export const alphaNumericRegex = new RegExp(`^\\w*$`);
export const emailRegex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

// if code is an object, then match all properties to find the right config,
// if code is string, just find matching code
const getFieldsByCodeList = codesList => {
  return codesList
    .map(code => {
      // if field code is not string (is an object)
      if (typeof code !== "string") {
        // filter all fields which match the given code, than match all other properties to find the right field
        return allFieldsList.filter(function(item) {
          // iterate over each property in code object
          for (let key in code) {
            // if property doesn't exist/match
            if (item[key] === undefined || item[key] !== code[key])
              return false;
          }
          // else return true to get this field
          return true;
        })[0];
      }

      // if field type is string, simply return the matching field code config
      else return allFieldsList.find(field => field.code === code);
    })
    .filter(Boolean);
};

const moduleFieldsDefinition = {
  static: [
    "name",
    {
      code: "code",
      minLength: 1
    },
    "description"
  ],
  staticProductFactory: [
    "name",
    {
      code: "code",
      minLength: 3
    },
    "description"
  ],
  recordStatic: ["name", "code", "description", "order", "isHidden"],
  entities: ["forceDeleteRecord", "state", "fields"],
  fields: [
    "state",
    "dataType",
    "masterDataType",
    "dependentField",
    "subFields",
    "minLength",
    "maxLength",
    "tags",
    "helperText",
    "group",
    "header",
    "belonging",
    "fuzzyThreshold",
    "supportFuzzySearch",
    "isRequired",
    "computationType",
    "computationFieldsForForm",
    "isReadOnly",
    "isHidden",
    "isImportAllowed",
    {
      code: "mathematicalExpression",
      type: "mathematicalExpression"
    },
    {
      code: "layout",
      masterDataType: "fieldLayout"
    },
    "products",
    "defaultValueType",
    "defaultValue",
    {
      code: "defaultValue",
      type: "select"
    },
    "defaultValueExpression",
    "conditions"
  ],
  fieldsComputations: ["computationType", "computationField", "isUnique"],
  fieldsConditionalComputations: [
    "expression",
    "source",
    "computationType",
    "destination"
  ],
  users: [
    "emailAddress",
    "gender",
    "phoneNumber",
    {
      code: "groupCodes",
      name: "Authorized Groups",
      isRequired: true
    },
    "personnelNumber",
    "branchCode",
    "department",
    "designation",
    "twoFactorEnabled",
    "defaultPassword",
    "remarks"
  ],
  modules: ["state", "parentCode", "route", "isMakerCheckerEnabled"],
  groups: ["state"],
  workflow: [],
  reports: [
    "exportTypes",
    "productCodes",
    "folderName",
    {
      code: "filters",
      name: "Filters"
    },
    {
      code: "source",
      masterDataType: "reportSources"
    },
    "state",
    {
      code: "expression",
      type: "conditionBuilder"
    }
  ],
  sets: [
    {
      code: "columns",
      masterDataType: "setFlowsColumns"
    }
  ],
  allocations: [
    "order",
    "collectorIds",
    "regions",
    "strategies",
    "queues",
    "ageCodes",
    {
      code: "cycleCuts",
      masterDataType: "cycleCuts"
    },
    "CollectionDBCITY",
    "CollectionMainCity",
    "CollectionSubCity"
  ],
  autoAssignments: [
    {
      name: "State",
      code: "type",
      masterDataType: "statuses",
      isRequired: true
    },
    {
      code: "fromDate",
      hidden: false
    },
    {
      code: "toDate",
      hidden: false
    }
  ],
  externalIntegrationSets: [
    "retries",
    "sequence",
    "parameters",
    "url",
    "isDependent",
    "parent"
  ],
  basicInformation: [
    "state",
    "externalSystemIdentifiers",
    "cycleCuts",
    "applicationCreationCriteria",
    "gridColumns",
    "hierarchyType",
    "hierarchyAlias",
    "overrideHierarchy",
    "hierarchyFieldCode",
    "childProducts",
    "identificationCode",
    "associationWith",
    "dataSinkIntegration",
    "declineGroups",
    "rejectGroups",
    "recallGroups",
    "repaymentBasedOn",
    "coolingPeriod",
    {
      code: "tags",
      name: "Collections Tags"
    },
    "isRenewal",
    "renewalProductCodes",
    "randomizeWorkflowIdentifier",
    "counters",
    "accountFields",
    "identificationFields",
    "validatePermissionOnWorkflowInitiation",
    {
      name: "Is Secured",
      code: "isSecured"
    }
  ],
  dedupeCriteria: [
    {
      code: "stageCodes",
      type: "multiselect"
    },
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  dedupeConditions: [
    "isGrid",
    "gridCode",
    "gridIdentifier",
    {
      code: "fieldCodes",
      hidden: false,
      isRequired: true
    },
    {
      code: "status",
      isRequired: true
    }
  ],
  quickViews: [
    {
      code: "code",
      name: "Quick View"
    },
    {
      code: "fieldCodes",
      hidden: false,
      isRequired: true
    },
    "layout",
    "icon",
    "color",
    {
      code: "status",
      isRequired: true
    },
    "checklistCode",
    "trailingSymbol",
    "expression"
  ],
  quickViews_overrides: [
    "code",
    "layout",
    "icon",
    "color",
    {
      code: "status",
      isRequired: true
    },
    "trailingSymbol",
    "expression"
  ],
  deviationQuota: [
    {
      code: "type",
      name: "Criteria Type",
      type: "select",
      masterDataType: "criteriaTypes"
    },
    {
      type: "select",
      code: "period",
      masterDataType: "timePeriodTypes"
    },
    {
      name: "Quota Type",
      type: "select",
      code: "quotaType",
      masterDataType: "quotaTypes"
    },
    "deviationLevel",
    "maxAllowedExceptionsNumber",
    "maxAllowedExceptionsPercentage"
  ],
  strategies: [
    "actions",
    "ptpRestriction",
    "useCycleCuts",
    {
      code: "status",
      isRequired: true
    },
    {
      code: "expression"
    }
  ],
  escalations: [
    "isEscalate",
    "sendNotifications",
    {
      code: "status",
      isRequired: true
    },
    {
      code: "expression"
    }
  ],
  queues: [
    "strategies",
    {
      code: "groupCodes",
      name: "Assigned Groups",
      type: "multiselect",
      isRequired: true
    },
    "allocation",
    {
      code: "status",
      isRequired: true
    },
    {
      code: "expression"
    }
  ],
  dunningLetters: [
    // "report",
    {
      code: "status",
      isRequired: true
    },
    {
      code: "expression"
    }
  ],
  notifications: [
    "notifications",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  policies: [
    {
      code: "stageCodes",
      type: "multiselect"
    },
    {
      code: "type",
      type: "select"
    },
    "fromDate",
    "toDate",
    "message",
    "canDeviate",
    "deviationCode",
    "isGrid",
    "gridCode",
    "gridIdentifier",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  policies_overrides: [
    {
      code: "type",
      type: "select"
    },
    "fromDate",
    "toDate",
    "message",
    "canDeviate",
    "deviationCode",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  stages: [
    "externalIntegrations",
    "notifyViaEmail",
    "notifyViaSms",
    "permissions",
    "stageCreationGroups",
    "reports",
    "validityInDays",
    "inactivityDurationInDays",
    "documentPermissionGroups",
    "assignmentGroups",
    "reAssignmentGroups",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  stageFields: [
    {
      name: "Field",
      code: "code",
      type: "select"
    },
    {
      code: "name",
      name: "Alias"
    },
    {
      code: "group",
      type: "select",
      isRequired: true
    },
    {
      code: "header",
      type: "select"
    },
    {
      code: "isReadOnly",
      type: "switch"
    },
    {
      code: "isRequired",
      type: "switch"
    },
    {
      code: "status",
      isRequired: true
    }
  ],
  turnAroundTime: [
    "hours",
    {
      code: "escalationType",
      masterDataType: "escalationType"
    },
    "stageCode",
    "authenticationCode",
    {
      code: "treatment",
      masterDataType: "turnAroundTimeTreatment"
    }
  ],
  escalationLevels: [
    "level",
    "duration",
    "notifyViaEmail",
    "notifyViaSms",
    "escalationGroups"
  ],
  stage_authentications: [
    // "levelNumber",
    // "levelCount",
    "readOnlyFieldCodes",
    "fieldCodes",
    {
      code: "groupCodes",
      name: "Authorized Groups",
      type: "multiselect",
      isRequired: true
    },
    // "criteria",
    // "bypass",
    "notifyViaEmail",
    "notifyViaSms",
    {
      code: "status",
      isRequired: true
    },
    // 'deferralList',
    "recommendOnly",
    "recommendingLevelNumbers",
    "deferralListOfRecommendingAuthorities",
    // "highestRecommendingLevelExceptionCount",
    "recommendingCriteria",
    {
      code: "recommendingExpression",
      type: "conditionBuilder"
    },
    "approvingLevelNumber",
    "approvingLevelCount",
    "deferralListOfApprovingAuthorities",
    "approvingCriteria",
    {
      code: "approvingExpression",
      type: "conditionBuilder"
    }
  ],
  stageCreationGroupsOverrides: [
    {
      code: "groupCodes",
      name: "Authorized Groups",
      isRequired: true
    },
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  widgets: [
    {
      name: "type",
      masterDataType: "stateWidgetTypeList"
    },
    "placement",
    "renderOn",
    "inputParameters",
    "outputParameters"
  ],
  conditions: [
    {
      code: "stageCodes",
      type: "multiselect",
      masterDataType: "stagesWithZeroStageFP"
    },
    {
      code: "status",
      isRequired: true
    },
    {
      code: "gridCodes"
    },
    "conditionTrigger",
    {
      code: "expression",
      name: "Outcome Conditions"
    }
  ],
  outcomes: [
    {
      name: "Type",
      code: "type",
      description: "Outcome Type"
    },
    {
      code: "group",
      type: "select"
    },
    {
      code: "header",
      type: "select"
    },
    {
      name: "Field",
      code: "code",
      type: "select",
      isRequired: false
    },
    {
      code: "value",
      type: "string"
    },
    {
      name: "Master Data Type",
      code: "masterDataType",
      type: "select",
      masterDataType: "entities"
    },
    "minLength",
    "maxLength",
    {
      code: "isReadOnly",
      type: "switch"
    },
    {
      code: "isRequired",
      type: "switch"
    },
    {
      code: "isHidden",
      type: "switch"
    },
    {
      code: "shouldClearValue",
      type: "switch"
    },
    {
      code: "status",
      isRequired: true
    },
    "outcomeRespect"
  ],
  markup: [
    {
      code: "name",
      type: "string"
    },
    {
      code: "code",
      type: "string"
    },
    "markupField",
    "spreadField",
    "kiborField",
    "tenorField",
    {
      code: "type",
      type: "select",
      masterDataType: "markupTypes"
    },
    "typeField",
    {
      code: "value",
      type: "number",
      helperText: "value in percentage(%)"
    },
    "divideRate",
    {
      code: "kiborFieldCode",
      type: "select",
      masterDataType: "fields"
    },
    {
      code: "stages",
      type: "multiselect"
    },
    "isGrid",
    "gridCode",
    "gridIdentifier"
  ],
  markup_overrides: [
    {
      code: "type",
      type: "select",
      masterDataType: "markupTypes"
    },
    "typeField",
    {
      code: "value",
      type: "number",
      helperText: "value in percentage(%)"
    },
    "divideRate",
    {
      code: "kibor",
      type: "select",
      masterDataType: "kiborTypes"
    },
    {
      code: "stages",
      type: "multiselect"
    },
    "fromDate",
    "toDate",
    "fromInstallment",
    "toInstallment",
    {
      code: "status",
      isRequired: true
    },
    {
      code: "expression",
      type: "conditionBuilder"
    }
  ],
  dpd: [
    {
      code: "status",
      isRequired: true
    },
    {
      code: "type",
      type: "select",
      masterDataType: "dpdTypes"
    }
  ],
  dpd_buckets: [
    {
      code: "name",
      type: "string"
    },
    {
      code: "code",
      type: "string"
    },
    "from",
    "to",
    {
      code: "status",
      isRequired: true
    }
  ],
  processingFees: [
    {
      code: "name",
      type: "string"
    },
    {
      code: "code",
      type: "string"
    },
    "processingFeesField",
    {
      code: "currentLoanAmount",
      type: "select"
    },
    {
      code: "region",
      type: "select"
    },
    {
      code: "type",
      type: "select",
      masterDataType: "processingFeesTypes"
    },
    {
      code: "percentage",
      type: "number"
    },
    {
      name: "Fixed Amount",
      code: "amount",
      type: "number"
    },
    {
      code: "criteria",
      type: "select",
      masterDataType: "processingFeesCriteria"
    },
    {
      code: "stages",
      type: "multiselect"
    }
  ],
  processing_overrides: [
    {
      code: "type",
      type: "select",
      masterDataType: "processingFeesTypes"
    },
    {
      code: "percentage",
      type: "number"
    },
    {
      name: "Fixed Amount",
      code: "amount",
      type: "number"
    },
    {
      code: "criteria",
      type: "select",
      masterDataType: "processingFeesCriteria"
    },
    {
      code: "stages",
      type: "multiselect"
    },
    "fromDate",
    "toDate",
    {
      code: "status",
      isRequired: true
    },
    {
      code: "expression",
      type: "conditionBuilder"
    }
  ],
  debtBurdenRatio: [
    {
      code: "grossPayable",
      type: "select"
    },
    {
      code: "verifiedIncome",
      type: "select"
    },
    {
      code: "dbrField",
      type: "select"
    },
    {
      code: "stages",
      type: "multiselect",
      isRequired: true
    }
  ],
  debtServiceRatio: [
    {
      code: "emi",
      type: "select"
    },
    {
      code: "verifiedIncome",
      type: "select"
    },
    {
      code: "dsrField",
      type: "select"
    },
    {
      code: "stages",
      type: "multiselect",
      isRequired: true
    }
  ],
  exposureSheet: [
    // {
    //   code: "customerName",
    //   type: "select",
    //   masterDataType: "fields"
    // },
    // {
    //   code: "cnic",
    //   type: "select",
    //   masterDataType: "fields"
    // },
    // {
    //   code: "dob",
    //   type: "select",
    //   masterDataType: "fields"
    // },
    // {
    //   code: "city",
    //   type: "select",
    //   masterDataType: "fields"
    // },
    // {
    //   code: "address",
    //   type: "select",
    //   masterDataType: "fields"
    // },
    // {
    //   code: "limit",
    //   type: "select",
    //   masterDataType: "fields"
    // },
    {
      code: "verifiedIncome",
      type: "select",
      masterDataType: "fields"
    },
    {
      code: "emiOnSalarySlip",
      type: "select"
    },
    {
      code: "grossPayable",
      type: "select"
    },
    {
      code: "currentLoanAmount",
      type: "select"
    },
    {
      code: "currentLoanEMI",
      type: "select"
    },
    {
      code: "aggregateEMI",
      type: "select"
    },
    {
      code: "securedEMI",
      type: "select"
    },
    {
      code: "securedExposure",
      type: "select"
    },
    {
      code: "unsecuredEMI",
      type: "select"
    },
    "debtBurdenRatio",
    "debtServiceRatio",
    {
      code: "unsecuredExposure",
      type: "select"
    },
    {
      code: "plus30",
      type: "select"
    },
    {
      code: "plus60",
      type: "select"
    },
    {
      code: "plus90",
      type: "select"
    },
    {
      code: "plus120",
      type: "select"
    },
    {
      code: "plus150",
      type: "select"
    },
    {
      code: "plus180",
      type: "select"
    },
    {
      code: "plus30Amount",
      type: "select"
    },
    {
      code: "plus60Amount",
      type: "select"
    },
    {
      code: "plus90Amount",
      type: "select"
    },
    {
      code: "plus120Amount",
      type: "select"
    },
    {
      code: "plus150Amount",
      type: "select"
    },
    {
      code: "plus180Amount",
      type: "select"
    },
    {
      code: "late1To15",
      type: "select"
    },
    {
      code: "late16To20",
      type: "select"
    },
    {
      code: "late21To29",
      type: "select"
    },
    {
      code: "late30",
      type: "select"
    },
    {
      code: "outstandingAmount",
      type: "select"
    },
    {
      code: "overdueAmount",
      type: "select"
    },
    {
      code: "groupCodes",
      name: "Authorized Groups",
      type: "multiselect",
      isRequired: true
    },
    {
      code: "primaryField",
      type: "select"
    },
    {
      code: "bureaus",
      name: "Bureaus",
      type: "multiselect"
    },
    {
      code: "customerName",
      type: "select"
    },
    {
      code: "fatherName",
      type: "select"
    },
    {
      code: "address",
      type: "select"
    },
    {
      code: "cnic",
      type: "select"
    },
    {
      code: "dateOfBirth",
      type: "select"
    },
    {
      code: "gender",
      type: "select",
      masterDataType: "fields"
    },
    {
      code: "mfiDefault",
      type: "select"
    },
    {
      code: "writeoff",
      type: "select"
    }
  ],
  statement: [
    "gridCode",
    "gridIdentifier",
    "firstMonthBalance",
    "secondMonthBalance",
    "thirdMonthBalance",
    "fourthMonthBalance",
    "fifthMonthBalance",
    "sixthMonthBalance",
    "seventhMonthBalance",
    "eighthMonthBalance",
    "ninthMonthBalance",
    "tenthMonthBalance",
    "eleventhMonthBalance",
    "twelfthMonthBalance",
    "totalCreditLastSixMonths",
    "totalCreditLastTwelveMonths",
    "totalDebitLastSixMonths",
    "totalDebitLastTwelveMonths",
    "lastTwoMonthsAverageBalance",
    {
      code: "fromDate",
      type: "select"
    },
    {
      code: "toDate",
      type: "select"
    }
  ],
  gridExposureSheets: [
    "gridCode",
    "gridIdentifier",
    {
      code: "verifiedIncome",
      type: "select",
      masterDataType: "fields"
    },
    {
      code: "emiOnSalarySlip",
      type: "select"
    },
    {
      code: "grossPayable",
      type: "select"
    },
    {
      code: "currentLoanAmount",
      type: "select"
    },
    {
      code: "currentLoanEMI",
      type: "select"
    },
    {
      code: "aggregateEMI",
      type: "select"
    },
    {
      code: "securedEMI",
      type: "select"
    },
    {
      code: "securedExposure",
      type: "select"
    },
    {
      code: "unsecuredEMI",
      type: "select"
    },
    {
      code: "unsecuredExposure",
      type: "select"
    },
    "debtBurdenRatio",
    "debtServiceRatio",
    {
      code: "plus30",
      type: "select"
    },
    {
      code: "plus60",
      type: "select"
    },
    {
      code: "plus90",
      type: "select"
    },
    {
      code: "plus120",
      type: "select"
    },
    {
      code: "plus150",
      type: "select"
    },
    {
      code: "plus180",
      type: "select"
    },
    {
      code: "plus30Amount",
      type: "select"
    },
    {
      code: "plus60Amount",
      type: "select"
    },
    {
      code: "plus90Amount",
      type: "select"
    },
    {
      code: "plus120Amount",
      type: "select"
    },
    {
      code: "plus150Amount",
      type: "select"
    },
    {
      code: "plus180Amount",
      type: "select"
    },
    {
      code: "late1To15",
      type: "select"
    },
    {
      code: "late16To20",
      type: "select"
    },
    {
      code: "late21To29",
      type: "select"
    },
    {
      code: "late30",
      type: "select"
    },
    {
      code: "outstandingAmount",
      type: "select"
    },
    {
      code: "overdueAmount",
      type: "select"
    },
    {
      code: "rootParameters",
      type: "multiselect"
    },
    {
      code: "customerName",
      type: "select"
    },
    {
      code: "fatherName",
      type: "select"
    },
    {
      code: "address",
      type: "select"
    },
    {
      code: "cnic",
      type: "select"
    },
    {
      code: "dateOfBirth",
      type: "select"
    },
    {
      code: "gender",
      type: "select",
      masterDataType: "fields"
    },
    {
      code: "status",
      isRequired: true
    }
  ],
  installmentPlan: [
    {
      code: "type",
      type: "select",
      masterDataType: "fields"
    },
    {
      code: "paymentFrequency",
      type: "select",
      isRequired: true,
      masterDataType: "fields"
    },
    {
      code: "disbursementDate",
      type: "select"
    },
    {
      code: "bulletLoanEndDate",
      type: "select"
    },
    {
      code: "loanAmount",
      type: "select"
    },
    {
      code: "tenure",
      type: "select"
    },
    {
      code: "markup",
      type: "select"
    },
    {
      code: "graceType",
      type: "select"
    },
    {
      code: "gracePeriod",
      type: "select"
    },
    {
      code: "paymentGracePeriod",
      type: "select"
    },
    {
      code: "markupBasedOn",
      type: "select"
    },
    {
      code: "residualValue",
      type: "select"
    },
    {
      code: "securityDeposit",
      type: "select"
    },
    {
      code: "roundOffDecimalUpTo",
      type: "select"
    },
    "isGrid",
    "gridCode",
    "gridIdentifier",
    {
      code: "stageCodes",
      type: "multiselect",
      isRequired: true
    },
    {
      code: "groupCodes",
      name: "Authorized Groups",
      type: "multiselect",
      isRequired: true
    },
    "postGeneration",
    "expression"
  ],
  firstInstallmentBehaviours: [
    "from",
    "to",
    {
      code: "type",
      type: "select",
      masterDataType: "firstInstallmentBehaviourTypes"
    }
  ],
  scenarios: [
    {
      code: "status",
      isRequired: true
    },
    {
      code: "expression",
      type: "conditionBuilder"
    }
  ],
  scenario_stages: [
    {
      code: "code",
      type: "select",
      name: "Stage"
    },
    // "automatedIntegrations",
    "externalIntegrations",
    {
      code: "status",
      isRequired: true
    },
    "entryCondition",
    "exitCondition"
  ],
  checkList: [
    {
      code: "stages",
      isRequired: true
    },
    {
      code: "type",
      type: "select",
      name: "Checklist Type"
    },
    "errorMessage",
    "isMandatory",
    "isGrid",
    "gridCode",
    "gridIdentifier",
    {
      name: "Document Group",
      code: "additionalInformation"
    },
    {
      code: "status",
      isRequired: true
    },
    "isFetchLocation",
    "latitudeFieldCode",
    "longitudeFieldCode",
    "retrieveFromArchive",
    "mobileUploadOptions",
    "disableRetry",
    "mandatoryFields",
    "expression"
  ],
  deviation: [
    {
      code: "code",
      name: "Deviations"
    },
    "stages",
    {
      code: "status",
      isRequired: true
    }
  ],
  scoringCriteria: [
    {
      code: "stageCodes",
      type: "multiselect"
    },
    {
      code: "scoreField",
      type: "select"
    },
    {
      code: "groupCodes",
      name: "Authorized Groups",
      type: "multiselect",
      isRequired: true
    },
    {
      code: "isWeightageBased",
      type: "switch"
    },
    {
      code: "labelField",
      type: "select"
    },
    {
      code: "orrField",
      type: "select"
    },
    {
      code: "frrField",
      type: "select"
    },
    "isGrid",
    "gridCode",
    "gridIdentifier",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  criteria_schemes: [
    {
      code: "fieldCode",
      type: "select"
    },
    "isRootLevelField",
    {
      code: "outOf",
      type: "number"
    },
    {
      code: "weightage",
      type: "number"
    },
    {
      code: "schemeType",
      type: "select"
    },
    {
      code: "status",
      isRequired: true
    },

    "expression"
  ],
  criteria_scores: [
    {
      code: "fieldCode",
      type: "select"
    },
    {
      name: "Score",
      code: "value"
    },
    {
      code: "status",
      isRequired: true
    },
    {
      code: "expression"
    }
  ],
  criteria_labels: [
    "fromValue",
    "toValue",
    "color",
    "orr",
    "frr",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  criteria_post_processing: [
    {
      code: "operation",
      type: "select"
    },
    "processingValue",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  peer_benchmark_outer: [
    {
      code: "name",
      type: "string"
    },
    {
      code: "code",
      type: "string"
    },
    {
      code: "stageCodes",
      type: "multiselect"
    },
    "primaryCriteriaField",
    "secondaryCriteriaField",
    {
      code: "status",
      isRequired: true
    }
  ],
  peer_benchmark_inner: [
    {
      code: "valueField",
      type: "select"
    },
    "percentileValueField",
    "percentileRankField",
    {
      code: "status",
      isRequired: true
    }
  ],
  precedeWorkflowModalFields: ["comments"],
  bulkOperationsHandler: [
    {
      code: "operation",
      masterDataType: "bulkOperations"
    },
    {
      name: "Filter By",
      code: "filters"
    },
    "filterMethod",
    "copyTo",
    "moveTo",
    "attributes"
  ],
  importMappings: [
    {
      code: "groupCode",
      name: "File Group"
    }
  ],
  mappings: [
    "cell",
    {
      code: "fieldCode",
      type: "select"
    }
  ],
  emiCriteria: [
    {
      code: "stageCodes",
      type: "multiselect"
    },
    "rate",
    "numberOfPayments",
    "loanValue",
    {
      code: "markupBasedOn",
      type: "select"
    },
    "residualValue",
    "method",
    "paymentFrequency",
    "emiField",
    "isGrid",
    "gridCode",
    "gridIdentifier",
    "expression"
  ],
  preChecks: [
    "name",
    {
      code: "code",
      type: "select",
      name: "Code"
    },
    {
      code: "stageCodes",
      type: "multiselect"
    },
    {
      code: "type",
      type: "select"
    },
    "message",
    {
      code: "status",
      isRequired: true
    },
    "canDeviate",
    "deviationCode",
    "deviationApprovalGroupCodes",
    "expression"
  ],
  discrepancies: [
    {
      code: "code",
      name: "Discrepancy"
    },
    {
      code: "type",
      type: "select",
      name: "Discrepancy Type"
    },
    {
      code: "isGrid",
      isRequired: false,
      isHidden: false
    },
    {
      name: "Creation Stages",
      type: "multiselect",
      code: "stages"
    },
    "resolutionType",
    "resolutionStage",
    {
      name: "Resolution Groups",
      type: "multiselect",
      code: "approvalGroups"
    },
    "maxApprovalStage",
    {
      name: "Discrepancy Main Fields",
      type: "multiselect",
      code: "discrepancyMainFields"
    },
    {
      name: "Discrepancy List Fields",
      type: "multiselect",
      code: "discrepancyListFields"
    },
    "expression"
  ],
  advices: [
    "name",
    "code",
    "stages",
    {
      code: "isGrid",
      isRequired: false,
      isHidden: false
    },
    "maxApprovalStage",
    "expression"
  ],
  insurance: [
    {
      code: "name",
      type: "string"
    },
    {
      code: "code",
      type: "string"
    },
    {
      code: "companies",
      type: "multiselect",
      masterDataType: "DCLInsuranceCompany"
    },
    "depreciationRate",
    {
      code: "rate",
      type: "number"
    },
    "isCustomRate",
    {
      code: "type",
      type: "select",
      masterDataType: "insuranceTypes"
    },
    "amountField",
    "insuranceField",
    "companyField",
    "tenureField",
    "markupField",
    "financeAmountField",
    {
      code: "stages",
      type: "multiselect"
    },
    {
      code: "status",
      isRequired: true
    }
  ],
  insurance_overrides: [
    {
      code: "rate",
      type: "number"
    },
    "fromDate",
    "toDate",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  agents: ["email", "contactNumber", "employeeId", "agentType"],
  widget: ["dynamicParameters"],
  permissions: ["dynamicParameters"],
  interview: [
    "name",
    "cnic",
    {
      code: "contactNumber",
      isHidden: true
    },
    // "contactNumber",
    "email",
    "dateOnly",
    "agentCode",
    "startTime",
    "cnicFront"
  ],
  rescheduleInterview: [
    {
      code: "name",
      disabled: true
    },
    {
      code: "cnic",
      disabled: true
    },
    {
      code: "email",
      disabled: true
    },
    "dateOnly",
    "agentCode",
    "startTime"
  ],
  customers: [
    "productCode",
    {
      code: "type",
      masterDataType: "customerConfigurationTypes"
    },
    "gridCode",
    "nameFieldCodeForGrid",
    "uniqueIdentifierFieldCodeForGrid",
    "productFieldCodesForGrid",
    "fixedFields"
  ],
  fixedFields: [
    "nameFieldCode",
    "uniqueIdentifierFieldCode",
    "productFieldCodes"
  ],
  customersStep1: [
    {
      code: "cnic",
      isWorkflowCustomerStep1Field: true
    }
  ],
  customersStep2: [
    {
      code: "cnic",
      isWorkflowCustomerStep2Field: true
    },
    {
      code: "name",
      isWorkflowCustomerStep2Field: true
    },
    {
      code: "mobileNumber",
      isWorkflowCustomerStep2Field: true
    },
    {
      code: "dateOfBirth",
      isWorkflowCustomerStep2Field: true
    }
  ],
  otp: [
    "message",
    {
      code: "type",
      masterDataType: "otpTypes"
    },
    "digits",
    "channels"
  ],
  notificationTemplates: [
    "state",
    "recipientType",
    {
      code: "notificationType",
      masterDataType: "notificationType"
    },
    {
      code: "productCodes",
      isNotificationProductCodes: true
    },
    {
      code: "stageCodes",
      isNotificationStageCodes: true
    },
    "eventType",
    "levels",
    "escalationCodes",
    "length",
    {
      code: "passwordType",
      masterDataType: "otpType"
    },
    "retriesCount",
    "resentAfterDuration",
    "isHierarchyBased",
    "hierarchyRecord",
    "hierarchyRecipient",
    {
      name: "Expression",
      code: "expression",
      type: "conditionBuilder"
    } // "emailHeader",
    // "emailBody",
    // "smsBody",
    // "pushNotificationHeader",
    // "pushNotificationBody"
  ],
  templateEvent: [
    {
      code: "type",
      masterDataType: "notificationTemplateEvents"
    },
    "escalationCodes",
    "length",
    {
      code: "passwordType",
      masterDataType: "otpType"
    },
    "retriesCount",
    "resentAfterDuration",
    {
      name: "Expression",
      code: "expression"
    }
  ],
  smsTemplate: [
    "fieldType",
    {
      code: "gridCode",
      isNotificationGridCode: true
    },
    // "gridCode",
    "recipients",
    "additionalRecipients",
    {
      code: "body",
      name: "Message",
      dataType: inputTypes.findIndex(field => field.type === "textArea")
    }
  ],
  emailTemplate: [
    "fieldType",
    {
      code: "gridCode",
      isNotificationGridCode: true
    },
    // "gridCode",
    "recipients",
    "additionalRecipients",
    {
      code: "header",
      name: "Subject"
    },
    {
      code: "body",
      name: "Email",
      dataType: inputTypes.findIndex(field => field.type === "emailEditor")
    }
  ],
  pushNotificationTemplate: [
    // "fieldType",
    // {
    //   code: "gridCode",
    //   isNotificationGridCode: true
    // },
    // // "gridCode",
    // "recipients",
    {
      code: "header",
      name: "Title"
    },
    {
      code: "body",
      name: "Message",
      dataType: inputTypes.findIndex(field => field.type === "textArea")
    }
  ],
  customerQuestions: [
    {
      code: "city",
      masterDataType: "eKYCCITY"
    },
    "country",
    "occupation",
    "sourceOfIncome",
    "otherSourceOfIncome",
    "expectedSalary",
    "numberOfBusiness",
    "businessNature",
    "politicalfigureName",
    {
      code: "comments",
      dataType: inputTypes.findIndex(field => field.type === "text")
    }
  ],
  mathematicalExpressionBuilder: [
    {
      code: "mathematicalExpression",
      type: "text"
    }
  ],
  successInterview: [
    {
      code: "comments",
      name: "Comments",
      dataType: inputTypes.findIndex(field => field.type === "textArea")
    }
  ],
  failedInterview: [
    "reason",
    {
      code: "comments",
      name: "Comments",
      dataType: inputTypes.findIndex(field => field.type === "textArea")
    }
  ],
  restructuring: [
    {
      code: "markup",
      type: "number"
    },
    {
      code: "principle",
      type: "number"
    },
    {
      code: "totalTenure",
      type: "number",
      disabled: false
    },
    {
      code: "pastTenure",
      type: "number",
      disabled: false
    },
    {
      code: "remainingTenure",
      type: "number",
      disabled: false
    }
  ],
  bulkPayments: [
    "bulkPaymentType",
    "bulkPaymentValue",
    "penalty",
    "waiver",
    "penaltyAfterWaiver"
  ],
  createDiscrepancy: [
    "discrepancyCode",
    "discrepancyType",
    { code: "maxApprovalStage", masterDataType: "productStages" },
    {
      code: "isGrid",
      isGridCode: true
    },
    {
      code: "documentType",
      isUploadDocument: true
    },
    {
      code: "additionalInformation",
      isUploadDocument: true
    },
    {
      code: "gridCode",
      isGridCode: true
    },
    "gridIdentifierCode",
    "gridIdentifierValue",
    { code: "fieldCodes", hidden: true },
    "comments"
  ],
  updateDiscrepancy: ["comments"],
  createAdvice: [
    {
      code: "name",
      isHidden: true,
      hidden: true,
      isCreateAdvice: true
    },
    "adviceCode",
    {
      code: "isGrid",
      isGridCode: true
    },
    {
      name: "Advice Type",
      dataType: inputTypes.findIndex(field => field.type === "select"),
      code: "type",
      masterDataType: "adviceTypes"
    },

    {
      code: "gridCode",
      isGridCode: true
    },
    "gridIdentifierCode",
    "gridIdentifierValue",
    { code: "fieldCodes", hidden: true },
    {
      code: "documentType",
      isUploadDocument: true
    },
    {
      code: "additionalInformation",
      isUploadDocument: true
    },
    {
      code: "maxApprovalStage",
      masterDataType: "productStages"
    },
    "approvalGroupCodes",
    "comments"
  ],
  updateAdvice: ["comments"],
  kibor: ["date", "state", "rates"],
  kiborRates: ["tenor", "unit", "bid", "offer"],
  geofencing: [
    "sourceLongitude",
    "sourceLatitude",
    "destinationLongitude",
    "destinationLatitude",
    "radius",
    {
      code: "stages",
      isRequired: true
    }
  ],
  purging: [
    "name",
    "code",
    {
      code: "stageCode",
      isPurging: true
    },
    "tat",
    {
      code: "treatment",
      masterDataType: "purgingTreatment"
    },
    {
      code: "comments",
      dataType: inputTypes.findIndex(field => field.type === "text")
    }
  ],
  generalOverrides: [
    "name",
    "code",
    "logo",
    "primaryColor",
    "secondaryColor",
    "currency"
  ],
  baseForm: ["logo", "primaryColor", "secondaryColor", "currency"],
  sequenceGeneratorConfig: [
    "characterPool",
    "prefix",
    "suffix",
    "middleLength"
  ],
  ledgerMappings: [
    {
      code: "event",
      masterDataType: "GeneralLedgerType"
    },
    "entryType",
    "accountType",
    "accountNumber",
    { code: "amount", masterDataType: "fields" },
    {
      code: "stageCodes",
      isRequired: true
    },
    "expression"
  ],
  backendConditions: [
    {
      code: "type",
      masterDataType: "BackendConditionTypes"
    },
    "outcome",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  screenings: [
    {
      code: "stageCodes",
      type: "multiselect"
    },
    {
      code: "groupCodes",
      name: "Authorized Groups",
      isRequired: true
    },
    "isGrid",
    "gridCode",
    "gridIdentifier",
    "resultField",
    {
      code: "status",
      isRequired: true
    }
  ],
  screening_checks: [
    {
      code: "type",
      type: "select",
      masterDataType: "screeningConditionTypes"
    },
    "lists",
    "workflowField",
    {
      type: "select",
      code: "operation",
      masterDataType: "ScreeningConditionsOperations"
    },
    "fieldOfProvidedList",
    "message",
    "fuzzyThreshold",
    "fuzzyOperator",
    {
      code: "status",
      isRequired: true
    },
    "expression"
  ],
  paymentHierarchy: [
    {
      code: "name",
      type: "string"
    },
    {
      code: "code",
      type: "string"
    },
    {
      type: "multiselect",
      code: "hierarchy",
      masterDataType: "paymentTypes"
    }
  ],
  paymentHierarchy_overrides: [
    {
      type: "multiselect",
      code: "hierarchy",
      masterDataType: "paymentTypes"
    },
    {
      code: "status",
      isRequired: true
    },
    {
      code: "expression",
      type: "conditionBuilder"
    }
  ],
  incentiveLevels: [
    {
      code: "type",
      masterDataType: "incentiveLevelTypes"
    },
    "source",
    {
      name: "Groups",
      type: "multiselect",
      code: "groupCodes"
    }
  ],
  variants: [
    {
      code: "level",
      masterDataType: "incentiveLevelsFP"
    },
    {
      code: "type",
      masterDataType: "incentiveLevelVariantTypes"
    },
    "source",
    "expression"
  ],
  slabs: [
    {
      code: "variant",
      masterDataType: "variantsFP"
    },
    {
      code: "calculationBasedOn",
      masterDataType: "incentiveLevelVariantSlabCalculationBasedOn"
    },
    {
      type: "number",
      code: "from"
    },
    {
      type: "number",
      code: "to"
    },
    {
      name: "Value (Amount/Percentage)",
      type: "number",
      code: "value"
    }
  ],
  rejectionCoolingConfiguration: [
    "isGrid",
    {
      name: "Duration (Days)",
      type: "number",
      code: "duration"
    },
    "gridCode",
    "resultFieldCode",
    "identifierFieldCode",
    "productFieldCode",
    "reasonFieldCode",
    "commentFieldCode"
  ],
  customerConfiguration: [
    "nameFieldCode",
    "nationalIdentifierFieldCode",
    "dateOfBirthFieldCode",
    "mobileNumberFieldCode"
  ],
  manuallyDisbursed: [
    {
      code: "disbursementDate",
      type: "datePicker"
    },
    "sendSms",
    "misysDealReference"
  ]
};

export const getFieldsByModule = ({
  moduleCode,
  codesList = [],
  excludeStaticFields = false,
  isProduct = false
}) =>
  getFieldsByCodeList([
    ...(excludeStaticFields
      ? []
      : isProduct
      ? moduleFieldsDefinition["staticProductFactory"]
      : moduleFieldsDefinition["static"]),
    ...(moduleFieldsDefinition[moduleCode] || []),
    ...(codesList || [])
  ]);

export const getRecordFields = () =>
  getFieldsByCodeList([...moduleFieldsDefinition["recordStatic"]]);

export const productFactoryGridColumnsList = {
  quickViews: {
    root: [
      {
        dataField: "layout",
        masterDataType: "layouts",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Layout",
        showNameOnly: true
      },
      {
        dataField: "code",
        masterDataType: "quickview",
        shouldFetchMDM: true,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Code",
        withSpan: true,
        showNameOnly: false,
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "fieldCodes",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Fields",
        showNameOnly: true
      },
      {
        dataField: "checklistCode",
        masterDataType: "checkListFP",
        dataType: inputTypes.find(inputType => inputType.type === "select")
          ?.id,
        text: "CheckList",
        showNameOnly: true
      },
      {
        dataField: "icon",
        masterDataType: undefined,
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "iconPicker")
          ?.id,
        text: "Icon",
        showNameOnly: true
      },
      {
        dataField: "color",
        masterDataType: undefined,
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "colorPicker")
          ?.id,
        text: "Color",
        showNameOnly: true
      },
      {
        dataField: "overrides",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Overrides",
        isCountColumn: true
      }
    ],
    overrides: [
      {
        dataField: "code",
        masterDataType: "quickview",
        shouldFetchMDM: true,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Code",
        withSpan: true,
        showNameOnly: false,
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "layout",
        masterDataType: "layouts",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Layout",
        showNameOnly: true
      },
      {
        dataField: "icon",
        masterDataType: undefined,
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "iconPicker")
          ?.id,
        text: "Icon",
        showNameOnly: true
      },
      {
        dataField: "color",
        masterDataType: undefined,
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "colorPicker")
          ?.id,
        text: "Color",
        showNameOnly: true
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  stages: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "fields",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Fields",
        isCountColumn: true
      },
      {
        dataField: "authentications",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Authentication",
        isCountColumn: true
      },
      {
        dataField: "widgets",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Widgets",
        isCountColumn: true
      },
      {
        dataField: "stageCreationGroupsOverrides",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Stage Creation Groups Overrides",
        isCountColumn: true
      }
    ],
    fields: [
      {
        dataField: "code",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Field",
        showToolbar: true,
        showNameOnly: false,
        copyCode: "code"
      },
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Alias",
        showToolbar: false
      },
      {
        dataField: "group",
        masterDataType: "StageGroups",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Group",
        showNameOnly: false
      },
      {
        dataField: "header",
        masterDataType: "StageHeaders",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Header",
        showNameOnly: false
      },
      {
        dataField: "minLength",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Min Length"
      },
      {
        dataField: "maxLength",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Max Length"
      },
      {
        dataField: "isReadOnly",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Readonly"
      },
      {
        dataField: "isRequired",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Mandatory"
      }
      // {
      //   dataField: "isHidden", masterDataType: undefined, dataType: inputTypes.find(inputType => inputType.type === "checkbox")?.id, text: "Hidden"
      // },
    ],
    authentications: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "recommendOnly",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Recommend Only"
      },
      {
        dataField: "recommendingExpression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Recommending Condition"
      },
      {
        dataField: "recommendingLevelNumbers",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Recommending Deviation Levels"
      },
      // {
      //   dataField: "highestRecommendingLevelExceptionCount", masterDataType: undefined, dataType: inputTypes.find(inputType => inputType.type === "text")?.id, text: "Recommending Deviation Count",
      // },
      {
        dataField: "recommendingCriteria",
        masterDataType: "authenticationCriterion",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Recommending Criteria"
      },
      {
        dataField: "approvingExpression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Approving Condition"
      },
      {
        dataField: "approvingLevelNumber",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Approving Deviation Level"
      },
      {
        dataField: "approvingLevelCount",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Approving Deviation Count"
      },
      {
        dataField: "approvingCriteria",
        masterDataType: "authenticationCriterion",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Approving Criteria"
      }
    ],
    widgets: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "type",
        masterDataType: "stateWidgetTypeList",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Type"
      },
      {
        dataField: "placement",
        masterDataType: "placementList",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Placement"
      },
      {
        dataField: "renderOn",
        masterDataType: "renderOnList",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Render On"
      }
    ],
    stageCreationGroupsOverrides: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "groupCodes",
        masterDataType: "groups",
        dataSource: "identity",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Groups",
        showNameOnly: true
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  checkList: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "stages",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages",
        showNameOnly: true
      },
      {
        dataField: "type",
        masterDataType: "checkListTypes",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Checklist Type"
      },
      {
        dataField: "isGrid",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Is Grid"
      },
      {
        dataField: "gridIdentifier",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Grid Identifier",
        showNameOnly: false
      },
      {
        dataField: "mobileUploadOptions",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Mobile Upload Options"
      },
      {
        dataField: "errorMessage",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Error Message"
      },
      {
        dataField: "isMandatory",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Mandatory"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  conditions: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "stageCodes",
        masterDataType: "stagesWithZeroStageFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages",
        showNameOnly: true
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      },
      {
        dataField: "outcomes",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Total Outcomes",
        isCountColumn: true
      }
    ],
    outcomes: [
      // {
      //   dataField: "code", masterDataType: undefined, dataType: inputTypes.find(inputType => inputType.type === "text")?.id, text: "Code", showToolbar: true,
      // },
      {
        dataField: "code",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Field",
        showToolbar: true,
        showNameOnly: false,
        copyCode: "code"
      },
      {
        dataField: "value",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Value"
      },
      {
        dataField: "group",
        masterDataType: "StageGroups",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Group",
        showNameOnly: false
      },
      {
        dataField: "header",
        masterDataType: "StageHeaders",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Header",
        showNameOnly: false
      },
      {
        dataField: "isReadOnly",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Readonly"
      },
      {
        dataField: "isRequired",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Mandatory"
      },
      {
        dataField: "isHidden",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Hidden"
      },
      {
        dataField: "outcomeRespect",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Outcome Respect"
      }
    ]
  },
  scenarios: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      },
      {
        dataField: "scenarioStages",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Stages",
        isCountColumn: true
      }
    ],
    scenarioStages: [
      {
        dataField: "code",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Stage",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "entryCondition",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Entry Condition",
        representations: ["HR", "SQL", "JSONLogic"]
      },
      {
        dataField: "exitCondition",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Exit Condition",
        representations: ["HR", "SQL", "JSONLogic"]
      }
    ]
  },
  rules: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "stageCodes",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages",
        showNameOnly: true
      },
      {
        dataField: "type",
        masterDataType: "types",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Checklist Type"
      },
      {
        dataField: "isGrid",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Is Grid"
      },
      {
        dataField: "gridIdentifier",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Grid Identifier",
        showNameOnly: false
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      },
      {
        dataField: "message",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Message"
      },
      {
        dataField: "canDeviate",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Can Deviate"
      },
      {
        dataField: "deviationCode",
        masterDataType: "deviation",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Deviation Code",
        showToolbar: true,
        showNameOnly: false
      },
      {
        dataField: "overrides",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Overrides",
        isCountColumn: true
      }
    ],
    overrides: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "message",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Message"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  screenings: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "stageCodes",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages",
        showNameOnly: true
      },
      {
        dataField: "isGrid",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Is Grid"
      },
      {
        dataField: "gridIdentifier",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Grid Identifier",
        showNameOnly: false
      },
      {
        dataField: "checks",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Checks",
        isCountColumn: true
      }
    ],
    checks: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "type",
        masterDataType: "screeningConditionTypes",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Type"
      },
      {
        dataField: "message",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Message"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  scoringScheme: {
    schemes: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "fieldCode",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Field",
        showNameOnly: false
      },
      {
        dataField: "weightage",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Weightage"
      },
      {
        dataField: "outOf",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Out Of"
      }
    ]
  },
  scoringCard: {
    cards: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "fieldCode",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Field",
        showNameOnly: false
      },
      {
        dataField: "score",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Score"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  scoringPostProcessing: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "operation",
        masterDataType: "scorePostProcessingOperationType",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Operation Type"
      },
      {
        dataField: "processingValue",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Processing Value"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  scoringLabel: {
    labels: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "fromValue",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "From"
      },
      {
        dataField: "toValue",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "To"
      },
      {
        dataField: "orr",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "ORR"
      },
      {
        dataField: "frr",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "FRR"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      },
      {
        dataField: "color",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Color"
      }
    ]
  },
  deviations: {
    root: [
      {
        dataField: "code",
        masterDataType: "deviation",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Deviation",
        showToolbar: true,
        showNameOnly: false,
        copyCode: "code"
      },
      {
        dataField: "stages",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages"
      }
    ]
  },
  discrepancies: {
    root: [
      {
        dataField: "code",
        masterDataType: "discrepancy",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Discrepancy",
        showToolbar: true,
        showNameOnly: false,
        copyCode: "code"
      },
      {
        dataField: "stages",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages"
      },
      {
        dataField: "approvalGroups",
        masterDataType: "groups",
        dataSource: "identity",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Approval Groups",
        showNameOnly: true
      },
      {
        dataField: "type",
        masterDataType: "discrepancyTypes",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Discrepancy Type"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  scoringCriteria: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "isGrid",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Is Grid"
      },
      {
        dataField: "gridIdentifier",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Grid Identifier",
        showNameOnly: false
      },
      {
        dataField: "schemes",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Schemes",
        isCountColumn: true
      },
      {
        dataField: "scores",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Scores",
        isCountColumn: true
      },
      {
        dataField: "postProcessing",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Post Processing",
        isCountColumn: true
      },
      {
        dataField: "labels",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Labels",
        isCountColumn: true
      }
    ],
    schemes: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "fieldCode",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Field",
        showNameOnly: false
      },
      {
        dataField: "weightage",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Weightage"
      },
      {
        dataField: "outOf",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Out Of"
      }
    ],
    scores: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "fieldCode",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Field",
        showNameOnly: false
      },
      {
        dataField: "value",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Value"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ],
    postProcessing: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "operation",
        masterDataType: "scorePostProcessingOperationType",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Operation Type"
      },
      {
        dataField: "processingValue",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Processing Value"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ],
    labels: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "fromValue",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "From"
      },
      {
        dataField: "toValue",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "To"
      },
      {
        dataField: "orr",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "ORR"
      },
      {
        dataField: "frr",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "FRR"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      },
      {
        dataField: "color",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Color"
      }
    ]
  },
  peerBenchmark: {
    peerBenchmarkVariables: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "valueField",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Value Field",
        showNameOnly: false
      },
      {
        dataField: "percentileValueField",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Percentile Value Field",
        showNameOnly: false
      },
      {
        dataField: "percentileRankField",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Percentile Rank Field",
        showNameOnly: false
      }
    ]
  },
  strategies: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "useCycleCuts",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Use Cycle Cuts"
      },
      {
        dataField: "escalations",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Escalations",
        isCountColumn: true
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ],
    escalations: [
      {
        dataField: "code",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Field",
        showToolbar: true,
        showNameOnly: false,
        copyCode: "code"
      },
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Alias",
        showToolbar: false
      },
      {
        dataField: "isEscalate",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Is Escalate"
      },
      {
        dataField: "isRequired",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Send Notifications"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  queues: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "allocation",
        masterDataType: "allocations",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Allocation",
        isCountColumn: true
      }
    ]
  },
  dunningLetters: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      // {
      //   dataField: "report", masterDataType: "reports", dataType: inputTypes.find(inputType => inputType.type === "select")?.id, text: "Report", isCountColumn: true,
      // },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  importMappings: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "group",
        masterDataType: "DocumentGroups",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "File Group",
        showToolbar: false
      }
    ],
    mappings: [
      {
        dataField: "fieldCode",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Field",
        showToolbar: true,
        showNameOnly: false,
        copyCode: "fieldCode"
      },
      {
        dataField: "cell",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Cell",
        showToolbar: false
      }
    ]
  },
  emiCriteria: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "emiField",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "EMI Field",
        showNameOnly: false
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  preChecks: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: "Integrations",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Code",
        showToolbar: true,
        showNameOnly: false
      },
      {
        dataField: "stageCodes",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages",
        showNameOnly: true
      },
      {
        dataField: "type",
        masterDataType: "types",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Type"
      },
      {
        dataField: "message",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Message"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      },
      {
        dataField: "canDeviate",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Can Deviate"
      },
      {
        dataField: "deviationCode",
        masterDataType: "deviation",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Deviation Code",
        showToolbar: true,
        showNameOnly: false
      },
      {
        dataField: "deviationApprovalGroupCodes",
        masterDataType: "groups",
        dataSource: "identity",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Deviation Approval Group Codes",
        showNameOnly: true
      }
    ]
  },
  processingFees: {
    overrides: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "type",
        masterDataType: "processingFeesTypes",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Type"
      },
      {
        dataField: "percentage",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Percentage"
      },
      {
        dataField: "amount",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Amount"
      },
      {
        dataField: "criteria",
        masterDataType: "processingFeesCriteria",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Criteria"
      }
    ]
  },
  markup: {
    overrides: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      // {
      //   dataField: "type",
      //   masterDataType: "markupTypes",
      //   shouldFetchMDM: false,
      //   dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
      //   text: "Type"
      // },
      {
        dataField: "value",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Value"
      },
      // {
      //   dataField: "kiborFieldCode",
      //   masterDataType: "fields",
      //   dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
      //   text: "Kibor Type",
      //   showNameOnly: true
      // },
      {
        dataField: "stages",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages",
        showNameOnly: true
      }
    ]
  },
  dpd: {
    buckets: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "from",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "From"
      },
      {
        dataField: "to",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "To"
      },
      {
        dataField: "status",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Status"
      }
    ]
  },
  deviationQuota: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "type",
        masterDataType: "criteriaTypes",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "type",
        withSpan: true,
        showNameOnly: false,
        showToolbar: true,
        copyCode: "type"
      },
      {
        dataField: "period",
        masterDataType: "timePeriodTypes",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Time Period"
      },
      {
        dataField: "deviationLevel",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "Deviation Level"
      }
    ]
  },
  insurance: {
    overrides: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "rate",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "Rate"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  turnAroundTime: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "escalationType",
        masterDataType: "escalationType",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Escalation Type"
      },
      {
        dataField: "stageCode",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Stage",
        showNameOnly: true
      },
      // {
      //   dataField: "authenticationCode", masterDataType: "authenticationFP", shouldFetchMDM: false, dataType: inputTypes.find(inputType => inputType.type === "select")?.id, text: "Authentication", showNameOnly: true
      // },
      {
        dataField: "hours",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "Duration (Hours)"
      },
      // {
      //   dataField: "type",
      //   masterDataType: "turnAroundTimeType",
      //   dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
      //   text: "Type"
      // },
      {
        dataField: "treatment",
        masterDataType: "turnAroundTimeTreatment",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Treatment"
      }
    ],
    escalationLevels: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "level",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "Level"
      },
      {
        dataField: "duration",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "Duration (Hours)"
      }
    ]
  },
  dedupingCriteria: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "stageCodes",
        masterDataType: "stagesWithZeroStageFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages",
        showNameOnly: true
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      },
      {
        dataField: "dedupeConditions",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Conditions",
        isCountColumn: true
      }
    ],
    dedupeConditions: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "isGrid",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "checkbox")
          ?.id,
        text: "Is Grid"
      },
      {
        dataField: "gridCode",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Grid",
        showNameOnly: false
      },
      {
        dataField: "gridIdentifier",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Grid Identifier",
        showNameOnly: false
      },
      {
        dataField: "fieldCodes",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Fields",
        showNameOnly: true
      },
      {
        dataField: "status",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Status"
      }
    ]
  },
  exposureSheet: {
    gridExposureSheets: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "gridCode",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Grid",
        showNameOnly: false
      },
      {
        dataField: "gridIdentifier",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Grid Identifier",
        showNameOnly: false
      }
    ]
  },
  advices: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "stages",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages"
      },
      {
        dataField: "expression",
        masterDataType: undefined,
        dataType: inputTypes.find(
          inputType => inputType.type === "conditionBuilder"
        )?.id,
        text: "Condition"
      }
    ]
  },
  installmentPlan: {
    firstInstallmentBehaviours: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "type",
        masterDataType: "firstInstallmentBehaviourTypes",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Type"
      },
      {
        dataField: "from",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "From"
      },
      {
        dataField: "to",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "To"
      }
    ]
  },
  geofencing: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "sourceLongitude",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Source Longitude",
        showNameOnly: false
      },
      {
        dataField: "sourceLatitude",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Source Latitude",
        showNameOnly: false
      },
      {
        dataField: "destinationLongitude",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Destination Longitude",
        showNameOnly: false
      },
      {
        dataField: "destinationLatitude",
        masterDataType: "fields",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Destination Latitude",
        showNameOnly: false
      },
      {
        dataField: "radius",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "Radius (In meters)"
      },
      {
        dataField: "stages",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages",
        showNameOnly: true
      }
    ]
  },
  purging: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "stageCode",
        masterDataType: "stagesFP",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Stage",
        showNameOnly: true
      },
      {
        dataField: "tat",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "Tat"
      },
      {
        dataField: "treatment",
        masterDataType: "purgingTreatment",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Treatment"
      }
    ]
  },
  ledgerMappings: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "event",
        masterDataType: "GeneralLedgerType",
        shouldFetchMDM: true,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Event"
      },
      {
        dataField: "entryType",
        masterDataType: "entryTypes",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Entry Type"
      },
      {
        dataField: "stageCodes",
        masterDataType: "stagesFP",
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Stages"
      }
    ]
  },
  backendConditions: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "type",
        masterDataType: "BackendConditionTypes",
        shouldFetchMDM: true,
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Type"
      }
    ]
  },
  paymentHierarchy: {
    overrides: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "hierarchy",
        masterDataType: "paymentTypes",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Hierarchy"
      }
    ]
  },
  incentiveLevels: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "type",
        masterDataType: "incentiveLevelTypes",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Type"
      },
      {
        dataField: "groups",
        masterDataType: "groups",
        dataSource: "identity",
        shouldFetchMDM: false,
        dataType: inputTypes.find(inputType => inputType.type === "multiselect")
          ?.id,
        text: "Groups",
        showNameOnly: true
      }
    ]
  },
  variants: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "level",
        masterDataType: "incentiveLevelsFP",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Level"
      },
      {
        dataField: "type",
        masterDataType: "incentiveLevelVariantTypes",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Type"
      }
    ]
  },
  slabs: {
    root: [
      {
        dataField: "name",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Name",
        showToolbar: true,
        copyCode: "code"
      },
      {
        dataField: "code",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "text")?.id,
        text: "Code"
      },
      {
        dataField: "variant",
        masterDataType: "variantsFP",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Variant"
      },
      {
        dataField: "calculationBasedOn",
        masterDataType: "incentiveLevelVariantSlabCalculationBasedOn",
        dataType: inputTypes.find(inputType => inputType.type === "select")?.id,
        text: "Calculation Based On"
      },
      {
        dataField: "from",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "From"
      },
      {
        dataField: "to",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "To"
      },
      {
        dataField: "value",
        masterDataType: undefined,
        dataType: inputTypes.find(inputType => inputType.type === "number")?.id,
        text: "Value (Amount/Percentage)"
      }
    ]
  }
};

export const getProductFactoryGridColumnsDefinition = ({
  moduleCode,
  gridCode
}) => {
  if (!moduleCode) return [];

  if (!gridCode)
    return productFactoryGridColumnsList?.[moduleCode]?.["root"] || [];
  return productFactoryGridColumnsList?.[moduleCode]?.[gridCode] || [];
};

export const productFactoryFields = [
  {
    _id: "6413d2a3-6f67-46b8-b70c-e0159666d6be",
    name: "Basic Information",
    code: "basicInformation",
    itemPayloadKey: "basicInformation",
    itemDataType: "object",
    description: "Basic Information",
    level: 1,
    form: getFieldsByModule({
      moduleCode: "basicInformation",
      isProduct: true
    }),
    list: null
  },
  {
    _id: "6413d2a3-6f67-46b8-b70c-e0159666d6ba",
    name: "Quick Views",
    code: "quickViews",
    description: "Quick Views",
    level: 1,
    form: null,
    list: {
      heading: "Quick Views",
      code: "quickViews",
      itemPayloadKey: "quickViews",
      itemDataType: "array",
      columns: [
        "priority",
        "name",
        "code",
        "fieldCodes",
        "layout",
        "overrides"
      ],
      conditionBuilder: false,
      level: 2,
      form: getFieldsByModule({
        moduleCode: "quickViews",
        excludeStaticFields: true
      }),
      list: {
        heading: "Overrides",
        code: "overrides",
        itemPayloadKey: "overrides",
        itemDataType: "array",
        columns: ["priority", "name", "code", "expression"],
        level: 3,
        form: getFieldsByModule({
          moduleCode: "quickViews_overrides",
          excludeStaticFields: true
        })
      }
    },
    header: "Loan Origination System"
  },
  {
    _id: "3ce9e311-ce10-4d17-848e-8925e808e135",
    name: "Stages",
    code: "stages",
    description: "stages",
    level: 1,
    form: null,
    list: {
      heading: "Stages",
      code: "stages",
      itemPayloadKey: "stages",
      itemDataType: "array",
      // columns: ["priority", "name", "code", "turnAroundTime", "fields", "checklist", "approvalLevels"],
      columns: ["priority", "name", "code", "fields"],
      level: 2,
      form: getFieldsByModule({ moduleCode: "stages" }),
      tabs: [
        {
          name: "Fields",
          code: "fields",
          description: "fields",
          level: 2,
          form: null,
          list: {
            heading: "Fields",
            code: "fields",
            itemPayloadKey: "fields",
            itemDataType: "array",
            columns: [
              "priority",
              "name",
              "code",
              "group",
              "header",
              "isRequired",
              "isReadOnly"
            ],
            level: 3,
            form: getFieldsByModule({
              moduleCode: "stageFields",
              excludeStaticFields: true
            })
          }
        },
        {
          name: "Authentications",
          code: "authentications",
          description: "Authentications",
          columns: ["priority", "name", "code", "expression"],
          heading: "Authentications",
          level: 2,
          form: null,
          list: {
            heading: "Authentications",
            code: "authentications",
            itemPayloadKey: "authentications",
            itemDataType: "array",
            columns: [
              "priority",
              "name",
              "code",
              "levelNumber",
              "levelCount",
              "expression"
            ],
            level: 3,
            form: getFieldsByModule({
              moduleCode: "stage_authentications"
            })
          }
        },
        {
          name: "Widgets",
          code: "widgets",
          description: "Widgets",
          heading: "Widgets",
          level: 2,
          form: null,
          list: {
            heading: "Widgets",
            code: "widgets",
            itemPayloadKey: "widgets",
            itemDataType: "array",
            level: 3,
            form: getFieldsByModule({ moduleCode: "widgets" })
          }
        },
        {
          name: "Stage Creation Groups Overrides",
          code: "stageCreationGroupsOverrides",
          description: "Stage Creation Groups Overrides",
          heading: "Stage Creation Groups Overrides",
          level: 2,
          form: null,
          list: {
            heading: "Stage Creation Groups Overrides",
            code: "stageCreationGroupsOverrides",
            itemPayloadKey: "stageCreationGroupsOverrides",
            itemDataType: "array",
            level: 3,
            form: getFieldsByModule({
              moduleCode: "stageCreationGroupsOverrides"
            })
          }
        }
      ]
    }
  },
  {
    _id: "6413d2a3-6f67-46b8-b70c-e0159666d6bw",
    name: "Check List",
    code: "checkList",
    description: "Check List",
    level: 1,
    list: {
      heading: "Check List",
      code: "checkList",
      itemPayloadKey: "checkList",
      itemDataType: "array",
      // columns: ["priority", "name", "code", "turnAroundTime", "fields", "checklist", "approvalLevels"],
      columns: ["priority", "name", "code"],
      level: 2,
      form: getFieldsByModule({ moduleCode: "checkList" })
    }
  },
  {
    _id: "23534311-ce10-4d17-848e-8925e808e135",
    name: "Conditions",
    code: "conditions",
    description: "conditions",
    level: 1,
    form: null,
    list: {
      heading: "Conditions",
      code: "conditions",
      itemPayloadKey: "conditions",
      itemDataType: "array",
      columns: ["priority", "name", "code", "stages", "expression"],
      level: 2,
      form: getFieldsByModule({ moduleCode: "conditions" }),
      list: {
        heading: "Outcomes",
        code: "outcomes",
        itemPayloadKey: "outcomes",
        itemDataType: "array",
        columns: [
          "priority",
          "name",
          "code",
          "isRequired",
          "isReadOnly",
          "isHidden",
          "value"
        ],
        level: 3,
        form: getFieldsByModule({
          moduleCode: "outcomes",
          excludeStaticFields: true
        })
      }
    }
  },
  {
    _id: "3ce9e311-ce10-4d17-848e-8925e808e141",
    name: "Scenarios",
    code: "scenarios",
    description: "Scenarios",
    level: 1,
    form: null,
    list: {
      heading: "Scenarios",
      code: "scenarios",
      itemPayloadKey: "scenarios",
      itemDataType: "array",
      columns: ["priority", "name", "code", "expression"],
      level: 2,
      form: getFieldsByModule({ moduleCode: "scenarios" }),
      list: {
        heading: "Stages",
        code: "scenarioStages",
        itemPayloadKey: "scenarioStages",
        itemDataType: "array",
        columns: [
          "priority",
          "name",
          "code",
          "entryCondition",
          "exitCondition"
        ],
        level: 3,
        form: getFieldsByModule({
          moduleCode: "scenario_stages",
          excludeStaticFields: true
        })
      }
    },
    header: "Loan Origination System"
  },
  {
    _id: "4778c0a0-b4a9-4b69-ad4f-fde8765a8fd4",
    name: "Dedupe Criteria",
    code: "dedupingCriteria",
    description: "dedupingCriteria",
    level: 1,
    form: null,
    list: {
      heading: "Dedupe Criteria",
      code: "dedupingCriteria",
      itemPayloadKey: "dedupingCriteria",
      itemDataType: "array",
      columns: ["priority", "stage", "dedupeConditions"],
      conditionBuilder: false,
      level: 2,
      form: getFieldsByModule({ moduleCode: "dedupeCriteria" }),
      list: {
        heading: "Conditions",
        code: "dedupeConditions",
        itemPayloadKey: "dedupeConditions",
        itemDataType: "array",
        columns: [
          "priority",
          "name",
          "code",
          "isGrid",
          "gridCode",
          "fieldCodes",
          "status"
        ],
        level: 3,
        form: getFieldsByModule({
          moduleCode: "dedupeConditions"
        })
      }
    },
    header: "Loan Origination System"
  },
  {
    _id: "2fcd44a0-f1bb-4c0b-8e72-0685e2d2b939",
    name: "Screenings",
    code: "screenings",
    description: "Screenings",
    level: 1,
    form: null,
    list: {
      heading: "Screenings",
      code: "screenings",
      itemPayloadKey: "screenings",
      itemDataType: "array",
      level: 2,
      form: getFieldsByModule({ moduleCode: "screenings" }),
      list: {
        heading: "Checks",
        code: "checks",
        itemPayloadKey: "checks",
        itemDataType: "array",
        level: 3,
        form: getFieldsByModule({
          moduleCode: "screening_checks"
        })
      }
    }
  },
  {
    _id: "3ce9e311-ce10-4d17-848e-8925e808e130",
    name: "Rules",
    code: "rules",
    description: "Policies",
    level: 1,
    form: null,
    list: {
      heading: "Rules",
      code: "rules",
      itemPayloadKey: "rules",
      itemDataType: "array",
      columns: ["priority", "name", "code", "field", "stages", "overrides"],
      level: 2,
      form: getFieldsByModule({ moduleCode: "policies" }),
      list: {
        heading: "Overrides",
        code: "overrides",
        itemPayloadKey: "overrides",
        itemDataType: "array",
        columns: ["priority", "name", "code", "expression"],
        level: 3,
        form: getFieldsByModule({
          moduleCode: "policies_overrides"
        })
      }
    }
  },
  {
    _id: "d916c510-8bcc-4bcc-ae28-c16309222002",
    name: "Scoring Criteria",
    code: "scoringCriteria",
    description: "Scoring Criteria",
    level: 1,
    form: null,
    list: {
      heading: "Scoring Criteria",
      code: "scoringCriteria",
      itemPayloadKey: "scoringCriteria",
      itemDataType: "array",
      columns: ["priority", "name", "code"],
      level: 2,
      form: getFieldsByModule({
        moduleCode: "scoringCriteria"
      }),
      tabs: [
        {
          name: "Schemes",
          code: "schemes",
          description: "Schemes",
          level: 2,
          form: null,
          list: {
            heading: "Schemes",
            code: "schemes",
            itemPayloadKey: "schemes",
            itemDataType: "array",
            columns: ["priority", "name", "code"],
            level: 3,
            form: getFieldsByModule({
              moduleCode: "criteria_schemes"
            })
          }
        },
        {
          name: "Scores",
          code: "scores",
          description: "Scores",
          columns: ["priority", "name", "code"],
          level: 2,
          form: null,
          list: {
            heading: "Scores",
            code: "scores",
            itemPayloadKey: "scores",
            itemDataType: "array",
            columns: ["priority", "name", "code"],
            level: 3,
            form: getFieldsByModule({
              moduleCode: "criteria_scores"
            })
          }
        },
        {
          name: "Post Processing",
          code: "postProcessing",
          description: "Post Processing",
          columns: ["priority", "name", "code"],
          level: 2,
          form: null,
          list: {
            heading: "Post Processing",
            code: "postProcessing",
            itemPayloadKey: "postProcessing",
            itemDataType: "array",
            columns: ["priority", "name", "code"],
            level: 3,
            form: getFieldsByModule({
              moduleCode: "criteria_post_processing"
            })
          }
        },
        {
          name: "Labels",
          code: "labels",
          description: "Labels",
          columns: ["priority", "name", "code"],
          level: 2,
          form: null,
          list: {
            heading: "Labels",
            code: "labels",
            itemPayloadKey: "labels",
            itemDataType: "array",
            columns: ["priority", "name", "code"],
            level: 3,
            form: getFieldsByModule({
              moduleCode: "criteria_labels"
            })
          }
        }
      ]
    }
  },
  {
    _id: "3ce9e311-ce10-4d17-848e-8925e808e137",
    name: "Computations",
    code: "computations",
    description: "Computations",
    form: null,
    list: null,
    tabs: [
      {
        name: "Debt Burden Ratio",
        code: "debtBurdenRatio",
        itemPayloadKey: "debtBurdenRatio",
        itemDataType: "object",
        description: "Debt Burden Ratio",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "debtBurdenRatio",
          excludeStaticFields: true
        }),
        list: null
      },
      {
        name: "Debt Service Ratio",
        code: "debtServiceRatio",
        itemPayloadKey: "debtServiceRatio",
        itemDataType: "object",
        description: "Debt Service Ratio",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "debtServiceRatio",
          excludeStaticFields: true
        }),
        list: null
      }
      // {
      //   name: "Exposure Sheet",
      //   code: "exposureSheet",
      //   itemPayloadKey: "exposureSheet",
      //   itemDataType: "object",
      //   description: "Exposure Sheet",
      //   level: 1,
      //   form: getFieldsByModule({
      //     moduleCode: "exposureSheet",
      //     excludeStaticFields: true
      //   }),
      //   list: null
      // },
      // {
      //   name: "Installment Plan",
      //   code: "installmentPlan",
      //   itemPayloadKey: "installmentPlan",
      //   itemDataType: "object",
      //   description: "Installment Plan",
      //   level: 1,
      //   form: getFieldsByModule({
      //     moduleCode: "installmentPlan",
      //     excludeStaticFields: true
      //   }),
      //   list: null
      // }
    ],
    header: "Loan Origination System"
  },
  {
    _id: "78aee806-95b8-442c-84db-f463c69e39d7",
    name: "Exposure Sheet",
    code: "exposureSheet",
    description: "Exposure Sheet",
    form: null,
    list: null,
    tabs: [
      {
        name: "Exposure Sheet",
        code: "exposureSheet",
        itemPayloadKey: "exposureSheet",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "exposureSheet"
        }),
        list: {
          heading: "Grid Exposure Sheets",
          code: "gridExposureSheets",
          itemPayloadKey: "gridExposureSheets",
          itemDataType: "array",
          level: 2,
          form: getFieldsByModule({
            moduleCode: "gridExposureSheets"
          })
        }
      }
    ],
    header: "Loan Origination System"
  },
  {
    _id: "3ce9e311-ce10-4d17-848e-8925e808e131",
    name: "Strategies",
    code: "strategies",
    description: "Strategies",
    level: 1,
    form: null,
    list: {
      heading: "Strategies",
      code: "strategies",
      itemPayloadKey: "strategies",
      itemDataType: "array",
      columns: ["priority", "name", "code", "expression"],
      level: 2,
      form: getFieldsByModule({ moduleCode: "strategies" }),
      list: {
        heading: "Escalations",
        code: "escalations",
        itemPayloadKey: "escalations",
        itemDataType: "array",
        columns: ["priority", "name", "code", "expression"],
        level: 3,
        form: getFieldsByModule({ moduleCode: "escalations" })
      }
    },
    header: "Collection Management System"
  },
  {
    _id: "b8d27641-660c-4e2a-827d-10f7dbded06c",
    name: "Queues",
    code: "queues",
    description: "Queues",
    level: 1,
    form: null,
    list: {
      heading: "Queues",
      code: "queues",
      itemPayloadKey: "queues",
      itemDataType: "array",
      columns: ["priority", "name", "code", "expression"],
      level: 2,
      form: getFieldsByModule({ moduleCode: "queues" }),
      list: null
    },
    header: "Collection Management System"
  },
  {
    _id: "3328f578-5668-474f-accf-34e12a56fdf2",
    name: "Dunning Letters",
    code: "dunningLetters",
    description: "Dunning Letters",
    level: 1,
    form: null,
    list: {
      heading: "Dunning Letters",
      code: "dunningLetters",
      itemPayloadKey: "dunningLetters",
      itemDataType: "array",
      columns: ["priority", "name", "code", "expression"],
      level: 2,
      form: getFieldsByModule({
        moduleCode: "dunningLetters"
      }),
      list: null
    },
    header: "Collection Management System"
  },
  {
    _id: "4778c0a0-b4a9-4b69-ad4f-fde5765a8fd1",
    name: "Notifications",
    code: "notifications",
    description: "Notifications",
    level: 1,
    form: null,
    list: {
      heading: "Notifications",
      code: "notifications",
      itemPayloadKey: "notifications",
      itemDataType: "array",
      columns: ["priority", "name", "code", "expression"],
      level: 2,
      form: getFieldsByModule({ moduleCode: "notifications" }),
      list: null
    }
  },
  {
    _id: "d5b5c4a7-eda2-4dfa-afd7-3a1afe9e10da",
    name: "Deviation",
    code: "deviations",
    description: "Deviations",
    level: 1,
    form: null,
    list: {
      heading: "Deviations",
      code: "deviations",
      itemPayloadKey: "deviations",
      itemDataType: "array",
      columns: ["priority", "name", "code", "stages"],
      conditionBuilder: false,
      level: 2,
      form: getFieldsByModule({
        moduleCode: "deviation",
        excludeStaticFields: true
      }),
      list: null
    },
    header: "Loan Origination System"
  },
  {
    _id: "3ce9e111-ce10-4d17-848e-8925e808e131",
    name: "Peer Benchmark",
    code: "peerBenchmark",
    description: "Peer Benchmark",
    form: null,
    list: null,
    tabs: [
      {
        name: "Peer Benchmark",
        code: "peerBenchmark",
        itemPayloadKey: "peerBenchmark",
        itemDataType: "object",
        columns: [
          "priority",
          "name",
          "code",
          "stageCodes",
          "primaryCriteriaField",
          "secondaryCriteriaField"
        ],
        level: 1,
        form: getFieldsByModule({
          moduleCode: "peer_benchmark_outer",
          excludeStaticFields: true
        }),
        list: {
          heading: "Variables",
          code: "peerBenchmarkVariables",
          itemPayloadKey: "peerBenchmarkVariables",
          itemDataType: "array",
          columns: [
            "priority",
            "name",
            "code",
            "valueField",
            "percentileValueField",
            "percentileRankField"
          ],
          level: 2,
          form: getFieldsByModule({
            moduleCode: "peer_benchmark_inner"
          })
        }
      }
    ]
  },
  {
    _id: "3ce9e455-ce10-4d17-848e-8925e808e131",
    name: "Import Mappings",
    code: "importMappings",
    description: "Import Mapping",
    level: 1,
    form: null,
    list: {
      heading: "Import Mappings",
      code: "importMappings",
      itemPayloadKey: "importMappings",
      itemDataType: "array",
      columns: ["priority", "name", "code"],
      level: 2,
      form: getFieldsByModule({
        moduleCode: "importMappings"
      }),
      list: {
        heading: "Mappings",
        code: "mappings",
        itemPayloadKey: "mappings",
        itemDataType: "array",
        columns: ["priority", "name", "code", "cell", "fieldCode"],
        level: 3,
        form: getFieldsByModule({
          moduleCode: "mappings",
          excludeStaticFields: true
        })
      }
    }
  },
  {
    _id: "f0a7df7c-8457-484a-a28e-088d0e9c651b",
    name: "EMI Criteria",
    code: "emiCriteria",
    description: "EMI Criteria",
    level: 1,
    form: null,
    list: {
      heading: "EMI Criteria",
      code: "emiCriteria",
      itemPayloadKey: "emiCriteria",
      itemDataType: "array",
      columns: [
        "priority",
        "name",
        "code",
        "stageCodes",
        "rate",
        "numberOfPayments",
        "loanValue",
        "residualValue",
        "method",
        "paymentFrequency",
        "emiField"
      ],
      conditionBuilder: false,
      level: 2,
      form: getFieldsByModule({ moduleCode: "emiCriteria" }),
      list: null
    }
  },
  {
    _id: "6413d2a3-6f67-46b9-b71c-f0159666d6cw",
    name: "Pre Check",
    code: "preChecks",
    description: "Pre Checking",
    level: 1,
    list: {
      heading: "Pre Check",
      code: "preChecks",
      itemPayloadKey: "preChecks",
      itemDataType: "array",
      // columns: ["priority", "name", "code", "turnAroundTime", "fields", "checklist", "approvalLevels"],
      columns: ["priority", "name", "code"],
      level: 2,
      form: getFieldsByModule({
        moduleCode: "preChecks",
        excludeStaticFields: true
      })
    }
  },
  {
    _id: "d5b5c4a7-eda2-4dfa-afd7-3a1bdf2e10da",
    name: "Discrepancy",
    code: "discrepancies",
    description: "Discrepancies",
    level: 1,
    form: null,
    list: {
      heading: "Discrepancies",
      code: "discrepancies",
      itemPayloadKey: "discrepancies",
      itemDataType: "array",
      columns: ["priority", "name", "code", "stages", "approvalGroups"],
      conditionBuilder: false,
      level: 2,
      form: getFieldsByModule({
        moduleCode: "discrepancies",
        excludeStaticFields: true
      }),
      list: null
    },
    header: "Loan Origination System"
  },
  {
    _id: "1817f05f-967e-487b-ab1e-11dd797b3256",
    name: "Markup",
    code: "markup",
    description: "Markup",
    form: null,
    list: null,
    tabs: [
      {
        name: "Markup",
        code: "markup",
        itemPayloadKey: "markup",
        itemDataType: "object",
        columns: [
          "priority",
          "name",
          "code",
          "type",
          "value",
          "kiborFieldCode",
          "stages"
        ],
        level: 1,
        form: getFieldsByModule({
          moduleCode: "markup",
          excludeStaticFields: true
        }),
        list: {
          heading: "Overrides",
          code: "overrides",
          itemPayloadKey: "overrides",
          itemDataType: "array",
          columns: [
            "priority",
            "name",
            "code",
            "type",
            "value",
            "kiborFieldCode",
            "stages"
          ],
          level: 2,
          form: getFieldsByModule({
            moduleCode: "markup_overrides"
          })
        }
      }
    ],
    header: "Loan Origination System"
  },
  {
    _id: "b72b4141-85b7-40de-8696-a8264f98c54e",
    name: "DPD",
    code: "dpd",
    description: "dpd",
    form: null,
    list: null,
    tabs: [
      {
        name: "DPD",
        code: "dpd",
        itemPayloadKey: "dpd",
        itemDataType: "object",
        columns: ["name", "code", "from", "to", "status"],
        level: 1,
        form: getFieldsByModule({
          moduleCode: "dpd"
        }),
        list: {
          heading: "Buckets",
          code: "buckets",
          itemPayloadKey: "buckets",
          itemDataType: "array",
          columns: ["name", "code", "from", "to", "status"],
          level: 2,
          form: getFieldsByModule({
            moduleCode: "dpd_buckets",
            excludeStaticFields: true
          })
        }
      }
    ],
    header: "Loan Management System"
  },
  {
    _id: "49f1d0db-e64c-4c9f-9b41-a35534e9c5a7",
    name: "Account Statement Configuration",
    code: "accountStatementConfiguration",
    description: "Account Statement Configuration",
    form: null,
    list: null,
    tabs: [
      {
        name: "Account Statement Configuration",
        code: "accountStatementConfiguration",
        itemPayloadKey: "accountStatementConfiguration",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "statement"
        })
      }
    ],
    header: "Loan Origination System"
  },
  {
    _id: "ffc2b5c9-f0e8-4f61-915c-72444b800218",
    name: "Deviation Quota",
    code: "deviationQuota",
    description: "Deviation Quota",
    level: 1,
    form: null,
    list: {
      heading: "Deviation Quota",
      code: "deviationQuota",
      itemPayloadKey: "deviationQuota",
      itemDataType: "array",
      columns: ["priority", "name", "code", "type", "period", "deviationLevel"],
      conditionBuilder: false,
      level: 2,
      form: getFieldsByModule({
        moduleCode: "deviationQuota"
      }),
      list: null
    },
    header: "Loan Origination System"
  },
  {
    _id: "7c72bab2-9d5f-48ad-8640-157d9a8358ba",
    name: "Payment Hierarchy",
    code: "paymentHierarchy",
    description: "Payment Hierarchy",
    form: null,
    list: null,
    tabs: [
      {
        name: "Payment Hierarchy",
        code: "paymentHierarchy",
        itemPayloadKey: "paymentHierarchy",
        itemDataType: "object",
        columns: [],
        level: 1,
        form: getFieldsByModule({
          moduleCode: "paymentHierarchy",
          excludeStaticFields: true
        }),
        list: {
          heading: "Overrides",
          code: "overrides",
          itemPayloadKey: "overrides",
          itemDataType: "array",
          columns: [],
          level: 2,
          form: getFieldsByModule({
            moduleCode: "paymentHierarchy_overrides"
          })
        }
      }
    ],
    header: "Loan Management System"
  },
  {
    _id: "a192e276-0fe8-4e67-a5ec-e2c258a50422",
    name: "Processing Fees",
    code: "processingFees",
    description: "Processing Fees",
    form: null,
    list: null,
    tabs: [
      {
        name: "Processing Fees",
        code: "processingFees",
        itemPayloadKey: "processingFees",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "processingFees",
          excludeStaticFields: true
        }),
        list: {
          heading: "Overrides",
          code: "overrides",
          itemPayloadKey: "overrides",
          itemDataType: "array",
          level: 2,
          form: getFieldsByModule({
            moduleCode: "processing_overrides"
          })
        }
      }
    ],
    header: "Loan Origination System"
  },
  {
    _id: "3b40bed7-fd24-4b8a-8791-95a09b52bd9e",
    name: "Insurance",
    code: "insurance",
    description: "Insurance",
    form: null,
    list: null,
    tabs: [
      {
        heading: "Insurance",
        code: "insurance",
        itemPayloadKey: "insurance",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "insurance",
          excludeStaticFields: true
        }),
        list: {
          heading: "Overrides",
          code: "overrides",
          itemPayloadKey: "overrides",
          itemDataType: "array",
          level: 2,
          form: getFieldsByModule({
            moduleCode: "insurance_overrides"
          })
        }
      }
    ],
    header: "Loan Origination System"
  },
  {
    _id: "6413d2a3-6f67-46b8-b70c-e0159776d6be",
    name: "Widget",
    code: "widget",
    description: "Widget",
    form: null,
    list: null,
    tabs: [
      {
        heading: "Widget",
        code: "widget",
        itemPayloadKey: "widget",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "widget",
          excludeStaticFields: true
        }),
        list: null
      }
    ]
  },
  {
    _id: "d2e7fb09-1645-49bf-ac7e-88c8575750ba",
    name: "Permissions",
    code: "permissions",
    description: "Permissions",
    form: null,
    list: null,
    tabs: [
      {
        heading: "Permissions",
        code: "permissions",
        itemPayloadKey: "permissions",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "permissions",
          excludeStaticFields: true
        }),
        list: null
      }
    ]
  },
  {
    _id: "af733f20-8b6c-49f6-abea-9603853c0f87",
    name: "Turn Around Time",
    code: "turnAroundTime",
    description: "Turn Around Times",
    level: 1,
    list: {
      heading: "Turn Around Time",
      code: "turnAroundTime",
      itemPayloadKey: "turnAroundTime",
      itemDataType: "array",
      // columns: ["priority", "name", "code", "turnAroundTime", "fields", "checklist", "approvalLevels"],
      // columns: ["priority", "name", "code"],
      level: 2,
      form: getFieldsByModule({
        moduleCode: "turnAroundTime"
      }),
      list: {
        heading: "Escalation Levels",
        code: "escalationLevels",
        itemPayloadKey: "escalationLevels",
        itemDataType: "array",
        // columns: ["priority", "name", "code"],
        level: 3,
        form: getFieldsByModule({
          moduleCode: "escalationLevels"
        })
      }
    }
  },
  {
    _id: "94a3085d-8132-40cc-82d3-da2dd85c14c1",
    name: "OTP configuration",
    code: "otp",
    description: "OTP configuration",
    form: null,
    list: null,
    tabs: [
      {
        name: "OTP",
        code: "otp",
        itemPayloadKey: "otp",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({ moduleCode: "otp" }),
        list: null
      }
    ]
  },
  {
    _id: "a0e2fc3b-07ee-451c-9577-9d37fe2908ed",
    name: "Advice",
    code: "advices",
    description: "advices",
    level: 1,
    form: null,
    list: {
      heading: "Advices",
      code: "advices",
      itemPayloadKey: "advices",
      itemDataType: "array",
      columns: ["priority", "name", "code", "stages", "maxApprovalStage"],
      level: 2,
      form: getFieldsByModule({
        moduleCode: "advices",
        excludeStaticFields: true
      }),
      list: null
    },
    header: "Loan Origination System"
  },
  {
    _id: "2823f84a-031b-4c96-a153-1ee38d38a405",
    name: "Installment Plan",
    code: "installmentPlan",
    description: "Installment Plan",
    form: null,
    list: null,
    tabs: [
      {
        name: "Installment Plan",
        code: "installmentPlan",
        itemPayloadKey: "installmentPlan",
        itemDataType: "object",
        columns: [],
        level: 1,
        form: getFieldsByModule({
          moduleCode: "installmentPlan"
        }),
        list: {
          heading: "First Installment Behaviour",
          code: "firstInstallmentBehaviours",
          itemPayloadKey: "firstInstallmentBehaviours",
          itemDataType: "array",
          columns: [],
          level: 2,
          form: getFieldsByModule({
            moduleCode: "firstInstallmentBehaviours"
          })
        }
      }
    ],
    header: "Loan Origination System"
  },
  {
    _id: "a2229e89-3a17-4627-910f-2dcc2a75063f",
    name: "Geofencing",
    code: "geofencing",
    description: "Geofencing",
    level: 1,
    list: {
      heading: "Geofencing",
      code: "geofencing",
      itemPayloadKey: "geofencing",
      itemDataType: "array",
      columns: [],
      level: 2,
      form: getFieldsByModule({ moduleCode: "geofencing" })
    }
  },
  {
    _id: "a0e2fc3b-07ee-451c-9577-9d37fe2907ed",
    name: "Purging",
    code: "purging",
    description: "purging",
    level: 1,
    form: null,
    list: {
      heading: "Purging",
      code: "purging",
      itemPayloadKey: "purging",
      itemDataType: "array",
      columns: ["priority", "name", "code", "stageCode", "tat", "treatment"],
      level: 2,
      form: getFieldsByModule({
        moduleCode: "purging",
        excludeStaticFields: true
      }),
      list: null
    }
  },
  {
    _id: "04667f98-db26-45d2-821e-5e4c2f2eeb42",
    name: "Sequence Generator Configuration",
    code: "sequenceGeneratorConfig",
    description: "Sequence Generator Configuration",
    form: null,
    list: null,
    tabs: [
      {
        heading: "Sequence Generator Configuration",
        code: "sequenceGeneratorConfig",
        itemPayloadKey: "sequenceGeneratorConfig",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "sequenceGeneratorConfig",
          excludeStaticFields: true
        }),
        list: null
      }
    ]
  },
  {
    _id: "b1f3f8b3-da7d-4b6c-9978-0010e240988c",
    name: "Ledger Mappings",
    code: "ledgerMappings",
    description: "Ledger Mappings",
    level: 1,
    form: null,
    list: {
      heading: "Ledger Mappings",
      code: "ledgerMappings",
      itemPayloadKey: "ledgerMappings",
      itemDataType: "array",
      level: 2,
      form: getFieldsByModule({
        moduleCode: "ledgerMappings"
      }),
      list: null
    },
    header: "Loan Management System"
  },
  {
    _id: "abf898db-cf3b-438c-b72e-8da9a803b0c5",
    name: "Backend Conditions",
    code: "backendConditions",
    description: "Backend Conditions",
    level: 1,
    form: null,
    list: {
      heading: "Backend Conditions",
      code: "backendConditions",
      itemPayloadKey: "backendConditions",
      itemDataType: "array",
      level: 2,
      form: getFieldsByModule({ moduleCode: "backendConditions" }),
      list: null
    }
  },
  {
    _id: "3b2943b1-44b5-4baa-b7eb-7af7a04b9e0b",
    name: "Incentive Levels",
    code: "incentiveLevels",
    description: "Incentive Levels",
    level: 1,
    form: null,
    list: {
      heading: "Incentive Levels",
      code: "incentiveLevels",
      itemPayloadKey: "incentiveLevels",
      itemDataType: "array",
      level: 2,
      form: getFieldsByModule({ moduleCode: "incentiveLevels" }),
      list: null
    },
    header: "Incentive Management System"
  },
  {
    _id: "8c2c27e5-f589-456f-ad33-b61cfe798567",
    name: "Variants",
    code: "variants",
    description: "Variants",
    level: 1,
    form: null,
    list: {
      heading: "Variants",
      code: "variants",
      itemPayloadKey: "variants",
      itemDataType: "array",
      level: 2,
      form: getFieldsByModule({ moduleCode: "variants" }),
      list: null
    },
    header: "Incentive Management System"
  },
  {
    _id: "8c2c27e5-f589-456f-ad33-b61cfe798567",
    name: "Slabs",
    code: "slabs",
    description: "Slabs",
    level: 1,
    form: null,
    list: {
      heading: "Slabs",
      code: "slabs",
      itemPayloadKey: "slabs",
      itemDataType: "array",
      level: 2,
      form: getFieldsByModule({ moduleCode: "slabs" }),
      list: null
    },
    header: "Incentive Management System"
  },
  {
    _id: "83287dcf-d281-4e13-8042-800ea44dd0c5",
    name: "Rejection Cooling Configuration",
    code: "rejectionCoolingConfiguration",
    description: "rejectionCoolingConfiguration",
    form: null,
    list: null,
    tabs: [
      {
        heading: "Rejection Cooling Configuration",
        code: "rejectionCoolingConfiguration",
        itemPayloadKey: "Rejection Cooling Configuration",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "rejectionCoolingConfiguration",
          excludeStaticFields: true
        }),
        list: null
      }
    ]
  },
  {
    _id: "83287dcf-d281-4e13-8042-800ea44dd0c5",
    name: "Customer Configuration",
    code: "customerConfiguration",
    description: "customerConfiguration",
    form: null,
    list: null,
    tabs: [
      {
        heading: "Customer Configuration",
        code: "customerConfiguration",
        itemPayloadKey: "Customer Configuration",
        itemDataType: "object",
        level: 1,
        form: getFieldsByModule({
          moduleCode: "customerConfiguration",
          excludeStaticFields: true
        }),
        list: null
      }
    ]
  }
];

export const initialProduct = {
  recoveryCompanies: [],
  quickViews: [],
  deviationQuota: [],
  externalSystemIdentifiers: [],
  dataSinkIntegration: "",
  cycleCuts: [],
  gracePeriod: "",
  template: {
    basicInformation: true,
    quickViews: false,
    deviationQuota: false,
    strategies: false,
    notifications: false,
    dunningLetters: false,
    queues: false
  },
  strategies: [],
  queues: [],
  dunningLetters: [],
  notifications: [],
  code: "",
  description: null,
  dedupeCriteria: [],
  comments: null,
  name: "",
  allowMultipleApplications: true,
  applicationCreationCriteria: [],
  gridColumns: [],
  debtBurdenRatio: {},
  debtServiceRatio: {},
  exposureSheet: {},
  statement: {},
  discrepancyRoleIds: [],
  documentRequirements: null,
  equalMonthlyInstallments: {},
  excelImport: true,
  installmentPlan: {},
  insurance: {},
  insuranceCompanyCodes: [],
  markup: {},
  dpd: {},
  parentId: "0",
  rules: [],
  processingFees: {},
  rejectionRoleIds: [],
  scenarios: [],
  scoringCard: {},
  scoringScheme: {},
  scoringLabel: {},
  scoringPostProcessing: [],
  searchFilters: [],
  spread: {},
  stages: [],
  conditions: [],
  checkList: [],
  deviations: [],
  peerBenchmark: {},
  counters: [],
  importMapping: [],
  preChecks: [],
  discrepancies: [],
  turnAroundTime: [],
  otp: {},
  advices: [],
  geofencing: [],
  ledgerMappings: [],
  backendConditions: [],
  incentiveLevels: [],
  variants: [],
  slabs: [],
  rejectionCoolingConfiguration: {},
  customerConfiguration: {}
};

export const queryBuilderQueryTypeEnums = {
  Mongo: 0,
  SQL: 1,
  HR: 2,
  Tree: 3,
  JSONLogic: 4
};

export const queryBuilderDefaultExpressions = ["SQL", "HR"];

export const staticCodes = {
  RRL_Field_ValueOfPassbook: "RRLValueofPassbook",
  RRL_Condition_ProductIndexUnit002: "ProductIndexUnit002",
  RRL_Field_CategoryOfLandHolding: "RRLFLD0050",
  RRL_Field_CNICNumber: "RRLFLD0029",
  RRL_Field_ApplicantName: "RRLFLD0867",
  RRL_Field_ApprovedLoanAmount: "RRLFLDAPPROVEDLOANAMOUNT",
  RRL_Field_ProposedAmount: "RRLFLD0139",
  RRL_Field_ApprovedLoanAmountTermFinance: "RRLFLDTERMAPPROVEDLOANAMOUNT",
  RRL_Field_ProposedAmountTermedFinance: "RRLFLDPROPOSEDAMOUNTTERMFINANCE",
  RRL_Renewal_Products: [
    "DairyRenewal",
    "FisheryRenewal",
    "PoultryRenewal",
    "RuralRenewal"
  ],
  RRL_Field_ProposedAmountRenewal: "RRLFLDProposedAmountRenewal",
  RRL_Field_ApprovedLoanAmountRenewal: "RRLFLDAPPROVEDLOANAMOUNTRENEWAL",

  RRL_Grid_ExistingCultivatedAreaInAcresDetails:
    "ExistingCultivatedAreainAcresDetails",
  RRL_Field_ExistingCultivatedAreaType: "RRLFLD0051",
  RRL_Field_ExistingCultivatedAreaInAcres: "RRLFLD0052",
  RRL_Field_ExistingTotalLeasedCultivatedArea:
    "RRLExistingTotalLeasedCultivatedArea",
  RRL_Field_ExistingTotalOwnedCultivatedArea:
    "RRLExistingTotalOwnedCultivatedArea",
  RRL_Field_ExistingTotalOwnedCollateralCultivatedArea:
    "RRLExistingTotalOwnedCollateralCultivatedArea",
  RRL_Field_ExistingTotalFamilyCultivatedArea:
    "RRLExistingTotalFamilyCultivatedArea",

  RRL_Grid_ProposedCultivatedAreaInAcresDetails:
    "ProposedCultivatedAreainAcresDetails",
  RRL_Field_ProposedCultivatedAreaType: "RRLFLD0054",
  RRL_Field_ProposedCultivatedAreaInAcres: "RRLFLD0055",
  RRL_Field_ProposedTotalLeasedCultivatedArea:
    "RRLProposedTotalLeasedCultivatedArea",
  RRL_Field_ProposedTotalOwnedCultivatedArea:
    "RRLProposedTotalOwnedCultivatedArea",
  RRL_Field_ProposedTotalOwnedCollateralCultivatedArea:
    "RRLProposedTotalOwnedCollateralCultivatedArea",
  RRL_Field_ProposedTotalFamilyCultivatedArea:
    "RRLProposedTotalFamilyCultivatedArea",

  RRL_Grid_UrbanProperty: "RRLFLD0334",
  RRL_Field_UrbanPropertyType: "RRLFLD0335",

  RRL_Field_UrbanPropertyApproximateAreaSquareFeet: "RRLFLD0336",
  RRL_Field_UrbanPropertyPortionApproximateAreaSquareFeet:
    "RRLTypeofPropertyPortion",
  RRL_Field_UrbanPropertyOpenPlotApproximateAreaSquareFeet:
    "RRLTypeofPropertyOpenPlot",
  RRL_Field_UrbanPropertyApartmentApproximateAreaSquareFeet:
    "RRLTypeofPropertyApartment",
  RRL_Field_UrbanPropertyHouseApproximateAreaSquareFeet:
    "RRTypeofPropertyHouse",
  RRL_Field_UrbanPropertyOtherApproximateAreaSquareFeet:
    "RRLTypeofPropertyOther",

  RRL_Field_UrbanPropertyApproximateValuePKR: "RRLFLD0337",
  RRL_Field_UrbanPropertyPortionApproximateValuePKR:
    "RRLTypeofPropertyPortionValue",
  RRL_Field_UrbanPropertyOpenPlotApproximateValuePKR:
    "RRLTypeofPropertyOpenPlotValue",
  RRL_Field_UrbanPropertyApartmentApproximateValuePKR:
    "RRLTypeofPropertyApartmentValue",
  RRL_Field_UrbanPropertyHouseApproximateValuePKR:
    "RRLTypeofPropertyHousevalue",
  RRL_Field_UrbanPropertyOtherApproximateValuePKR:
    "RRLTypeofPropertyOtherValue",

  RRL_Grid_MoveableAssets: "RRLFLD0338",
  RRL_Field_MoveableAssetsType: "RRLFLD0339",

  RRL_Field_MoveableAssetsQuantity: "RRLFLD0340",
  RRL_Field_MoveableAssetsJeepQuantity: "RRLTotalTypeofAssetJeep",
  RRL_Field_MoveableAssetsPickupQuantity: "RRLTotalTypeofAssetPickup",
  RRL_Field_MoveableAssetsMotorcycleQuantity: "RRLTotalTypeofAssetMotorcycle",
  RRL_Field_MoveableAssetsTrucksQuantity: "RRLTotalTypeofAssetTrucks",
  RRL_Field_MoveableAssetsCarQuantity: "RRLTotalTypeofAssetCar",

  RRL_Field_MoveableAssetsMake: "RRLFLD0341",
  RRL_Field_MoveableAssetsJeepMake: "RRLTotalTypeofAssetJeepMake",
  RRL_Field_MoveableAssetsPickupMake: "RRLTotalTypeofAssetPickupMake",
  RRL_Field_MoveableAssetsMotorcycleMake: "RRLTotalTypeofAssetMotorcycleMake",
  RRL_Field_MoveableAssetsTrucksMake: "RRLTotalTypeofAssetTrucksMake",
  RRL_Field_MoveableAssetsCarMake: "RRLTotalTypeofAssetCarMake",

  RRL_Field_MoveableAssetsModel: "RRLFLD0342",
  RRL_Field_MoveableAssetsJeepModel: "RRLTotalTypeofAssetJeepModel",
  RRL_Field_MoveableAssetsPickupModel: "RRLTotalTypeofAssetPickupModel",
  RRL_Field_MoveableAssetsMotorcycleModel: "RRLTotalTypeofAssetMotorcycleModel",
  RRL_Field_MoveableAssetsTrucksModel: "RRLTotalTypeofAssetTrucksModel",
  RRL_Field_MoveableAssetsCarModel: "RRLTotalTypeofAssetCarModel",

  RRL_Field_MoveableAssetsApproximateValuePKR: "RRLFLD0343",
  RRL_Field_MoveableAssetsJeepApproximateValuePKR:
    "RRLTotalTypeofAssetJeepValue",
  RRL_Field_MoveableAssetsPickupApproximateValuePKR:
    "RRLTotalTypeofAssetPickupValue",
  RRL_Field_MoveableAssetsMotorcycleApproximateValuePKR:
    "RRLTotalTypeofAssetMotorcycleValue",
  RRL_Field_MoveableAssetsTrucksApproximateValuePKR:
    "RRLTotalTypeofAssetTrucksValue",
  RRL_Field_MoveableAssetsCarApproximateValuePKR: "RRLTotalTypeofAssetCarValue",

  RRL_Grid_FarmMachinery: "RRLFLD0345",
  RRL_Field_FarmMachineryAssetsType: "RRLFLD0346",

  RRL_Field_FarmMachineryAssetsQuantity: "RRLFLD0347",
  RRL_Field_FarmMachineryAssetsTrolleyQuantity: "RRLTotalTypeofAssetTrolley",
  RRL_Field_FarmMachineryAssetsTractorQuantity: "RRLTotalTypeofAssetTractor",
  RRL_Field_FarmMachineryAssetsTubewellQuantity: "RRLTotalTypeofAssetTubewell",
  RRL_Field_FarmMachineryAssetsCombineHarvesterQuantity:
    "RRLTotalTypeofAssetCombineHarvester",
  RRL_Field_FarmMachineryAssetsThresherQuantity: "RRLTotalTypeofAssetThresher",

  RRL_Field_FarmMachineryAssetsMake: "RRLFLD0348",
  RRL_Field_FarmMachineryAssetsTrolleyMake: "RRLTotalTypeofAssetTrolleyMake",
  RRL_Field_FarmMachineryAssetsTractorMake: "RRLTotalTypeofAssetTractorMake",
  RRL_Field_FarmMachineryAssetsTubewellMake: "RRLTotalTypeofAssetTubewellMake",
  RRL_Field_FarmMachineryAssetsCombineHarvesterMake:
    "RRLTotalTypeofAssetCombineHarvesterMake",
  RRL_Field_FarmMachineryAssetsThresherMake: "RRLTotalTypeofAssetThresherMake",

  RRL_Field_FarmMachineryAssetsModel: "RRLFLD0349",
  RRL_Field_FarmMachineryAssetsTrolleyModel: "RRLTotalTypeofAssetTrolleyModel",
  RRL_Field_FarmMachineryAssetsTractorModel: "RRLTotalTypeofAssetTractorModel",
  RRL_Field_FarmMachineryAssetsTubewellModel:
    "RRLTotalTypeofAssetTubewellModel",
  RRL_Field_FarmMachineryAssetsCombineHarvesterModel:
    "RRLTotalTypeofAssetCombineHarvesterModel",
  RRL_Field_FarmMachineryAssetsThresherModel:
    "RRLTotalTypeofAssetThresherModel",

  RRL_Field_FarmMachineryAssetsApproximateValuePKR: "RRLFLD0350",
  RRL_Field_FarmMachineryAssetsTrolleyApproximateValuePKR:
    "RRLTotalTypeofAssetTrolleyValue",
  RRL_Field_FarmMachineryAssetsTractorApproximateValuePKR:
    "RRLTotalTypeofAssetTractorValue",
  RRL_Field_FarmMachineryAssetsTubewellApproximateValuePKR:
    "RRLTotalTypeofAssetTubewellValue",
  RRL_Field_FarmMachineryAssetsCombineHarvesterApproximateValuePKR:
    "RRLTotalTypeofAssetCombineHarvesterValue",
  RRL_Field_FarmMachineryAssetsThresherApproximateValuePKR:
    "RRLTotalTypeofAssetThresherValue",

  RRL_Field_TypeOfBusiness: "RRLFLDTypeofBusiness",
  RRL_Field_CategoryOfFarm: "RRLFLDCategoryofFARM",

  ICF_Field_TenureType: "ICFTenureType",
  ICF_Field_ProposedMaturity: "ICF0018",

  SAAF_Field_CNICNumber: "SAAFCNIC",
  SAAF_Field_DetailOfAuthorizedSignatory_CNICNumber: "SAAFgrid007",
  SAAF_Grid_DetailOfAuthorizedSignatory: "SAAFgridheader001",
  SAAF_Field_DetailOfAuthorizedSignatory_ResidenceAddress: "SAAFgrid003",
  SAAF_Field_DetailOfAuthorizedSignatory_DateOfBirth: "SAAFgrid008",
  SAAF_Field_MergedResidenceAddress: "SAAFMergedResidenceAddress",
  SAAF_Field_MergedDateOfBirth: "SAAFMERGEDDOB",

  Product_Rural: "RRL001",
  Product_Rural_Renewal: "RuralRenewal",
  Product_CMS: "MCBCC",
  Product_SAAF: "SAAF",
  Product_Poultry: "RRL003",
  Product_Poultry_Renewal: "PoultryRenewal",
  Product_Dairy: "RRLDAIRY",
  Product_Dairy_Renewal: "DairyRenewal",
  Product_Fishery: "RRLFISHERY",
  Product_Fishery_Renewal: "FisheryRenewal",
  Product_BPC_Digital_EKYC: "BPCDIGITALEKYC",

  RRL_Stage_Initiation: "Initiation",
  RRL_Stage_CreditAnalyst: "CreditAnalyst",

  BPC_Digital_EKYC_Type_of_Account: "eKYCTypeOfAcc01",
  BPC_Digital_EKYC_Customer_Relationship: "eKYC003"
};

export const extensionTypes = [
  {
    mimeType: "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    extension: "xlsx"
  },
  { mimeType: "vnd.ms-excel", extension: "xls" },
  { mimeType: "msword", extension: "doc" },
  {
    mimeType: "vnd.openxmlformats-officedocument.wordprocessingml.document",
    extension: "docx"
  },
  {
    mimeType: "vnd.openxmlformats-officedocument.presentationml.presentation",
    extension: "pptx"
  },
  { mimeType: "vnd.ms-powerpoint", extension: "ppt" },
  { mimeType: "plain", extension: "txt" },
  { mimeType: "vnd.ms-outlook", extension: "msg" },
  { mimeType: "rfc822", extension: "eml" }
];

export const SetFlowsColumnsDictionaries = {
  ProductType: {
    0: "DFG",
    1: "Digital Credit"
  }
};

export const fileTypeAccept =
  ".xlsx, .xls, .xlsm, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf, .csv, .zip, .msg, .eml";
