import {createSlice} from "@reduxjs/toolkit";

const initialDynamicSearchState = {
    listLoading: false,
    logsLoading: false,
    actionsLoading: false,
    totalCount: 0,
    totalLogs: 0,
    totalActivities: 0,
    entities: null,
    entityForEdit: undefined,
    lastError: null,
    makerCheckerAction: false,
    allDynamicSearch: undefined,
    allActivities: undefined,
    allLogs: undefined
};

export const callTypes = {
    list: "list",
    action: "action",
    log: "logs",
};

export const slice = createSlice({
    name: "dynamicSearch",
    initialState: initialDynamicSearchState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.log) {
                state.logsLoading = false;
            } else if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === "logs") {
                state.logsLoading = true;
            } else if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        dynamicSearchFetched: (state, action) => {
            const {totalCount, entities} = action.payload;
            state.listLoading = false;
            state.error = null
            state.makerCheckerAction = false;
            state.allDynamicSearch = entities;
            state.totalCount = totalCount;
            state.entityForEdit = undefined;
        },
        activitiesFetched: (state, action) => {
            const {totalActivities, entities} = action.payload;
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null
            state.makerCheckerAction = false;
            state.allActivities = entities;
            state.totalActivities = totalActivities;
            state.entityForEdit = undefined;
        },
        logsFetched: (state, action) => {
            const {totalLogs, entities} = action.payload;
            state.logsLoading = false;
            state.listLoading = false;
            state.error = null
            state.makerCheckerAction = false;
            state.allLogs = entities;
            state.totalLogs = totalLogs;
            state.entityForEdit = undefined;
        },
    }
})