import { createSlice } from "@reduxjs/toolkit";

const initialFacialSpoofState = {
  listLoading: false,
  logsLoading: false,
  actionsLoading: false,
  totalCount: 0,
  totalLogs: 0,
  totalActivities: 0,
  entities: null,
  entityForEdit: undefined,
  lastError: null,
  makerCheckerAction: false,
  allLogs: undefined
};

export const callTypes = {
  list: "list",
  action: "action",
  log: "logs"
};

export const slice = createSlice({
  name: "facialSpoofLogs",
  initialState: initialFacialSpoofState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.log) {
        state.logsLoading = false;
      } else if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === "logs") {
        state.logsLoading = true;
      } else if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    logsFetched: (state, action) => {
      const { totalLogs, entities } = action.payload;
      state.logsLoading = false;
      state.listLoading = false;
      state.error = null;
      state.makerCheckerAction = false;
      state.allLogs = entities;
      state.totalLogs = totalLogs;
      state.entityForEdit = undefined;
    }
  }
});
