import { toast } from "react-toastify";

export const errorNotification = (content, width = null) => {
  toast.error(content, {
    style: {
      width: width
    }
  });
};
export const successNotification = content => toast.success(content);
export const warningNotification = content => toast.warn(content);
export const infoNotification = content => toast.info(content);
