import {
  alphaNumericRegex,
  emailRegex,
  inputTypesDictionary
} from "./staticFields";

import { inputTypes, hideFieldsInEntity } from "./commonStaticVariables";

export const allFieldsList = [
  {
    name: "Name",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "name",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Code",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    minLength: 1,
    maxLength: 100,
    regex: alphaNumericRegex,
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Description",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "description",
    status: 0,
    minLength: 2,
    maxLength: 1000,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Order",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "order",
    status: 0,
    minLength: 0,
    // maxLength: "",
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },
  {
    name: "Is Hidden",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isHidden",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Fields",
    dataType: inputTypes.findIndex(field => field.type === "dualListBox"),
    type: "string",
    description: hideFieldsInEntity.map(
      (value, index) =>
        (index ? `, ` : "") +
        inputTypesDictionary?.[value]?.displayType +
        (index === hideFieldsInEntity?.length - 1
          ? " is currently unavailable."
          : "")
    ),
    helperText: "",
    code: "fields",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Data Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: hideFieldsInEntity.map(
      (value, index) =>
        (index ? `, ` : "") +
        inputTypesDictionary?.[value]?.displayType +
        (index === hideFieldsInEntity?.length - 1
          ? " is currently unavailable."
          : "")
    ),
    helperText: "",
    code: "dataType",
    status: 0,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "dataTypes"
  },
  {
    name: "Master Data Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "masterDataType",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "masterDataTypes"
  },
  {
    name: "Dependent Fields",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "dependentField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "dependentFields",
    valueAsString: true
  },
  {
    name: "Sub Fields",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "subFields",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "dependentFields"
  },
  {
    name: "Minimum Length",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "minLength",
    status: 0,
    minLength: 0,
    // maxLength: 10,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Maximum Length",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "maxLength",
    status: 0,
    minLength: 0,
    // maxLength: 10,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Regex",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "regex",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "regex_reccords"
  },
  {
    name: "Helper Text",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "helperText",
    status: 0,
    minLength: 1,
    maxLength: 50,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Group",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "group",
    status: 0,
    minLength: 1,
    maxLength: 200,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "StageGroups"
  },
  {
    name: "Group",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "group",
    status: 0,
    minLength: 1,
    maxLength: 200,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "StageGroups"
  },
  {
    name: "Sub Group",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "subGroup",
    status: 0,
    minLength: 1,
    maxLength: 200,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Header",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "header",
    status: 0,
    minLength: 1,
    maxLength: 200,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "StageHeaders"
  },
  {
    name: "Belonging",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "belonging",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "belongings"
  },
  {
    name: "Status",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "status",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "statuses"
  },
  {
    name: "Fuzzy Threshold",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "fuzzyThreshold",
    status: 0,
    minLength: 1,
    maxLength: 100,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Fuzzy Search",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "supportFuzzySearch",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Mandatory",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isRequired",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Is Import Allowed",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isImportAllowed",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Hidden",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isHidden",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Unique",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isUnique",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Email",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "emailAddress",
    status: 0,
    minLength: 3,
    maxLength: 100,
    regex: emailRegex,
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Gender",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "gender",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "gender"
  },
  {
    name: "Phone Number",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "phoneNumber",
    status: 0,
    minLength: "",
    maxLength: "",
    regex: "",
    tags: ["ContactNumber"],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Parent Module",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "parentCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "modules"
  },
  {
    name: "Route",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "route",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Module Status",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "moduleStatus",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "statuses"
  },

  /*       *** PRODUCT FACTORY EXCLUSIVE FIELDS ***              */

  {
    name: "Recovery Companies",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "recoveryCompanies",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "recovery_companies"
  },

  {
    name: "External System Identifiers",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    description: "",
    helperText: "",
    code: "externalSystemIdentifiers",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    header: "Loan Origination System"
  },

  {
    name: "Cycle Cuts",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    description: "",
    helperText: "",
    code: "cycleCuts",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    header: "Collection Management System"
  },

  {
    name: "Cycle Cuts",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "cycleCuts",
    masterDataType: "cycleCuts"
  },

  {
    name: "Grace Period",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "gracePeriod",
    status: 0,
    minLength: 1,
    maxLength: 20,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Widgets",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "widgets",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "widgets"
  },

  {
    name: "Quick View",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "quickview"
  },

  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "type",
    status: 0,
    minLength: 2,
    maxLength: 50,
    regex: "",
    tags: [],
    // isRequired: false,
    isRequired: false,
    disabled: true,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Field Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "fieldCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Layout",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "layout",
    status: 0,
    regex: "",
    tags: [],
    // isRequired: false,
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "layouts"
  },
  {
    name: "Layout",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "layout",
    status: 0,
    regex: "",
    tags: [],
    // isRequired: false,
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "fieldLayout"
  },

  {
    name: "Actions",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "select",
    description: "",
    helperText: "",
    code: "actions",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "actions"
  },
  {
    name: "PTP Restriction",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "ptpRestriction",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "ptp_restrictions"
  },
  {
    name: "Use Cycle Cuts",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "cycleCuts",
    status: 0,
    minLength: 1,
    maxLength: 20,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Is Escalate",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isEscalate",
    status: 0,
    minLength: 1,
    maxLength: 20,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Send Notifications",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "sendNotifications",
    status: 0,
    minLength: 1,
    maxLength: 20,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Strategies",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "strategies",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "strategiesFP",
    dataSource: "product"
  },
  {
    name: "Groups",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "groups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },
  {
    name: "Allocation",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "allocation",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "allocations"
  },

  // {
  //   name: "Report",
  //   dataType: inputTypes.findIndex((field) => field.type === "select"),
  //   type: "select",
  //   description: "",
  //   helperText: "",
  //   code: "report",
  //   status: 0,
  //   regex: "",
  //   tags: [],
  //   isRequired: false,
  //   disabled: false,
  //   hidden: false,
  //   fixed: true,
  //   masking: "",
  //   masterDataType: "reports",
  // },

  {
    name: "Notifications",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "notifications",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "notifications"
  },

  {
    name: "Fields",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "fields",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Stages",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "stages",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "stagesFP",
    dataSource: "product"
  },

  {
    name: "Stages",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "stageCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "stagesWithZeroStageFP",
    dataSource: "product"
  },

  {
    name: "Grid Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "gridCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "DPD",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "dpd",
    status: 0,
    minLength: 1,
    // maxLength: 10,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Status",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "status",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "statuses"
  },

  {
    name: "Stages",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "stages",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "stagesFP",
    dataSource: "product"
  },

  {
    name: "Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "fields",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Stages",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "stages",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "stagesFP",
    dataSource: "product"
  },

  {
    name: "Deferral List",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "deferralList",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Recommending Deferral List",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "deferralListOfRecommendingAuthorities",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Approving Deferral List",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "deferralListOfApprovingAuthorities",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Deviations",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "deviation"
  },

  {
    name: "Minimum",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "min",
    status: 0,
    // minLength: 1,
    // maxLength: 10,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Maximum",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "max",
    status: 0,
    // minLength: 1,
    // maxLength: 10,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "type",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "types"
  },

  {
    name: "From (Date)",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "fromDate",
    status: 0,
    fixed: true,
    isRequired: false
  },

  {
    name: "To (Date)",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "toDate",
    status: 0,
    fixed: true
  },

  {
    name: "Policy View Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "policyViewGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "Message",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "message",
    status: 0,
    minLength: 2,
    maxLength: 500,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Out of",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "outOf",
    status: 0,
    minLength: -100,
    // maxLength: 100,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  //
  // {
  //   name: "Deviation",
  //   dataType: inputTypes.findIndex((field) => field.type === "select"),
  //   type: "select",
  //   description: "",
  //   helperText: "",
  //   code: "scoreDeviation",
  //   status: 0,
  //   regex: "",
  //   tags: [],
  //   isRequired: false,
  //   disabled: false,
  //   hidden: false,
  //   fixed: true,
  //   masking: "",
  //   masterDataType: "deviations",
  // },

  {
    name: "Score View Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "scoreViewGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "Score",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "value",
    status: 0,
    minLength: -1000000,
    // maxLength: 100,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Color",
    dataType: inputTypes.findIndex(field => field.type === "colorPicker"),
    type: "colorPicker",
    description: "",
    helperText: "",
    code: "color",
    status: 0,
    // minLength: 0,
    // maxLength: 100,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Stages",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "scoreStages",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "stagesFP",
    dataSource: "product"
  },

  {
    name: "Name",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "schemeName",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "fieldCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Name",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "scoreName",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Code",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "scoreCode",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Group",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "group",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Stage Creation Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "stageCreationGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "Document Permission Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "documentPermissionGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "Reports",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "reports",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "reports",
    dataSource: "friday"
  },

  {
    name: "Notify via Email",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "notifyViaEmail",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Notify via SMS",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "notifyViaSms",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "QR Enable",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "qrEnable",
    fixed: true
  },

  {
    name: "Hold Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "holdGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "External Integrations",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "externalIntegrations",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "Integrations"
  },

  {
    name: "Notify Integrations",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "notifyIntegrations",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "Integrations"
  },

  {
    name: "Workflow Duration (Days)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "workflowDuration",
    status: 0,
    minLength: 0,
    maxLength: 100,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Send Notifications",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "sendNotifications",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Value (%)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    helperText: "value in percentage(%)",
    code: "value",
    status: 0,
    minLength: 1,
    maxLength: 100,
    isRequired: true,
    fixed: true
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    status: 0,
    isRequired: true,
    fixed: true,
    masterDataType: "markupTypes"
  },
  {
    name: "Kibor Type Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "kiborFieldCode",
    status: 0,
    fixed: true,
    masterDataType: "fields"
  },

  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    status: 0,
    isRequired: true,
    fixed: true,
    masterDataType: "processingFeesTypes"
  },
  {
    name: "Percentage (%)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "percentage",
    status: 0,
    minLength: 0,
    maxLength: 100,
    isRequired: true,
    fixed: true
  },
  {
    name: "Fixed Amount",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "amount",
    status: 2,
    tags: ["amount"],
    isRequired: true,
    fixed: true,
    maxLength: 1000000000,
    minLength: 0
  },
  {
    name: "Criteria",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "criteria",
    status: 2,
    fixed: true,
    masterDataType: "processingFeesCriteria"
  },

  {
    name: "Error Message",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "errorMessage",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Mandatory",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isMandatory",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Checklist Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "type",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "checkListTypes"
  },

  // {
  //   name: "Deviation Code",
  //   dataType: inputTypes.findIndex((field) => field.type === "select"),
  //   type: "select",
  //   description: "",
  //   helperText: "",
  //   code: "deviationCode",
  //   status: 0,
  //   regex: "",
  //   tags: [],
  //   isRequired: false,
  //   disabled: false,
  //   hidden: false,
  //   fixed: true,
  //   masking: "",
  //   masterDataType: "deviations",
  // },

  {
    name: "Condition",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "expression",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"]
  },

  {
    name: "Entrance Condition",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "entranceCondition",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"]
  },

  {
    name: "Entry Condition",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "entryCondition",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"]
  },

  {
    name: "Exit Condition",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "exitCondition",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"]
  },

  {
    name: "Approving Condition",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "approvingExpression",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"]
  },

  {
    name: "Recommending Condition",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "recommendingExpression",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"]
  },
  // {
  //   name: "Deviation",
  //   dataType: inputTypes.findIndex((field) => field.type === "select"),
  //   type: "select",
  //   description: "",
  //   helperText: "",
  //   code: "deviation",
  //   status: 0,
  //   regex: "",
  //   tags: [],
  //   isRequired: false,
  //   disabled: false,
  //   hidden: false,
  //   fixed: true,
  //   masking: "",
  //   masterDataType: "deviation",
  // },

  {
    name: "Readonly",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isReadOnly",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Group",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "group",
    status: 0,
    minLength: 2,
    maxLength: 50,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Authorized Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "groupCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "Assigned Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "groupCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "Approval Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "approvalGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "Cancel Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "declineGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity",
    header: "Loan Origination System"
  },

  {
    name: "Reject Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "rejectGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity",
    header: "Loan Origination System"
  },

  {
    name: "Recall Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "recallGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity",
    header: "Loan Origination System"
  },

  {
    name: "Stage Creation Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "stageCreationGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "Weightage (%)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "weightage",
    status: 0,
    // minLength: 1,
    // maxLength: 10,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Use Cycle Cuts",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "useCycleCuts",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Alias",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "Alias",
    helperText: "",
    code: "name",
    status: 0,
    minLength: 2,
    maxLength: 500,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },

  {
    name: "Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Stages",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "stageCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "stagesFP",
    dataSource: "product"
  },

  {
    name: "Stage",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "stagesFP",
    dataSource: "product"
  },

  {
    name: "Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "field",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Tags",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "tags",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "tags"
  },

  {
    name: "Creation Criteria",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "applicationCreationCriteria",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields",
    dataSource: "friday",
    header: "Loan Origination System"
  },

  {
    name: "Grid Columns",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "gridColumns",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields",
    dataSource: "friday",
    header: "Loan Origination System"
  },

  {
    name: "Outcome Conditions",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "expression",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"]
  },

  {
    name: "Collections Tags",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "tags",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "CMSTAGS",
    header: "Collection Management System"
  },

  {
    name: "Counters",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "counters",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "counters",
    header: "Collection Management System"
  },
  /*       *** PRODUCT FACTORY EXCLUSIVE FIELDS *** - END              */

  //Identity Fields
  {
    name: "Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "groupCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups"
  },

  {
    name: "Personnel Number",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "personnelNumber",
    status: 0,
    minLength: 2,
    maxLength: 20,
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    tag: ["AlphaNumeric"],
    regex: alphaNumericRegex
  },

  {
    name: "Branch Code",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    code: "branchCode",
    tag: ["AlphaNumeric"],
    minLength: 4,
    maxLength: 20,
    fixed: true,
    regex: alphaNumericRegex
  },

  // Reports Factory Fields

  {
    name: "Folder Name",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "folderName",
    status: 0,
    minLength: 2,
    maxLength: 1000,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "URL",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "url",
    status: 0,
    minLength: 2,
    maxLength: 1000,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    tags: ["Link"]
  },

  {
    name: "Product Codes",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    description: "",
    helperText: "",
    code: "productCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "products"
  },

  {
    name: "Export Types",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "exportTypes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "exportTypes"
  },

  {
    name: "Filters",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "filters",
    masterDataType: "filtersForReports"
  },

  {
    name: "Product",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "ProductCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "products"
  },

  // Workflows
  {
    name: "Unique Identifier",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "uniqueIdentifier",
    status: 0,
    minLength: 10,
    maxLength: 20,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Customer Name",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "customerName",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Customer Name",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "RRLFLD0867",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Region",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "region",
    status: 0,
    minLength: 2,
    maxLength: 20,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Mobile Number",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "text",
    description: "",
    helperText: "",
    code: "RRLFLD0165",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Field For Computation",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "computationField",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Computation Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "computationType",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "computationOptions"
  },
  {
    name: "Field For Computations",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "computationFieldsForForm",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Value",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "value",
    status: 0,
    minLength: 1,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },
  {
    name: "Mathematical Expression",
    dataType: inputTypes.findIndex(
      field => field.type === "mathematicalExpression"
    ),
    type: "mathematicalExpression",
    code: "mathematicalExpression"
  },
  {
    name: "Hierarchy Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "hierarchyType",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "entities",
    dataSource: "friday"
  },
  {
    name: "Identification Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "identificationCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields",
    dataSource: "friday"
  },
  {
    name: "Association With",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "associationWith",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "ProductAssociation"
  },
  {
    name: "Repayment Based On",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "repaymentBasedOn",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "repaymentCriteriaTypes",
    header: "Loan Management System"
  },

  {
    name: "Hierarchy Type",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "text",
    description: "",
    helperText: "",
    code: "RenewalWorkflowIdentifier",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Hierarchy Alias",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "text",
    description: "",
    helperText: "",
    code: "hierarchyAlias",
    status: 0,
    minLength: 0,
    maxLength: 30,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Is Weightage Based",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isWeightageBased",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Score Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "scoreField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Label Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "labelField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "ORR Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "orrField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "FRR Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "frrField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "ORR",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "orr",
    status: 0,
    minLength: 0,
    // maxLength: 100,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "FRR",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "frr",
    status: 0,
    minLength: 0,
    // maxLength: 100,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "From",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "fromValue",
    status: 0,
    // minLength: 1,
    // maxLength: 10,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "To",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "toValue",
    status: 0,
    // minLength: 1,
    // maxLength: 10,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "Outcome Type",
    helperText: "",
    code: "type",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "outcomeTypes"
  },
  {
    name: "Comments",
    dataType: inputTypes.findIndex(field => field.type === "richTextBox"),
    type: "string",
    description: "",
    helperText: "",
    code: "comments",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Default Password",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "defaultPassword",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    tags: ["PasswordWithPolicy"],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Remarks",
    dataType: inputTypes.findIndex(field => field.type === "richTextBox"),
    type: "string",
    description: "",
    helperText: "",
    code: "remarks",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "View Only Field Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "readOnlyFieldCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Deviation Level",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "levelNumber",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Approving Deviation Level",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "approvingLevelNumber",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    valueAsInt: true
  },

  {
    name: "Recommending Deviation Levels",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    description: "",
    helperText: "",
    code: "recommendingLevelNumbers",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    valueAsInt: true
  },

  {
    name: "Deviation Count",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "levelCount",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Approving Deviation Count",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "approvingLevelCount",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  // {
  //   name: "Recommending Deviation Count",
  //   dataType: inputTypes.findIndex((field) => field.type === "number"),
  //   type: "number",
  //   description: "",
  //   helperText: "",
  //   code: "highestRecommendingLevelExceptionCount",
  //   status: 0,
  //   regex: "",
  //   tags: [],
  //   isRequired: false,
  //   disabled: false,
  //   hidden: false,
  //   fixed: true,
  //   masking: "",
  // },
  {
    name: "DataSink Integration",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "dataSinkIntegration",
    status: 0,
    minLength: 2,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "Integrations",
    header: "Loan Origination System"
  },
  {
    name: "Default Value Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "defaultValueType",
    status: 0,
    minLength: 1,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "defaultValueType"
  },
  {
    name: "Default Value",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "defaultValue",
    status: 0,
    maxLength: 9999999999999999,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },
  // {
  //   name: "Default Value Field",
  //   dataType: inputTypes.findIndex((field) => field.type === "select"),
  //   type: "select",
  //   description: "",
  //   helperText: "",
  //   code: "defaultValue",
  //   status: 0,
  //   minLength: 1,
  //   maxLength: 100,
  //   regex: "",
  //   isRequired: false,
  //   disabled: false,
  //   hidden: false,
  //   fixed: false,
  //   masking: "",
  //   masterDataType: "fields"
  // },
  {
    name: "Criteria",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "Criteria",
    helperText: "",
    code: "criteria",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "authenticationCriterion"
  },
  {
    name: "Approving Criteria",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "Approving Criteria",
    helperText: "",
    code: "approvingCriteria",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "authenticationCriterion"
  },
  {
    name: "Recommending Criteria",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "Recommeding Criteria",
    helperText: "",
    code: "recommendingCriteria",
    status: 0,
    minLength: null,
    maxLength: null,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "authenticationCriterion"
  },

  {
    name: "Bypass",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "bypass",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Recommend Only",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "recommendOnly",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Operation Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "operation",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "scorePostProcessingOperationType"
  },
  {
    name: "Processing Value",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "processingValue",
    status: 0,
    minLength: 0,
    maxLength: 500,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Primary Criteria Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "primaryCriteriaField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Secondary Criteria Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "secondaryCriteriaField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Value Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "valueField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Percentile Value Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "percentileValueField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Percentile Rank Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "percentileRankField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Is Renewal",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isRenewal",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    header: "Loan Origination System"
  },
  {
    name: "Randomize Workflow Identifier",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "randomizeWorkflowIdentifier",
    header: "Loan Origination System"
  },
  {
    name: "Renewal Products",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "renewalProductCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "products",
    dataSource: "friday",
    header: "Loan Origination System"
  },
  {
    name: "Operation Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "operation",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "bulkOperations"
  },
  {
    name: "Filter By",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "filters",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "bulkOperationFilters"
  },
  {
    name: "Attributes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "attributes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: true,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "attributeTypes"
  },
  {
    name: "Filteration Method",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "filterMethod",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "filterMethods"
  },
  {
    name: "Copy To",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "copyTo",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: true,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "copyMoveOptions"
  },
  {
    name: "Move To",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "moveTo",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: true,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "copyMoveOptions"
  },

  {
    name: "Cell",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "cell",
    status: 0,
    minLength: 2,
    maxLength: 4,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },
  {
    name: "File Group",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "groupCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "DocumentGroups"
  },

  {
    name: "Identifier",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "identifier",
    status: 0,
    minLength: 1,
    maxLength: 100,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },
  {
    name: "Status",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "workflowStatus",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "workflowStatus"
  },
  {
    name: "File Generated",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "IsFileGenerated",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "isFileGenerated"
  },

  // Sets Integration (DFG)

  {
    name: "Retries",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "retries",
    status: 0,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },

  {
    name: "Sequence",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "sequence",
    status: 0,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },

  {
    name: "Parameters",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "parameters",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields",
    dataSource: "friday"
  },

  {
    name: "Is Dependent",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isDependent",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Parent",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "parent",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },

  {
    name: "Conditional Computations",
    dataType: inputTypes.findIndex(field => field.type === "list"),
    type: "list",
    description: "",
    helperText: "",
    code: "conditions",
    subFields: ["expression", "source", "computationType", "destination"],
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Source",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "source",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Destination",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "destination",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Rate",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "rate",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "No. of Payments",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "numberOfPayments",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Loan Value",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "loanValue",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Residual Value",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "residualValue",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Method",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "method",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Payment Frequency",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "paymentFrequency",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "EMI Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "emiField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Start Date",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    description: "Start Date",
    helperText: "",
    code: "StartDate",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
    // defaultValue: new Date()
  },

  {
    name: "End Date",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    description: "End Date",
    helperText: "",
    code: "EndDate",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
    // defaultValue: new Date()
  },

  {
    name: "Collector Id",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "CollectorId",
    masterDataType: "usersByCodeCollections",
    dataSource: "identity"
  },

  {
    name: "Customer Id",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "CustId"
  },

  {
    name: "Cycle Cut",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    description: "",
    helperText: "",
    code: "CycleCut"
  },

  {
    name: "Age Code At Cycle Cut",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "AgeCodeAtCycleCut",
    masterDataType: "MCB_CUST_AGE_CODE"
  },

  {
    name: "Age Code At Allocation",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "AgeCodeAtAllocation",
    masterDataType: "MCB_CUST_AGE_CODE"
  },

  {
    name: "Age Code",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "AgeCode",
    masterDataType: "MCB_CUST_AGE_CODE"
  },

  {
    name: "Queue",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "SystemQueue",
    masterDataType: "queues"
  },

  {
    name: "Queues",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    isRequired: false,
    code: "queues",
    masterDataType: "queues"
  },

  {
    name: "Age Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "ageCodes",
    masterDataType: "MCB_CUST_AGE_CODE"
  },

  {
    name: "City",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    description: "",
    helperText: "",
    code: "City",
    masterDataType: "CollectionCity"
  },

  {
    name: "Region",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "Region",
    masterDataType: "CollectionRegion"
  },

  {
    name: "Regions",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "regions",
    masterDataType: "CollectionRegions"
  },

  {
    name: "DB City",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    dependentField: "CollectionMainCity!CollectionSubCity!",
    description: "",
    helperText: "",
    code: "CollectionDBCITY",
    tags: [],
    disabled: false,
    isHidden: false,
    hidden: false,
    masterDataType: "CollectionCity"
  },

  {
    name: "Main City",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    disabled: true,
    hidden: false,
    isHidden: false,
    helperText: "",
    tags: [],
    code: "CollectionMainCity",
    masterDataType: "CollectionMainCity"
  },

  {
    name: "Sub City",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    tags: [],
    disabled: true,
    hidden: false,
    isHidden: false,
    code: "CollectionSubCity",
    masterDataType: "CollectionSubCity"
  },

  {
    name: "Account Fields",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "accountFields",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "fields",
    header: "Collection Management System"
  },

  {
    name: "Identification Fields",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "identificationFields",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "fields",
    header: "Loan Origination System"
  },

  {
    name: "Gross Payable",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "grossPayable",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Verified Income",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "verifiedIncome",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "DBR Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "dbrField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "EMI",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "emi",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "DSR Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "dsrField",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Automated Integrations",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "automatedIntegrations",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "Integrations"
  },

  //Pre Check
  {
    name: "Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: "",
    masterDataType: "Integrations"
  },

  {
    name: "Can Deviate",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "canDeviate",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "Deviation",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "deviationCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "deviation"
  },

  {
    name: "Deviation Approval Group Codes ",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "deviationApprovalGroupCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "EMI on Salary Slip",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "emiOnSalarySlip",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Gross Payable",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "grossPayable",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Current Loan Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "currentLoanAmount",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Province",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "province",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Current Loan EMI",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "currentLoanEMI",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Aggregate EMI",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "aggregateEMI",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Secured EMI",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "securedEMI",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Secured Exposure",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "securedExposure",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Unsecured EMI",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "unsecuredEMI",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Sum of 30 DPD",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus30",
    masterDataType: "fields"
  },
  {
    name: "Sum of 60 DPD",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus60",
    masterDataType: "fields"
  },
  {
    name: "Sum of 90 DPD Count",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus90",
    masterDataType: "fields"
  },
  {
    name: "Sum of 120 DPD Count",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus120",
    masterDataType: "fields"
  },
  {
    name: "Sum of 150 DPD Count",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus150",
    masterDataType: "fields"
  },
  {
    name: "Sum of 180 DPD Count",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus180",
    masterDataType: "fields"
  },
  {
    name: "Sum of 30 DPD Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus30Amount",
    masterDataType: "fields"
  },
  {
    name: "Sum of 60 DPD Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus60Amount",
    masterDataType: "fields"
  },
  {
    name: "Sum of 90 DPD Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus90Amount",
    masterDataType: "fields"
  },
  {
    name: "Sum of 120 DPD Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus120Amount",
    masterDataType: "fields"
  },
  {
    name: "Sum of 150 DPD Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus150Amount",
    masterDataType: "fields"
  },
  {
    name: "Sum of 180 DPD Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "plus180Amount",
    masterDataType: "fields"
  },
  {
    name: "Sum of 1-15 Times Late Payment Count",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "late1To15",
    masterDataType: "fields"
  },
  {
    name: "Sum of 16-20 Times Late Payment Count",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "late16To20",
    masterDataType: "fields"
  },
  {
    name: "Sum of 21-29 Times Late Payment Count",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "late21To29",
    masterDataType: "fields"
  },
  {
    name: "Sum of 30 Times Late Payment Count",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "late30",
    masterDataType: "fields"
  },
  {
    name: "Outstanding Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "outstandingAmount",
    masterDataType: "fields"
  },
  {
    name: "Overdue Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "overdueAmount",
    masterDataType: "fields"
  },
  {
    name: "Loan Limit",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "loanLimit",
    masterDataType: "fields"
  },

  {
    name: "Unsecured Exposure",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "unsecuredExposure",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  // Fields for exposure sheet
  {
    name: "Product Family",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "productFamily",
    status: 2,
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "ProductFamily",
    isExposureSheetFields: true
  },
  {
    name: "Secured",
    dataType: inputTypes.findIndex(field => field.type === "checkbox"),
    type: "checkbox",
    code: "isSecured",
    status: 2,
    disabled: true,
    fixed: true,
    isExposureSheetFields: true
  },
  {
    name: "Rate",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "rate",
    status: 2,
    isRequired: true,
    fixed: true,
    maxLength: 100,
    minLength: 0,
    isExposureSheetFields: true
  },
  {
    name: "Calculation Based",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "calculationBased",
    disabled: true,
    masterDataType: "CalculationTypes",
    isExposureSheetFields: true
  },
  {
    name: "Amount",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "amount",
    status: 2,
    tags: ["amount"],
    isRequired: true,
    fixed: true,
    maxLength: 10000000000000,
    minLength: 0,
    isExposureSheetFields: true
  },
  {
    name: "Utilized",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "utilized",
    status: 2,
    tags: ["amount"],
    fixed: true,
    maxLength: 1000000000,
    minLength: 0,
    isExposureSheetFields: true
  },
  {
    name: "Account Status",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "accountStatus",
    status: 2,
    fixed: true,
    isRequired: true,
    defaultValue: "OPEN",
    masterDataType: "ExposureSheetFacilityStatus",
    isExposureSheetFields: true
  },
  {
    name: "Start Date",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "startDate",
    status: 2,
    isRequired: true,
    fixed: true,
    defaultValue: new Date(),
    isExposureSheetFields: true
  },
  {
    name: "Maturity Date",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "maturityDate",
    status: 2,
    isRequired: true,
    fixed: true,
    defaultValue: new Date(),
    isExposureSheetFields: true
  },
  {
    name: "Matured",
    dataType: inputTypes.findIndex(field => field.type === "checkbox"),
    type: "checkbox",
    code: "isMatured",
    status: 2,
    disabled: true,
    fixed: true,
    isExposureSheetFields: true
  },
  {
    name: "Tenure",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "tenure",
    status: 2,
    isRequired: true,
    disabled: true,
    hidden: false,
    fixed: true,
    maxLength: 1000,
    minLength: 0,
    isExposureSheetFields: true
  },
  {
    name: "EMI",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "emi",
    status: 2,
    tags: ["amount"],
    isRequired: false,
    disabled: true,
    hidden: false,
    fixed: true,
    maxLength: 1000000000,
    minLength: 0,
    isExposureSheetFields: true
  },

  {
    name: "Discrepancy",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "discrepancy"
  },

  {
    name: "Discrepancy Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "type",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "discrepancyTypes"
  },
  {
    name: "Max Approval Stage",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "maxApprovalStage",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "stagesFP",
    dataSource: "product"
  },
  {
    name: "Markup Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "markupField",
    status: 0,
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Spread Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "spreadField",
    masterDataType: "fields"
  },
  {
    name: "Kibor Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "kiborField",
    masterDataType: "fields"
  },
  {
    name: "Type Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "typeField",
    masterDataType: "fields"
  },
  {
    name: "Processing Fees Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "processingFeesField",
    status: 0,
    isRequired: true,
    masterDataType: "fields"
  },

  {
    name: "Status",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "ApplicationStatus",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "workflowStatusForFilter"
  },

  {
    name: "Current Stage",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "CurrentStage",
    fixed: true,
    masterDataType: "stages"
  },

  {
    name: "Companies",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "companies",
    isRequired: true,
    fixed: true,
    masterDataType: "DCLInsuranceCompany"
  },

  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    fixed: true,
    masterDataType: "insuranceTypes"
  },
  {
    name: "Depreciation Rate",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "depreciationRate",
    isRequired: true,
    fixed: true,
    maxLength: 100,
    minLength: 0
  },
  {
    name: "Rate",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "rate",
    isRequired: true,
    fixed: true,
    maxLength: 100,
    minLength: 0
  },
  {
    name: "Amount Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "amountField",
    isRequired: true,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Insurance Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "insuranceField",
    isRequired: true,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Company Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "companyField",
    isRequired: true,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Tenure Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "tenureField",
    isRequired: true,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Tenor Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "tenorField",
    isRequired: false,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Markup Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "markupField",
    isRequired: true,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Finance Amount Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "financeAmountField",
    isRequired: true,
    fixed: true,
    masterDataType: "fields"
  },

  {
    name: "Department",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "department",
    fixed: true,
    masterDataType: "departments"
  },

  {
    name: "Designation",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "designation",
    fixed: true,
    masterDataType: "designations"
  },

  {
    name: "Assigned To",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "AssignedTo",
    fixed: true,
    masterDataType: "users",
    dataSource: "identity"
  },
  {
    name: "User By Code",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "UserByCode",
    fixed: true,
    masterDataType: "usersByCode",
    dataSource: "identity"
  },
  {
    name: "User By Name",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "UserByName",
    fixed: true,
    masterDataType: "usersByName",
    dataSource: "identity"
  },
  {
    name: "User By Id",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "UserById",
    fixed: true,
    masterDataType: "users",
    dataSource: "identity"
  },
  {
    name: "Group By Code",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "GroupByCode",
    fixed: true,
    masterDataType: "groupsByCode",
    dataSource: "identity"
  },

  {
    name: "Permissions",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "permissions",
    fixed: true,
    masterDataType: "StagePermissions"
  },
  {
    name: "Parameters",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "dynamicParameters",
    isRequired: false,
    fixed: true,
    masterDataType: "DynamicTabParameters",
    forDynamicTab: true,
    valueAsString: true
  },

  {
    name: "Is Secured",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isSecured",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    header: "Loan Origination System"
  },

  {
    name: "Two Factor Enabled",
    dataType: inputTypes.findIndex(field => field.type === "checkbox"),
    type: "checkbox",
    code: "twoFactorEnabled",
    status: 2,
    fixed: true
  },
  //Agents E Kyc Fields
  {
    name: "Email",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "email",
    status: 0,
    minLength: 3,
    maxLength: 100,
    regex: emailRegex,
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Contact Number",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "contactNumber",
    status: 0,
    minLength: "",
    maxLength: "",
    regex: "",
    tags: ["ContactNumber"],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Contact Number",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "contactNumber",
    status: 0,
    minLength: "",
    maxLength: "",
    regex: "",
    tags: ["ContactNumber"],
    isRequired: false,
    isHidden: true,
    disabled: false,
    hidden: true,
    fixed: true,
    masking: ""
  },
  {
    name: "Employee Id",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "employeeId",
    status: 0,
    minLength: "",
    maxLength: "",
    regex: "",
    tags: ["EmployeeId"],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "agentType",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "agentType"
  },

  // Interview KYC Fields
  {
    name: "Full Name",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "fullName",
    status: 0,
    minLength: "",
    maxLength: "",
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "National Identifier ",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "cnic",
    status: 0,
    minLength: "",
    maxLength: "",
    regex: "",
    tags: ["maskedCNIC"],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Phone Number",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "contactNumber",
    status: 0,
    minLength: "",
    maxLength: "",
    regex: "",
    tags: ["ContactNumber"],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Call Schedule Date",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    helperText: "",
    code: "dateOnly",
    status: 0,
    regex: "",
    tags: ["disallowPastDays"],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Agent Name",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "agentCode",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isHidden: false,
    isRequired: false,
    disabled: true,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "agents"
  },
  {
    name: "Call Schedule Time",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "startTime",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isHidden: false,
    isRequired: false,
    disabled: true,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "agentTimeSlots"
  },
  {
    name: "Front Cnic Image",
    dataType: inputTypes.findIndex(field => field.type === "fileInput"),
    type: "fileInput",
    helperText: "",
    code: "cnicFront",
    status: 0,
    regex: "",
    tags: [],
    isHidden: true,
    isRequired: false,
    disabled: false,
    hidden: true,
    fixed: true,
    masking: ""
  },

  {
    name: "Duration (Hours)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "hours",
    isRequired: true
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    masterDataType: "turnAroundTimeType"
  },
  {
    name: "Escalation Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "escalationType",
    masterDataType: "escalationType"
  },
  {
    name: "Stage",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "stageCode",
    masterDataType: "stagesFP",
    dataSource: "product",
    filterAuthentication: true
  },
  {
    name: "Authentication",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "authenticationCode",
    masterDataType: "authenticationFP",
    dataSource: "product"
  },
  {
    name: "Event",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "event",
    masterDataType: "turnAroundTimeEvent"
  },
  {
    name: "Treatment",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "treatment",
    masterDataType: "turnAroundTimeTreatment"
  },
  {
    name: "Rule Title",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    code: "ruleName",
    isRequired: true
  },
  {
    name: "Message",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    code: "ruleMessage",
    isRequired: true
  },
  {
    name: "Name Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "nameFieldCode",
    isRequired: false,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "CNIC Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "uniqueIdentifierFieldCode",
    isRequired: false,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Grid Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "gridCode",
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Name Field Code For Grid",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "nameFieldCodeForGrid",
    isRequired: false,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "CNIC Field Code For Grid",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "uniqueIdentifierFieldCodeForGrid",
    isRequired: false,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    masterDataType: "customerConfigurationTypes"
  },

  {
    name: "Field Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "productFieldCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Field Codes For Grid",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    valueAsString: false,
    description: "",
    helperText: "",
    code: "productFieldCodesForGrid",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },

  {
    name: "Fixed Fields",
    dataType: inputTypes.findIndex(field => field.type === "list"),
    type: "list",
    code: "fixedFields",
    subFields: [
      "nameFieldCode",
      "uniqueIdentifierFieldCode",
      "productFieldCodes"
    ],
    fixed: true,
    masterDataType: "fields"
  },

  {
    name: "Product",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "productCode",
    isRequired: true,
    fixed: true,
    masterDataType: "products"
  },
  {
    name: "CNIC",
    dataType: 0,
    type: "string",
    code: "cnic",
    status: 0,
    isRequired: true,
    tags: ["maskedCNIC"],
    isWorkflowCustomerStep1Field: true,
    minLength: 15,
    maxLength: 15
  },
  {
    name: "CNIC",
    dataType: 0,
    type: "string",
    code: "cnic",
    status: 0,
    isRequired: true,
    tags: ["maskedCNIC"],
    disabled: true,
    isWorkflowCustomerStep2Field: true
  },
  {
    name: "Name",
    dataType: 0,
    type: "string",
    code: "name",
    minLength: 1,
    maxLength: 50,
    isRequired: true,
    isWorkflowCustomerStep2Field: true
  },
  {
    name: "Mobile",
    dataType: 0,
    code: "mobileNumber",
    isRequired: false,
    header: "IdentityInformation",
    tags: ["ContactNumber"],
    isWorkflowCustomerStep2Field: true
  },
  {
    name: "Date of Birth",
    dataType: 7,
    code: "dateOfBirth",
    isRequired: false,
    header: "IdentityInformation",
    tags: ["disallowFutureDays"],
    isWorkflowCustomerStep2Field: true
  },

  {
    name: "Level",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "level",
    isRequired: true
  },

  {
    name: "Duration (Hours)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "duration"
  },

  {
    name: "Escalation Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "escalationGroups",
    isRequired: true,
    masterDataType: "groups",
    dataSource: "identity"
  },

  {
    name: "Collector Ids",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "collectorIds",
    fixed: true,
    isRequired: true,
    masterDataType: "users",
    dataSource: "identity"
  },

  {
    name: "Is Grid",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "isGrid",
    isRequired: false
  },
  {
    name: "Grid Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "gridCode",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Grid Identifier",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "gridIdentifier",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Master Data Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "masterDataType",
    masterDataType: "entities",
    dataSource: "friday"
  },
  {
    name: "Is Root Level Field (Only applicable for grid scoring)",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "isRootLevelField",
    isRequired: false
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "type",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    isHidden: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "otpTypes"
  },
  {
    name: "No of OTP digits",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "digits",
    status: 0,
    regex: "",
    tags: ["WholeNumber"],
    maxLength: 8,
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: ""
  },
  {
    name: "Channels",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "channels",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "otpChannel"
  },
  {
    name: "Code",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    minLength: 3,
    maxLength: 100,
    regex: alphaNumericRegex,
    isRequired: true,
    disabled: false,
    hidden: true,
    fixed: true,
    masking: ""
  },
  {
    name: "Notification Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "notificationType",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "notificationType"
  },
  {
    name: "Recipient Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "recipientType",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "recipientTypes"
  },
  {
    name: "Products",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "productCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: true,
    fixed: true,
    masterDataType: "products",
    isNotificationProductCodes: true
  },
  {
    name: "Stage Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "stageCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    isHidden: true,
    disabled: false,
    hidden: true,
    fixed: true,
    masterDataType: "stageCodes",
    isNotificationStageCodes: true
  },
  {
    name: "Event Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "eventType",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "eventTypes"
  },
  {
    name: "Levels",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "levels",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    isHidden: true,
    disabled: false,
    hidden: true,
    fixed: true,
    masterDataType: "authenticationLevels"
  },
  {
    name: "Expression",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "expression",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"]
  },
  {
    name: "Escalation Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "escalationCodes",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    isHidden: true,
    hidden: true,
    fixed: true,
    masking: "",
    masterDataType: "escalations"
  },
  {
    name: "OTP Length",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "length",
    status: 0,
    // minLength: -1000000,
    // maxLength: 100,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    isHidden: true,
    hidden: true,
    fixed: true,
    masking: ""
  },
  {
    name: "OTP Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "passwordType",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    isHidden: true,
    hidden: true,
    fixed: true,
    masking: "",
    masterDataType: "otpType"
  },
  {
    name: "Retries Count",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "retriesCount",
    status: 0,
    // minLength: -1000000,
    // maxLength: 100,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    isHidden: true,
    hidden: true,
    fixed: true,
    masking: ""
  },
  {
    name: "Resent Duration (Seconds)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "resentAfterDuration",
    status: 0,
    // minLength: -1000000,
    // maxLength: 100,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    isHidden: true,
    hidden: true,
    fixed: true,
    masking: ""
  },
  {
    name: "Field Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "fieldType",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    isHidden: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fieldTypes"
  },

  {
    name: "Recipients",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "(Email or SMS)",
    helperText: "",
    code: "recipients",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    isHidden: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "fields"
  },
  {
    name: "Additional Recipients",
    description: "(Email or SMS)",
    dataType: inputTypes.findIndex(field => field.type === "creatableSelect"),
    type: "creatableSelect",
    code: "additionalRecipients"
  },
  {
    name: "Grid Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "gridCode",
    isRequired: false,
    isHidden: true,
    disabled: false,
    hidden: true,
    fixed: true,
    masterDataType: "fields",
    isNotificationGridCode: true
  },
  {
    name: "Subject",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    description: "",
    helperText: "",
    code: "header",
    status: 0,
    regex: "",
    tags: [],
    isHidden: false,
    // header: "email",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Email",
    dataType: inputTypes.findIndex(field => field.type === "emailEditor"),
    type: "text",
    description: "",
    helperText: "",
    code: "body",
    status: 0,
    regex: "",
    tags: [],
    // header: "email",
    isRequired: false,
    isHidden: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Message",
    dataType: inputTypes.findIndex(field => field.type === "textArea"),
    type: "text",
    description: "",
    helperText: "",
    code: "body",
    status: 0,
    regex: "",
    tags: [],
    // header: "sms",
    isRequired: true,
    isHidden: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Title",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    description: "",
    helperText: "",
    code: "header",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },

  {
    name: "CNIC",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "cnic",
    masterDataType: "fields"
  },
  {
    name: "Customer Name",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "customerName",
    masterDataType: "fields"
  },
  {
    name: "Address",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "address",
    masterDataType: "fields"
  },
  {
    name: "Limit",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "limit",
    masterDataType: "fields"
  },
  {
    name: "City",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "city",
    masterDataType: "fields"
  },
  {
    name: "Date of Birth",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "dob",
    masterDataType: "fields"
  },
  {
    name: "Fuzzy Search",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "fuzzySearch"
  },
  {
    name: "State",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "state",
    masterDataType: "statuses"
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    status: 0,
    isRequired: true,
    fixed: true,
    masterDataType: "installmentTypes"
  },
  {
    name: "Payment Frequency",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "paymentFrequency",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Loan Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "loanAmount",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Tenure",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "tenure",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Markup",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "markup",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Grace Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "graceType",
    masterDataType: "fields"
  },
  {
    name: "Grace Period",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "gracePeriod",
    masterDataType: "fields"
  },
  {
    name: "Payment Grace Period",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "paymentGracePeriod",
    masterDataType: "fields"
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Stages",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "stageCodes",
    isRequired: true,
    masterDataType: "stagesFP",
    dataSource: "product"
  },
  {
    name: "Disbursement Date",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "disbursementDate",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Bullet Loan End Date",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "bulletLoanEndDate",
    masterDataType: "fields"
  },
  {
    name: "Markup/Profit Based On",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "markupBasedOn",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Residual Value",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "residualValue",
    masterDataType: "fields"
  },
  {
    name: "Security Deposit",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "securityDeposit",
    masterDataType: "fields"
  },
  {
    name: "RoundOff Decimal UpTo",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "roundOffDecimalUpTo",
    masterDataType: "fields"
  },
  {
    name: "From",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    isRequired: true,
    code: "from"
  },
  {
    name: "To",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    isRequired: true,
    code: "to"
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    masterDataType: "firstInstallmentBehaviourTypes"
  },
  {
    name: "City",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "city",
    isRequired: true,
    fixed: true,
    masterDataType: "eKYCCITY"
  },
  {
    name: "Expected Monthly Salary",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "expectedSalary",
    status: 0,
    minLength: 0,
    // maxLength: "",
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },
  {
    name: "Number of Business",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "numberOfBusiness",
    status: 0,
    minLength: 0,
    // maxLength: 2,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },
  {
    name: "Country",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "country",
    isRequired: true,
    fixed: true,
    masterDataType: "ekycCountryofResidence"
  },
  {
    name: "Political Figure Name",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    description: "",
    helperText: "",
    code: "politicalfigureName",
    status: 0,
    regex: "",
    tags: [],
    isHidden: false,
    // header: "email",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Occupation",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    description: "",
    helperText: "",
    code: "occupation",
    status: 0,
    regex: "",
    tags: [],
    isHidden: false,
    // header: "email",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Source of Income",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    description: "",
    helperText: "",
    code: "sourceOfIncome",
    status: 0,
    regex: "",
    tags: [],
    isHidden: false,
    // header: "email",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Other Source of Fund(Extra Business)",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    description: "",
    helperText: "",
    code: "otherSourceOfIncome",
    status: 0,
    regex: "",
    tags: [],
    isHidden: false,
    // header: "email",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Nature of Business",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    description: "",
    helperText: "",
    code: "businessNature",
    status: 0,
    regex: "",
    tags: [],
    isHidden: false,
    // header: "email",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Comments",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    description: "",
    helperText: "",
    code: "comments",
    status: 0,
    regex: "",
    tags: [],
    isHidden: false,
    // header: "email",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Mathematical Expression",
    dataType: inputTypes.findIndex(field => field.type === "textArea"),
    type: "text",
    code: "mathematicalExpression"
  },
  {
    name: "Name",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    code: "name",
    disabled: true
  },
  {
    name: "National Identifier",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    code: "cnic",
    disabled: true,
    tags: ["maskedCNIC"]
  },
  {
    name: "Email",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    code: "email",
    disabled: true
  },
  {
    name: "Interview Failed Reason",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "reason",
    isRequired: true,
    fixed: true,
    masterDataType: "VideoKYCFailedInterviewReasons"
  },
  {
    name: "Comments",
    dataType: inputTypes.findIndex(field => field.type === "textArea"),
    type: "text",
    description: "",
    helperText: "",
    code: "comments",
    status: 0,
    regex: "",
    tags: [],
    // header: "sms",
    isRequired: true,
    isHidden: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Root Parameters",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "rootParameters",
    masterDataType: "gridExposureSheetsRootParameters"
  },
  {
    name: "type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    masterDataType: "stateWidgetTypeList"
  },
  {
    name: "Placement",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "placement",
    masterDataType: "placementList"
  },
  {
    name: "Render On",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "renderOn",
    masterDataType: "renderOnList"
  },
  {
    name: "Input Parameters",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "inputParameters",
    masterDataType: "DynamicTabParameters",
    forDynamicTab: true,
    valueAsString: true
  },
  {
    name: "Output Parameters",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "outputParameters",
    masterDataType: "DynamicTabParameters",
    forDynamicTab: true,
    valueAsString: true
  },
  {
    name: "Columns",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "columns",
    masterDataType: "setFlowsColumns"
  },
  {
    name: "Debt Burden Ratio",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "debtBurdenRatio",
    masterDataType: "fields"
  },
  {
    name: "Debt Service Ratio",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "debtServiceRatio",
    masterDataType: "fields"
  },
  {
    name: "Validity (Days)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "validityInDays"
  },
  {
    name: "Inactivity Duration (Days)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "inactivityDurationInDays"
  },
  {
    name: "Markup",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "markup"
  },
  {
    name: "Principle",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "principle"
  },
  {
    name: "Total Tenure",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "totalTenure",
    disabled: false
  },
  {
    name: "Past Tenure",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "pastTenure",
    disabled: false
  },
  {
    name: "Remaining Tenure",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "remainingTenure",
    disabled: false
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "bulkPaymentType",
    masterDataType: "bulkPaymentsList"
  },
  {
    name: "Value",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "bulkPaymentValue",
    disabled: false
  },
  {
    name: "Penalty",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "penalty",
    disabled: false
  },
  {
    name: "Waiver",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "waiver",
    disabled: false
  },
  {
    name: "Penalty after waiver",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "penaltyAfterWaiver",
    disabled: false
  },
  {
    name: "Discrepancy",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "discrepancyCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: true,
    hidden: true,
    fixed: true,
    masking: "",
    masterDataType: "discrepancy"
  },
  {
    name: "Discrepancy Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "discrepancyType",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: true,
    hidden: true,
    fixed: true,
    masking: "",
    masterDataType: "discrepancyTypes"
  },
  {
    name: "Field Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "fieldCodes",
    status: 0,
    regex: "",
    tags: [],
    isHidden: true,
    isRequired: false,
    disabled: false,
    hidden: true,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Document Group Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "documentGroupCodes",
    status: 0,
    regex: "",
    tags: [],
    isHidden: true,
    isRequired: false,
    disabled: false,
    hidden: true,
    fixed: true,
    masking: "",
    masterDataType: "DocumentGroups"
  },
  {
    name: "Max Approval Stage",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "maxApprovalStage",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: true,
    hidden: true,
    fixed: true,
    masking: "",
    masterDataType: "productStages"
  },
  {
    name: "Advice Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "adviceCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: true,
    fixed: true,
    masking: "",
    masterDataType: "advices"
  },
  {
    name: "Advice Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "type",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: true,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "adviceTypes"
  },
  {
    name: "Max Approval Stage",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "maxApprovalStage",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "productStages"
  },
  {
    name: "Approval Group Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "approvalGroupCodes",
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "allGroups"
  },
  {
    name: "Advice",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "code",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "advices"
  },
  {
    name: "Rule Expression Conditions",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "condition",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"],
    dataSource: "identity"
  },

  {
    name: "Child Products",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "childProducts",
    fixed: true,
    masterDataType: "products"
  },
  {
    name: "Name",
    dataType: 0,
    type: "string",
    code: "name",
    minLength: 1,
    maxLength: 50,
    isRequired: true,
    isHidden: true,
    hidden: true,
    isCreateAdvice: true
  },
  {
    name: "Tenor",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "tenor",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isHidden: false,
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "kiborTenors"
  },
  {
    name: "Unit",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "unit",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isHidden: false,
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "kiborUnits"
  },
  {
    name: "Bid",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "bid",
    isRequired: true
  },
  {
    name: "Offer",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "offer",
    isRequired: true
  },
  {
    name: "Date",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "date",
    status: 0,
    fixed: true,
    isRequired: true,
    tags: ["disallowFutureDays"]
  },
  {
    name: "Rates",
    dataType: inputTypes.findIndex(field => field.type === "list"),
    type: "list",
    description: "",
    helperText: "",
    code: "rates",
    subFields: ["tenor", "unit", "bid", "offer"],
    status: 0,
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Document Group",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "additionalInformation",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "DocumentGroups"
  },
  {
    name: "Is Maker Checker Enable",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isMakerCheckerEnabled",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Source Longitude",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "sourceLongitude",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Source Latitude",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "sourceLatitude",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Destination Longitude",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "destinationLongitude",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Destination Latitude",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "destinationLatitude",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Radius (In Meters)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "radius",
    isRequired: true
  },
  {
    name: "Stage",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "stageCode",
    masterDataType: "stagesFP",
    dataSource: "product",
    isPurging: true
  },
  {
    name: "Treatment",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "treatment",
    masterDataType: "purgingTreatment"
  },
  {
    name: "Tat",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    description: "",
    helperText: "",
    code: "tat",
    minLength: 0,
    // maxLength: "",
    regex: "",
    tags: [],
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: false,
    masking: ""
  },
  {
    name: "Is Fetch Location",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "isFetchLocation",
    isRequired: false
  },
  {
    name: "Disable Retry",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "disableRetry",
    isRequired: false
  },
  {
    name: "Mandatory Fields",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "select",
    code: "mandatoryFields",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Longitude Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "longitudeFieldCode",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Mobile Upload Options",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "mobileUploadOptions",
    isRequired: false,
    masterDataType: "mobileUploadDropdownOptions"
  },
  {
    name: "Latitude Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "latitudeFieldCode",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Retrieve From Archive",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "retrieveFromArchive",
    isRequired: false
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "isGrid",
    isRequired: true,
    isHidden: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "isGrid",
    isGridCode: true
  },

  {
    name: "Table",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "gridCode",
    isRequired: false,
    isHidden: true,
    disabled: false,
    hidden: true,
    fixed: true,
    masterDataType: "gridFieldCodes",
    isGridCode: true
  },
  {
    name: "Grid Identifier Code",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    description: "",
    helperText: "",
    code: "gridIdentifierCode",
    status: 0,
    regex: "",
    tags: [],
    isHidden: true,
    // header: "email",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Table Identifier",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "gridIdentifierValue",
    status: 0,
    regex: "",
    tags: [],
    isHidden: true,
    // header: "email",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "gridIdentifierValues"
  },
  {
    name: "Category",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "isGrid",
    isRequired: false,
    isHidden: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masterDataType: "isGrid",
    isGridCode: true
  },
  {
    name: "Document Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "documentType",
    isRequired: false,
    isHidden: true,
    disabled: false,
    hidden: true,
    fixed: true,
    masterDataType: "documentTypes",
    isUploadDocument: true
  },
  {
    name: "Additional Information",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "additionalInformation",
    isRequired: false,
    isHidden: true,
    disabled: false,
    hidden: true,
    fixed: true,

    isUploadDocument: true
  },
  {
    name: "Logo",
    dataType: inputTypes.findIndex(field => field.type === "fileInput"),
    type: "fileInput",
    code: "logo",
    status: 0,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Primary Color",
    dataType: inputTypes.findIndex(field => field.type === "colorPicker"),
    type: "colorPicker",
    description: "",
    helperText: "",
    code: "primaryColor",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Secondary Color",
    dataType: inputTypes.findIndex(field => field.type === "colorPicker"),
    type: "colorPicker",
    description: "",
    helperText: "",
    code: "secondaryColor",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Currency",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "currency",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "currencyList"
  },
  {
    name: "Character Pool",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    code: "characterPool",
    regex: new RegExp(`^\\w*$`),
    minLength: 5,
    maxLength: 100
  },
  {
    name: "Prefix",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    code: "prefix",
    maxLength: 20
  },
  {
    name: "Suffix",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    code: "suffix",
    maxLength: 20
  },
  {
    name: "Randomize Digit Length",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "middleLength",
    minLength: 5,
    maxLength: 15
  },
  {
    name: "Scheme Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "schemeType",
    status: 2,
    fixed: true,
    isRequired: false,
    masterDataType: "schemeTypes"
  },
  {
    name: "Event",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "event",
    status: 2,
    isRequired: true,
    masterDataType: "GeneralLedgerType"
  },
  {
    name: "Entry Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "entryType",
    status: 2,
    isRequired: true,
    masterDataType: "entryTypes"
  },
  {
    name: "Date",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "date",
    masterDataType: "fields"
  },
  {
    name: "Account Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "accountType",
    masterDataType: "ParwaazAccountTypes"
  },
  {
    name: "Account No.",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "accountNumber",
    masterDataType: "fields"
  },
  {
    name: "Amount",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "amount",
    masterDataType: "fields"
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    status: 2,
    isRequired: true,
    masterDataType: "BackendConditionTypes"
  },
  {
    name: "Outcome",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "String",
    code: "outcome",
    maxLength: 100
  },
  {
    name: "Creation Stages",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "stages",
    status: 2,
    isRequired: true,
    masterDataType: "stagesFP",
    dataSource: "product"
  },
  {
    name: "Resolution Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "resolutionType",
    status: 2,
    isRequired: true,
    masterDataType: "resolutionTypes"
  },
  {
    name: "Resolution Stage",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "resolutionStage",
    status: 2,
    masterDataType: "stagesFP",
    dataSource: "product"
  },
  {
    name: "Resolution Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "approvalGroups",
    status: 2,
    masterDataType: "groups",
    dataSource: "identity"
  },
  {
    name: "Discrepancy Main Fields",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "discrepancyMainFields",
    status: 2,
    masterDataType: "fields"
  },
  {
    name: "Discrepancy List Fields",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "discrepancyListFields",
    status: 2,
    masterDataType: "fields"
  },
  {
    name: "Override Hierarchy",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "overrideHierarchy"
  },
  {
    name: "Hierarchy Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "hierarchyFieldCode",
    masterDataType: "fields"
  },
  {
    name: "Result Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "resultField",
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    masterDataType: "screeningConditionTypes"
  },
  {
    name: "Lists",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "lists",
    masterDataType: "entities",
    dataSource: "friday"
  },
  {
    name: "Workflow Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "workflowField",
    masterDataType: "fields"
  },
  {
    name: "Field Of Provided List",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "fieldOfProvidedList",
    masterDataType: "fields"
  },
  {
    name: "Operation",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "operation",
    masterDataType: "ScreeningConditionsOperations"
  },
  {
    name: "From (Installment)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "fromInstallment"
  },
  {
    name: "To (Installment)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "toInstallment"
  },
  {
    name: "Is Hierarchy Based",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "isHierarchyBased"
  },
  {
    name: "Hierarchy Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "hierarchyRecord",
    masterDataType: "entities",
    dataSource: "friday"
  },
  {
    name: "Hierarchy Recipient",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "hierarchyRecipient",
    masterDataType: "fields"
  },
  {
    name: "Post Generation",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "postGeneration"
  },
  {
    name: "Fuzzy Operator",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "fuzzyOperator",
    masterDataType: "fuzzyOperator"
  },
  {
    name: "Hierarchy",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "hierarchy",
    masterDataType: "paymentTypes"
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    masterDataType: "dpdTypes"
  },
  {
    name: "Divide Rate",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "divideRate"
  },
  {
    name: "Source",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "source",
    masterDataType: "reportSources"
  },
  {
    name: "Balance",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "balance",
    tags: ["amount"]
  },
  {
    name: "Date",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "date",
    tags: ["statementDateFormat"]
  },
  {
    name: "Credit",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "credit",
    tags: ["amount"]
  },
  {
    name: "Debit",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "debit",
    tags: ["amount"]
  },
  {
    name: "Details",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    code: "details"
  },
  {
    name: "Value Date",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "valueDate",
    tags: ["statementDateFormat"]
  },
  {
    name: "Is Salary",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "isSalary",
    isRequired: false
  },
  {
    name: "Criteria Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    masterDataType: "criteriaTypes"
  },
  {
    name: "Quota Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "quotaType",
    masterDataType: "quotaTypes"
  },
  {
    name: "Time Period",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "period",
    masterDataType: "timePeriodTypes"
  },
  {
    name: "Deviation Level",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "deviationLevel"
  },
  {
    name: "Max Allowed Exceptions",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "maxAllowedExceptions"
  },
  {
    name: "Max Allowed Exceptions Number",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "maxAllowedExceptionsNumber"
  },
  {
    name: "Max Allowed Exceptions Percentage",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "maxAllowedExceptionsPercentage"
  },
  {
    name: "Bureaus",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "bureaus",
    masterDataType: "bureaus"
  },
  {
    name: "Primary Field",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "primaryField",
    masterDataType: "fields"
  },
  {
    name: "Products",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "products",
    masterDataType: "products"
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    masterDataType: "incentiveLevelTypes"
  },
  {
    name: "Type",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    masterDataType: "incentiveLevelVariantTypes"
  },
  {
    name: "Level",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "level",
    isRequired: true,
    masterDataType: "incentiveLevelsFP"
  },
  {
    name: "Calculation Based On",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "calculationBasedOn",
    isRequired: true,
    masterDataType: "incentiveLevelVariantSlabCalculationBasedOn"
  },
  {
    name: "Variant",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "variant",
    isRequired: true,
    masterDataType: "variantsFP"
  },
  {
    name: "From",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    isRequired: true,
    code: "from"
  },
  {
    name: "To",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    isRequired: true,
    code: "to"
  },
  {
    name: "Value (Amount/Percentage)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    isRequired: true,
    code: "value"
  },
  {
    name: "First Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "firstMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Second Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "secondMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Third Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "thirdMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Fourth Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "fourthMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Fifth Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "fifthMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Sixth Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "sixthMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Seventh Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "seventhMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Eighth Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "eighthMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Ninth Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "ninthMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Tenth Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "tenthMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Eleventh Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "eleventhMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Twelfth Month Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "twelfthMonthBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Total Credit Last Six Months",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "totalCreditLastSixMonths",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Total Credit Last Twelve Months",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "totalCreditLastTwelveMonths",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Total Debit Last Six Months",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "totalDebitLastSixMonths",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Total Debit Last Twelve Months",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "totalDebitLastTwelveMonths",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Last Two Months Average Balance",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "lastTwoMonthsAverageBalance",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Father Name",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "fatherName",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "CNIC",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "cnic",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Date of Birth",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "dateOfBirth",
    isRequired: false,
    masterDataType: "fields"
  },
  {
    name: "Gender",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "gender",
    masterDataType: "fields"
  },
  {
    name: "Users",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "users",
    isRequired: true,
    masterDataType: "users"
  },
  {
    name: "Hierarchies",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "hierarchies",
    isRequired: true,
    masterDataType: "hierarchies"
  },
  {
    name: "Stage Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "stageCode",
    isRequired: true,
    masterDataType: "stages"
  },
  {
    name: "Product Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "productCode",
    isRequired: true,
    masterDataType: "productCode"
  },
  {
    name: "State",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "type",
    isRequired: true,
    masterDataType: "statuses"
  },
  {
    name: "From (Date)",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "fromDate",
    hidden: false,
    tags: ["disallowPastDays"]
  },
  {
    name: "To (Date)",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "toDate",
    hidden: false,
    tags: ["disallowPastDays"]
  },
  {
    name: "Mfi Default",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "mfiDefault",
    masterDataType: "fields"
  },
  {
    name: "WriteOff",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    code: "writeoff",
    masterDataType: "fields"
  },
  {
    name: "ShouldClearValue",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "shouldClearValue",
    maxLength: 5
  },
  {
    name: "Duration (Days)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "duration",
    isRequired: true
  },
  {
    name: "Validate Permission On Workflow Initiation",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "validatePermissionOnWorkflowInitiation"
  },
  {
    name: "Result Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "resultFieldCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Identifier Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "identifierFieldCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Product Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "productFieldCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Reason Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "reasonFieldCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Comment Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "commentFieldCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "National Identifier Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "nationalIdentifierFieldCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Date Of Birth Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "dateOfBirthFieldCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Mobile Number Field Code",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "mobileNumberFieldCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Cooling Period (Hours)",
    dataType: inputTypes.findIndex(field => field.type === "number"),
    type: "number",
    code: "coolingPeriod",
    header: "Loan Management System"
  },
  {
    name: "Field Codes",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    code: "fieldCodes",
    hidden: false,
    isRequired: true,
    masterDataType: "fields"
  },
  {
    name: "Maintain Previous Assignment",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "maintainPreviousAssignment",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Assignment Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "assignmentGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },
  {
    name: "ReAssignment Groups",
    dataType: inputTypes.findIndex(field => field.type === "multiselect"),
    type: "multiselect",
    description: "",
    helperText: "",
    code: "reAssignmentGroups",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "groups",
    dataSource: "identity"
  },
  {
    name: "Condition Trigger",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "conditionTrigger",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "conditionsTrigger"
  },
  {
    name: "From Date",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "fromDate",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "To Date",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "toDate",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "fields"
  },
  {
    name: "Icon",
    dataType: inputTypes.findIndex(field => field.type === "iconPicker"),
    type: "iconPicker",
    description: "",
    helperText: "",
    code: "icon",
    status: 0,
    // minLength: 0,
    // maxLength: 100,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Is Custom Rate?",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "isCustomRate"
  },
  {
    name: "Default Value Expression",
    dataType: inputTypes.findIndex(field => field.type === "conditionBuilder"),
    type: "conditionBuilder",
    description: "",
    helperText: "",
    code: "defaultValueExpression",
    status: 0,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    representations: ["HR", "SQL", "JSONLogic"]
  },
  {
    name: "Force Delete Record",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    code: "forceDeleteRecord"
  },
  {
    name: "Discrepancy Status",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "discrepancyStatus",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "discrepancyStatus"
  },
  {
    name: "Is Discrepant",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "isDiscrepant",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Outcome Respect (For Group & Header)",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description:
      "The dropdown controls checkboxes (Is Hidden, Mandatory, Readonly) to respect outcomes. " +
      '"Respect All" activates all, while a specific option activates only that checkbox.',
    helperText: "",
    code: "outcomeRespect",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "outcomeBehaviours"
  },
  {
    name: "Disbursement Date",
    dataType: inputTypes.findIndex(field => field.type === "datePicker"),
    type: "datePicker",
    code: "disbursementDate",
    status: 0,
    fixed: true,
    isRequired: false
  },
  {
    name: "Send Sms",
    dataType: inputTypes.findIndex(field => field.type === "switch"),
    type: "switch",
    description: "",
    helperText: "",
    code: "sendSms",
    status: 0,
    minLength: 0,
    maxLength: 5,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "Misys Deal Reference",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "misysDealReference",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isRequired: true,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  },
  {
    name: "CheckList",
    dataType: inputTypes.findIndex(field => field.type === "select"),
    type: "select",
    description: "",
    helperText: "",
    code: "checklistCode",
    status: 0,
    regex: "",
    tags: [],
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: "",
    masterDataType: "checkListFP",
    dataSource: "product"
  },
  {
    name: "Trailing Symbol",
    dataType: inputTypes.findIndex(field => field.type === "text"),
    type: "string",
    description: "",
    helperText: "",
    code: "trailingSymbol",
    status: 0,
    minLength: 1,
    maxLength: 1000,
    regex: "",
    isRequired: false,
    disabled: false,
    hidden: false,
    fixed: true,
    masking: ""
  }
];
