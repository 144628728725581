import moment from "moment";
import * as yup from "yup";
import lodash from "lodash";
import pluralize from "pluralize";
import * as SignalR from "@microsoft/signalr";

// Constants
import {
  componentFields,
  createFieldRestriction,
  dropDownOptions,
  initialProduct,
  inputTypesDictionary,
  queryBuilderQueryTypeEnums,
  staticFieldRestrictions
} from "./staticFields";
import { inputTypes } from "./commonStaticVariables";
import {
  errorNotification,
  successNotification
} from "../../_custom/_partials/notifications";
import {
  defaultValueTypes,
  workflowStatus
} from "../../_custom/_helpers/enums";
import {
  ApplicationDynamicFormatter,
  NameColumnFormatter
} from "../_partials/grids/column-formatters";
import jsonLogic from "../../app/helper/logic";
import ReactHtmlParser from "react-html-parser";

export const getSingularName = string =>
  string.charAt(string.length - 1).toLowerCase() === "s"
    ? string.substring(0, string.length - 1)
    : string;

export const convertFirstToUpperCase = string =>
  string?.[0]?.toUpperCase() + string?.slice(1);

export const getRenderableColumnNames = (module, subModule, category) => {
  let columnNames = componentFields["general"][category];
  if (module && subModule && category) {
    const moduleKey = module.replace("-", "_");
    columnNames =
      componentFields[moduleKey] &&
      componentFields[moduleKey][subModule] &&
      componentFields[moduleKey][subModule][category]
        ? componentFields[moduleKey][subModule][category]
        : componentFields["general"][category];
  }
  return columnNames;
};

export const getRestrictions = (
  code,
  attributes,
  restrictionType,
  isProductFactory = false,
  isFilter = false
) => {
  const { isRequired, disabled, hidden } = attributes;
  const staticFields = [
    // "name",
    "code",
    "status",
    "entityStatus",
    "entityCode"
    // "dataType",
  ];
  let restrictions = {};

  if (staticFields.includes(code)) restrictions = staticFieldRestrictions[code];
  else
    restrictions = {
      new: { required: isRequired, disabled, hidden },
      add: { required: isRequired, disabled, hidden },
      clone: { required: isRequired, disabled, hidden },
      edit: { required: isRequired, disabled, hidden },
      delete: { required: isRequired, disabled, hidden },
      updateStatus: { required: isRequired, disabled, hidden },
      view: {
        required: isRequired,
        disabled: true,
        hidden: hidden
      }
    };

  if (isProductFactory && code === "status") {
    restrictions = {
      add: createFieldRestriction(true, false, false),
      new: createFieldRestriction(true, false, false),
      clone: createFieldRestriction(true, false, false),
      edit: createFieldRestriction(true, false, false),
      view: createFieldRestriction(true, true, false)
    };
  }

  if (isFilter && code === "code") {
    restrictions = {
      add: createFieldRestriction(false, false, false),
      new: createFieldRestriction(false, false, false),
      clone: createFieldRestriction(false, false, false),
      edit: createFieldRestriction(false, false, false),
      view: createFieldRestriction(false, true, false)
    };
  }
  return restrictions[restrictionType] || {};
};

export const removeNullValuesFromData = data => {
  if (!data) return data;

  let tempData = { ...data };
  for (const [key, value] of Object.entries(tempData)) {
    if (value === null || value === undefined || value === "") {
      tempData[key] = null;
    }
  }
  return tempData;
};

export const eliminateNullValuesFromData = data => {
  if (!data) return data;

  let updatedDate = {};
  for (const [key, value] of Object.entries(data)) {
    if (!(value === null || value === undefined || value === "")) {
      updatedDate[key] = value;
    }
  }
  return updatedDate;
};

export const getInitialValues = (stateData, fieldsData, type) => {
  let initialValues = { ...stateData };
  if (fieldsData === null || fieldsData === undefined) fieldsData = {};
  if (type !== "add")
    initialValues = { ...stateData, ...removeNullValuesFromData(fieldsData) };
  return initialValues;
};

const createYupSchema = (schema, config) => {
  const { id, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations.forEach(validation => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  if (validationType === "array")
    schema["gridFields"] = { ...(schema["gridFields"] || {}), [id]: validator };
  else schema[id] = validator;
  return schema;
};

export const getFieldsWithValidation = (fields, type, tagsDictionary = {}) => {
  let formData = Object.values(fields).map((field, index) => {
    let overrides = {};
    const { code: id } = field;
    const label = field.name;
    // const label = field.name
    //   .split(" ")
    //   .map((item) => convertFirstToUpperCase(item))
    //   .join(" ");
    const placeholder = label;
    let validationType = "string";
    let onlyRequiredCheck = false;

    if (field.dataType !== undefined) {
      validationType = inputTypesDictionary[field.dataType].validationType;
      onlyRequiredCheck =
        inputTypesDictionary[field.dataType].onlyRequiredCheck;
    }

    let value = "";

    let validations = [];
    validations.push({
      type: "nullable",
      params: [true]
    });

    let { minLength, maxLength, regex } = field;

    minLength = !(
      minLength === "" ||
      minLength === null ||
      minLength === undefined ||
      onlyRequiredCheck
    )
      ? Number(minLength)
      : null;
    overrides["minLength"] = minLength;

    maxLength = !(
      maxLength === "" ||
      maxLength === null ||
      maxLength === undefined ||
      onlyRequiredCheck
    )
      ? Number(maxLength)
      : null;
    overrides["maxLength"] = maxLength;

    regex = !onlyRequiredCheck ? regex : null;
    overrides["regex"] = regex;

    Object.keys(field).forEach(key => {
      if (field.name === "status" && (type === "new" || type === "add")) return;
      const name = convertFirstToUpperCase(field.name);
      const updatedField = { ...field, minLength, maxLength };

      if (updatedField[key] !== null && updatedField[key] !== undefined) {
        switch (key) {
          case "isRequired": {
            // not adding required validation to fields of type number bcoz they are used in fields form with
            // variable requirements based on datatype
            // if (updatedField[key] && validationType !== "number")
            if (updatedField[key]) {
              if (validationType === "array") {
                validations.push({
                  type: "min",
                  params: [1, `${name} is required.`]
                });
              }
              validations.push({
                type: "required",
                params: [`${name} is required`]
              });
            }
            return;
          }
          case "minLength": {
            if (validationType === "array") {
              validations.push({
                type: "min",
                params: [
                  updatedField[key],
                  `${name} must contain at least ${updatedField[key]} rows`
                ]
              });
            } else if (validationType === "number") {
              validations.push({
                type: "moreThan",
                params: [
                  updatedField[key] - 1,
                  ` ${name} cannot be lesser than ${updatedField[key]}`
                ]
              });
            } else {
              validations.push({
                type: "min",
                params: [
                  updatedField[key],
                  ` ${name} cannot be less than ${updatedField[key]} characters`
                ]
              });
            }
            return;
          }
          case "maxLength": {
            if (validationType === "array") {
              validations.push({
                type: "max",
                params: [
                  updatedField[key],
                  `${name} can not contain more then ${updatedField[key]} rows`
                ]
              });
            } else if (validationType === "number") {
              validations.push({
                type: "lessThan",
                params: [
                  updatedField[key] + 1,
                  ` ${name} cannot be greater than ${updatedField[key]}`
                ]
              });
            } else {
              validations.push({
                type: "max",
                params: [
                  updatedField[key],
                  ` ${name} cannot be greater than ${updatedField[key]} characters`
                ]
              });
            }
            return;
          }
          case "regex": {
            if (updatedField[key]) {
              let regex = [updatedField[key]];
              if (Array.isArray(updatedField[key]))
                regex = [...updatedField[key]];
              regex.forEach(rg => {
                validations.push({
                  type: "matches",
                  params: [
                    new RegExp(rg),
                    {
                      message: updatedField.helperText || `Invalid ${name}`,
                      excludeEmptyString: false
                    }
                  ]
                });
              });
            }
            return;
          }
          case "tags": {
            if (updatedField[key]) {
              let tags = [];
              if (Array.isArray(updatedField[key]))
                tags = [...updatedField[key]];

              tags.forEach(tag => {
                if (tag && !tag.code) {
                  tag = tagsDictionary[tag]?.values || {};
                }

                if (tag.regex) {
                  validations.push({
                    type: "matches",
                    params: [
                      new RegExp(tag.regex),
                      {
                        message: tag.message || `Invalid ${name}`,
                        excludeEmptyString: false
                      }
                    ]
                  });
                }
              });
            }
            return;
          }
          default:
            return;
        }
      }
    });

    return {
      ...field,
      ...overrides,
      id,
      label,
      placeholder,
      validationType,
      value,
      validations
    };
  });

  let yupSchema = formData.reduce(createYupSchema, {});
  yupSchema.gridFields = yup.object({ ...(yupSchema.gridFields || {}) });
  let validationSchema = yup.object().shape(yupSchema);

  return {
    fields: formData,
    validationSchema
  };
};

export const getRequiredMDMNames = fields => {
  if (!fields || !fields.length) {
    console.error("No fields were supplied to return MDM list.");
    return;
  }

  const excludedFieldCodes = [
    "dataType",
    "masterDataType",
    "belonging",
    "status"
  ];

  return fields.filter(
    field =>
      (field.dataType === 2 || field.dataType === 3) &&
      !excludedFieldCodes.includes(field.code)
  );
};

export const isParentField = (depLink, fieldCode) =>
  Boolean(depLink[fieldCode].dependents.length);
export const isDependentField = (depLink, fieldCode) =>
  Boolean(depLink.isDependent.find(fld => fld === fieldCode));
export const getParentFieldCodes = (depLink, fieldCode) => {
  let parentCodes = [];
  for (const [key, value] of Object.entries(depLink)) {
    if (key !== "isDependent") {
      if (value.dependents.find(fld => fld === fieldCode))
        parentCodes.push(key);
    }
  }
  return parentCodes;
};

const isIndependentField = (depLink, fieldCode) =>
  Boolean(
    !isParentField(depLink, fieldCode) && !isDependentField(depLink, fieldCode)
  );
const isIndependentParent = (depLink, fieldCode) =>
  Boolean(
    isParentField(depLink, fieldCode) && !isDependentField(depLink, fieldCode)
  );
const isDependentParent = (depLink, fieldCode) =>
  Boolean(
    isParentField(depLink, fieldCode) && isDependentField(depLink, fieldCode)
  );
const isDependentFieldOnly = (depLink, fieldCode) =>
  Boolean(
    !isParentField(depLink, fieldCode) && isDependentField(depLink, fieldCode)
  );

export const getDependencyStatus = (dependencyLinkage = {}, fieldCode = {}) => {
  if (isIndependentField(dependencyLinkage, fieldCode)) return 1;
  else if (isIndependentParent(dependencyLinkage, fieldCode)) return 2;
  else if (isDependentParent(dependencyLinkage, fieldCode)) return 3;
  else if (isDependentFieldOnly(dependencyLinkage, fieldCode)) return 4;

  return -1;
};

export const getFormattedDate = date => moment(date, "YYYYMMDDHHmmss ZZ");

export const isPendingList = url => url.toLowerCase().includes("/pending");

export const sanitizeInput = inputValues => {
  let tempValues = { ...inputValues };
  let arrayValueKeys = [],
    objectValueKeys = [];
  for (const [key, value] of Object.entries(initialProduct)) {
    if (Array.isArray(value)) arrayValueKeys.push(key);
    else if (typeof value === "object" && value !== null)
      objectValueKeys.push(key);
  }

  // const arrayValues = ["widgets", "recoveryCompanies", "quickViews", "externalSystemIdentifiers", "cycleCuts", "strategies", "queues", "dunningLetters", "notifications", "dedupings", "applicationCreationCriteria", "gridColumns", "discrepancies", "discrepancyRoleIds", "insuranceCompanyCodes", "rejectionRoleIds", ];

  Object.keys(inputValues).forEach(key => {
    let value = inputValues[key];
    if (key === "gracePeriod" && !value) tempValues[key] = null;
    else if (arrayValueKeys.includes(key) && !value) tempValues[key] = [];
    else if (objectValueKeys.includes(key) && !value) tempValues[key] = {};
    // else if (value === null || value === undefined) tempValues[key] = "";
  });
  return tempValues;
};
export const generateAcronym = (str = "N/A") => {
  if (str == null) str = "NA";

  const checkSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/g;

  if (checkSpecialCharacter.test(str)) {
    str = str.replace(/[^a-zA-Z ]/g, "");
  }

  return (/\s/g.test(str)
    ? str.charAt(0) + str.charAt(str.lastIndexOf(" ") + 1)
    : str.charAt(0)
  ).toUpperCase();
};

export const removeWhitespaces = data => {
  let tempData = { ...data };
  Object.keys(tempData).forEach(key => {
    const value = tempData[key];
    if (typeof value === "string") {
      tempData[key] = value.trim();
    }
  });
  return tempData;
};

export const getPermissionsObject = (currentModulePermissions = []) => {
  return currentModulePermissions.reduce(
    (obj, item) => ({
      ...obj,
      [item.toLowerCase()]: !!item
    }),
    {}
  );
};

export const getNumberInCurrencyFormat = ({
  number = 0,
  locales = "en-GB",
  currencyFormat = "PKR",
  appendCurrency = false,
  isWholeNumber = false
}) => {
  if (number?.toString() === "-") {
    return "-";
  }
  if (number === "N/A" || number === null || number === "") {
    return number;
  }

  const formatter = new Intl.NumberFormat(locales, {
    style: "currency",
    currency: currencyFormat
  });
  return `${appendCurrency ? currencyFormat + " " : ""}${formatter
    .formatToParts(number ? number : isWholeNumber ? 0.0 : 0)
    .map(item =>
      (!isWholeNumber
      ? item?.type !== "currency" &&
        item?.type !== "literal" &&
        item?.type !== "nan"
      : item?.type !== "currency" &&
        item?.type !== "literal" &&
        item?.type !== "decimal" &&
        item?.type !== "fraction" &&
        item?.type !== "nan")
        ? item?.value
        : ""
    )
    .join("")}`;
};

export const getFormattedNumber = number =>
  number !== "N/A" || !number === null
    ? new Intl.NumberFormat("us-US").format(number)
    : number;

export const getDictionaryForHeader = ({ groups }) => {
  let dictionary = {};
  //Iterate over the Groups that user have
  (groups || []).forEach(group => {
    // Iterate over the module permission for the current group
    (group?.modulePermissions || []).forEach(item => {
      // If dictionary already contains the code for permission then merge
      if (dictionary[item.moduleCode]?.length) {
        // Using lodash to merge permissions
        let dictionaryExistingPermissions = [...dictionary[item.moduleCode]];
        let currentGroupPermissions = [...item.permissions];
        dictionary[item.moduleCode] = lodash.union(
          dictionaryExistingPermissions,
          currentGroupPermissions
        );
      } else {
        // Else create new item in dictionary
        dictionary[item.moduleCode] = item.permissions;
      }
    });
  });
  return dictionary;
};

export const sortByAlphabeticalOrder = (list, sortKey = "name") =>
  (list || []).sort((a, b) => a[sortKey].localeCompare(b[sortKey]));

export const sortDropdownsOptions = (list, sortKey = "label") => {
  if (!list?.length) return list;

  const selectAllItem = list?.find(item => item.label === "Select All");
  const sortedList = list?.filter(item => item.label !== "Select All");

  let fa;
  let fb;
  let isOrderExist = false;

  let sortedByAlphabet = (sortedList || []).sort((a, b) => {
    if (!isNaN(a.value) && !isNaN(b.value)) {
      fa = a?.value;
      fb = b?.value;
    } else {
      fa = a?.label?.toLowerCase();
      fb = b?.label?.toLowerCase();
    }

    if (fa > fb) {
      return 1;
    }
    if (fa < fb) {
      return -1;
    }
    return 0;
  });
  if (selectAllItem) {
    sortedByAlphabet = [selectAllItem, ...sortedByAlphabet];
  } else {
    sortedByAlphabet = [...sortedByAlphabet];
  }

  let sortedByOrder = [...(sortedByAlphabet || [])].sort((a, b) => {
    if (a?.order > 0 || b?.order > 0) {
      fa = a?.order;
      fb = b?.order;
      isOrderExist = true;
    }

    if (fa < fb) {
      return -1;
    }

    if (fa > fb) {
      return 1;
    }

    return 0;
  });

  return isOrderExist ? sortedByOrder : sortedByAlphabet;
};

//Create/Edit Notification and action that has to be perfomred on it.
export const addEditDeleteNotificationAction = ({
  name,
  status,
  code,
  mode,
  resetClick,
  addMultiple,
  onClickNavigateBack,
  isPending,
  setDeleteItem,
  currentModule
}) => {
  if (status >= 200 && status <= 210) {
    if (mode === "delete") {
      successNotification(`${pluralize.singular(name)} deleted successfully.`);
      setDeleteItem(null);
    } else if (mode === "add" || mode === "clone") {
      successNotification(`${pluralize.singular(name)} created successfully.`);
      addMultiple && addMultiple
        ? resetClick()
        : onClickNavigateBack &&
          onClickNavigateBack(Boolean(currentModule?.isMakerCheckerEnabled));
    } else if (mode === "edit") {
      successNotification(`${pluralize.singular(name)} edited successfully.`);
      onClickNavigateBack &&
        onClickNavigateBack(Boolean(currentModule?.isMakerCheckerEnabled));
    }
  }
};

export const uppercaseFirstLetter = word =>
  lodash.startCase(lodash.toLower(word));

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const differenceInObjects = (object, base) => {
  function changes(object, base) {
    return lodash.transform(object, function(result, value, key) {
      if (!lodash.isEqual(value, base[key])) {
        result[key] =
          lodash.isObject(value) && lodash.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }

  return changes(object, base);
};

/**
 * safe function to get a valid json (if parsable), otherwise undefined is returned
 * can be later checked for truthy value by caller
 * @param  {String} str (expected JSON string)
 * @return {Object / Array} return a new object or array based on string
 * @return {undefined} return undefined if string isn't valid JSON
 */
export const IsJsonString = str => {
  if (!str?.length) return undefined;

  let temp = undefined;
  try {
    temp = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return temp;
};

const allowedDateFormats = [
  "DD-MM-YYYY",
  "DD-MMM-YYYY",
  "DD-MMMM-YYYY",
  "MM-DD-YYYY",
  "YYYY-MM-DD",
  "DD/MM/YYYY",
  "DD/MMM/YYYY",
  "DD/MMMM/YYYY",
  "MM/DD/YYYY",
  "YYYY/MM/DD"
];
// moment("2015-06-22T13:17:21+0000", formats, true).isValid(); // true
/**
 * safe function to get a valid date if string is one of the formats allowed
 * else will be instantly reset to prevent crashing behaviour of dates
 * @param  {String} date (expected date of allowed formats)
 * @param  {Boolean} isDatePickerInput (formats date in ISO format for acceptance in date picker component)
 * @return {Date} return a valid date based on string
 * @return {undefined} return undefined if string isn't valid date
 */
export const getValidMomentDate = (date, isDatePickerInput = false) => {
  if (!date?.length) return undefined;

  let currentFormat = allowedDateFormats.find(format =>
    moment(date, format, true).isValid()
  );
  if (!currentFormat) return moment(date, moment.defaultFormat).toDate();

  return isDatePickerInput
    ? moment.utc(date, currentFormat).toDate()
    : moment.utc(moment(date, currentFormat));
};

export const setDefaultValues = (currentField, fields) => {
  let data = {};
  let validationStatus = false;

  if (fields && currentField?.defaultValue) {
    if (currentField?.defaultValueExpression) {
      let jsonQuery = JSON.parse(
        findJSONQueryDefaultValueField(currentField?.defaultValueExpression)
      );
      validationStatus = validateJSONLogic(jsonQuery, fields);
    }

    const alwaysUpdatedDefaultValue = (currentField?.tags || []).find(
      tag =>
        tag === "alwaysUpdatedDefaultValue" ||
        tag?.code === "alwaysUpdatedDefaultValue"
    );
    const dayFromDate = (currentField?.tags || []).find(
      tag => tag === "dayFromDate" || tag?.code === "dayFromDate"
    );
    if (
      (validationStatus === true &&
        currentField?.defaultValueExpression?.representations?.length) ||
      !currentField?.defaultValueExpression?.representations?.length
    ) {
      if (currentField?.defaultValueType === defaultValueTypes.Static) {
        const fieldValue = fields?.[currentField?.code];
        data[currentField?.code] = alwaysUpdatedDefaultValue
          ? currentField?.defaultValue
          : fieldValue === 0
          ? fieldValue
          : fieldValue || currentField?.defaultValue;
      } else if (currentField?.defaultValueType === defaultValueTypes.Field) {
        const fieldValue = fields?.[currentField?.code];
        const getDefaultValueDay = new Date(
          fields?.[currentField?.defaultValue]
        )?.getDate();
        const defaultValue = dayFromDate
          ? !isNaN(getDefaultValueDay)
            ? getDefaultValueDay
            : fields?.[currentField?.defaultValue]
          : fields?.[currentField?.defaultValue];
        data[currentField?.code] = alwaysUpdatedDefaultValue
          ? defaultValue
          : fieldValue === 0
          ? fieldValue
          : fieldValue || defaultValue;
      }
    }
  }
  return data;
};

export const getCurrentlyActiveStage = (stages = []) =>
  stages.find(stg => stg.isCurrent);

export const findActiveRenderStage = (workflow = {}) => {
  if (workflow?.status === workflowStatus.Completed) {
    return Number(workflow?.stageDetails?.length) > 0
      ? workflow?.stageDetails?.[0]?.stageCode
      : null;
  } else {
    return (
      getCurrentlyActiveStage(workflow?.stageDetails)?.stageCode ||
      workflow?.stageDetails?.[
        Number(workflow?.stageDetails?.length) > 0
          ? Number(workflow?.stageDetails?.length) - 1
          : 0
      ]?.stageCode
    );
  }
};

export const generateGridFormatterConfig = ({
  dataField = "",
  dataType = 0,
  masterDataType = "",
  text = "",
  allRecords = {},
  showToolbar,
  showCopy,
  copyCode,
  isProductFactoryGrid = false,
  withSpan,
  showNameOnly = true,
  shouldFetchMDM = true,
  isCountColumn = false,
  sort,
  sortCaret,
  formatter,
  formatExtraData
}) => {
  let genericFormatter = {
    dataField,
    text,
    sort,
    sortCaret,
    formatter: formatter || ApplicationDynamicFormatter,
    formatExtraData: {
      ...formatExtraData,
      parameters: {
        identifier: dataField,
        dataType,
        masterDataType,
        allRecords,
        withSpan: withSpan || false,
        showNameOnly: showNameOnly || false,
        shouldFetchMDM: shouldFetchMDM || false,
        showToolbar: showToolbar || false,
        isCountColumn: isCountColumn || false,
        showCopy: showCopy || false,
        copyCode,
        isProductFactoryGrid: isProductFactoryGrid || false,
        ...formatExtraData?.parameters
      }
    }
  };
  if (showToolbar) genericFormatter.formatter = NameColumnFormatter;
  if (masterDataType && (dataType === 0 || !dataType))
    genericFormatter.formatExtraData.parameters.dataType = inputTypes.find(
      inputType => inputType.type === "select"
    )?.id;

  return genericFormatter;
};

export const removeEmptyParameters = payload => {
  return Object.fromEntries(
    Object.entries(payload).filter(
      ([_, v]) => ![null, undefined, ""].includes(v)
    )
  );
};

const fallbackCopyTextToClipboard = async text => {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let success = false;
  try {
    success = Boolean(document.execCommand("copy"));
  } catch (err) {}
  document.body.removeChild(textArea);
  return success;
};
export const copyTextToClipboard = async text => {
  if (!navigator.clipboard) {
    (await fallbackCopyTextToClipboard(text))
      ? successNotification(`${text} copied successfully`)
      : errorNotification(`${text} couldn't be copied.`);
  }
  navigator.clipboard.writeText(text).then(
    function() {
      return successNotification(`${text} copied successfully`);
    },
    function(err) {
      return errorNotification(`${text} couldn't be copied.`);
    }
  );
};

export const isItemActive = (itemStatus = 0) =>
  Boolean(
    itemStatus !==
      dropDownOptions.statuses.find(status => status.name === "Inactive")?.code
  );

export const createDotNotation = (
  dotString,
  dataKey,
  incomingDataType,
  incomingDataIndex
) => {
  //   if(incomingDataIndex > -1) return dotString + `${dotString.length ? "." : ""}${dataKey}[${incomingDataIndex}]`;
  //   else {
  //     switch (incomingDataType) {
  //       case "object": {
  //         return dotString + `${dotString.length ? "." : ""}${dataKey}`;
  //       }
  //       case "array": {
  //         // console.log({dotString, incomingDataType, incomingDataIndex, dataKey});
  //         // if(incomingDataIndex > -1) return  dotString + `${dotString.length ? "." : ""}${dataKey}[${incomingDataIndex}]`;
  //         return dotString + `${dotString.length ? "." : ""}${dataKey}`;
  //       }
  //       default: return '';
  //     }
  //   }
};
//
export const createFormNotation = (
  dotString,
  dataKey,
  incomingDataType,
  incomingDataIndex
) => {
  //   if(incomingDataIndex > -1) return dotString + `[${incomingDataIndex}]`;
  //   else {
  //
  //   }
  //
};

export const getValueFromObject = (
  object = {},
  keyString = "",
  defaultValue = undefined
) => {
  return keyString.length
    ? lodash.get(object, keyString, defaultValue || undefined)
    : object;
};

export const setValueFromObject = (
  object = {},
  keyString = "",
  value = undefined
) => {
  let tempObject = { ...(object || {}) };
  if (keyString === "") tempObject = { ...tempObject, ...value };
  else lodash.set(tempObject, keyString, value);
  return tempObject;
};

const hubURL = `${process.env.REACT_APP_API_URL}/identity/loginHub`;

export const hubConnection = (userToken, userId) => {
  const encryptedUserId = btoa(userId);

  let connection = new SignalR.HubConnectionBuilder()
    .withUrl(`${hubURL + "?state=" + encryptedUserId}`, {
      skipNegotiation: true,
      transport: SignalR.HttpTransportType.WebSockets
    })
    .withAutomaticReconnect([0, 10000, 60000, 90000, null])
    .configureLogging(SignalR.LogLevel.Debug)
    .build();

  return connection
    .start()
    .then(() => connection)
    .catch(error => error);
};

export const createSearchArray = (searchValue = "", data = []) => {
  let searchArray = data?.length > 0 ? [...data] : [];
  searchArray = searchArray.filter(
    item =>
      item?.code.toLowerCase().includes(searchValue?.toLowerCase()) ||
      item?.name.toLowerCase().includes(searchValue?.toLowerCase())
  );
  return searchArray;
};

export const passwordHelper = (helperText, min, max) => {
  helperText = helperText?.replace("{min}", min.toString());
  helperText = helperText?.replace("{max}", max.toString());
  return helperText;
};

//get the IP addresses associated with an account
export const getIPs = callback => {
  var ip_dups = {};
  var count = 0;

  //compatibility for firefox and chrome
  var RTCPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  // var useWebKit = !!window.webkitRTCPeerConnection;

  //bypass naive webrtc blocking using an iframe
  if (!RTCPeerConnection) {
    //NOTE: you need to have an iframe in the page right above the script tag
    //
    //<iframe id="iframe" sandbox="allow-same-origin" style="display: none"></iframe>
    //<script>...getIPs called in here...
    //
    var iframe = document.getElementById("iframe");
    var win = iframe.contentWindow;
    RTCPeerConnection =
      win.RTCPeerConnection ||
      win.mozRTCPeerConnection ||
      win.webkitRTCPeerConnection;
    // useWebKit = !!win.webkitRTCPeerConnection;
  }

  //minimal requirements for data connection
  var mediaConstraints = {
    optional: [{ RtpDataChannels: true }]
  };

  var servers = { iceServers: [{ urls: "stun:stun.services.mozilla.com" }] };

  //construct a new RTCPeerConnection
  var pc = new RTCPeerConnection(servers, mediaConstraints);

  function handleCandidate(candidate) {
    //match just the IP address
    var ip_regex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/;
    var ip_addr = ip_regex.exec(candidate)?.[1];

    //remove duplicates
    if (ip_dups[ip_addr] === undefined) {
      if (ip_addr) {
        ++count;
        callback({
          ip: ip_addr,
          name:
            count === 1
              ? "Ethernet adapter vEthernet (Default Switch)"
              : count === 2
              ? "Ethernet adapter vEthernet (WSL)"
              : count === 3
              ? "Wireless LAN adapter Wi-Fi 2"
              : "Not Available"
        });
      }
    }

    ip_dups[ip_addr] = true;
  }

  //listen for candidate events
  pc.onicecandidate = function(ice) {
    //skip non-candidate events
    if (ice.candidate) handleCandidate(ice.candidate.candidate);
  };

  //create a bogus data channel
  pc.createDataChannel("");

  //create an offer sdp
  pc.createOffer(
    function(result) {
      //trigger the stun server request
      pc.setLocalDescription(
        result,
        function() {},
        function() {}
      );
    },
    function() {}
  );

  //wait for a while to let everything done
  setTimeout(function() {
    //read candidate info from local description
    var lines = pc.localDescription.sdp.split("\n");

    lines.forEach(function(line) {
      if (line.indexOf("a=candidate:") === 0) handleCandidate(line);
    });
  }, 1000);
};

export const parseDropdownOptionsToRecords = data => {
  if (data) {
    return (
      Object.assign(
        ...Object.keys(data || {}).map(k => ({ [k]: { records: data[k] } }))
      ) || {}
    );
  }

  return {};
};

export const getFilterMoveToPreviousStages = (workflow, currentProduct) => {
  const productStages = currentProduct?.stages || [];
  const workflowStages = workflow?.stageDetails || [];
  let filteredStages = [];
  for (let index = 0; index < workflowStages?.length; index++) {
    const workflowStage = workflowStages?.[index];
    if (workflowStage.isCurrent) break;

    productStages.forEach(productStage => {
      if (
        productStage?.code === workflowStage?.stageCode &&
        !(productStage?.permissions || []).includes(
          "disabledMoveToPreviousStage"
        )
      ) {
        filteredStages.push(productStage);
      }
    });
  }
  return filteredStages;
};

export const arrayToObject = (items, parameter) => {
  let updatedItems = [];

  (items || []).forEach(item => {
    if (item[parameter]?.length) {
      updatedItems.push({
        ...item,
        [parameter]: item[parameter]?.[0] || {}
      });
    } else {
      updatedItems.push(item);
    }
  });

  return updatedItems;
};

export const objectToArray = (items, parameter) => {
  items = (items || []).map(item => {
    let updatedItem = item;

    if (Object.entries(item[parameter] || {})?.length) {
      updatedItem = {
        ...item,
        [parameter]: [item[parameter] || {}]
      };
    }

    return updatedItem;
  });

  return items;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const getOutcomeValueInDesiredFormat = (dataType, value) => {
  switch (inputTypesDictionary[dataType]?.type) {
    case "text":
      return [null, undefined].includes(value) ? "" : value + "";
    case "number":
      return [null, undefined].includes(value) ? undefined : Number(value);
    case "select":
      return value || "";
    case "multiselect":
      return value || [];

    default:
      return value;
  }
};

export const findJSONQuery = condition => {
  return (
    (condition?.expression?.representations || []).find(
      rep => queryBuilderQueryTypeEnums["JSONLogic"] === rep.representationType
    )?.representationValue || "{}"
  );
};

export const validateJSONLogic = (logic, data) => jsonLogic.apply(logic, data);

export const getAllRoutes = list => {
  let updatedRoutes = [];
  (list || []).forEach(item => {
    updatedRoutes.push(item?.route);

    if (item?.children?.length) {
      item.children.forEach(child => {
        updatedRoutes.push(child?.route);
      });
    }
  });
  return updatedRoutes || [];
};

export const findJSONQueryDefaultValueField = defaultValueExpression => {
  return (
    (defaultValueExpression?.representations || []).find(
      rep => queryBuilderQueryTypeEnums["JSONLogic"] === rep.representationType
    )?.representationValue || "{}"
  );
};

export const validateColorCode = value => {
  let error;
  const colorRegEx = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  if (value?.length > 0) {
    if (!value?.match(colorRegEx)) {
      error = "Please enter valid color code";
    }
  }
  return error;
};

export const convertDictionaryKeysToLowerCase = object => {
  const entries = Object.entries(object);

  return Object.fromEntries(
    entries.map(([key, value]) => {
      return [key.toLowerCase(), value];
    })
  );
};

export const replaceNotNumberValueWithZero = value => {
  if (isNaN(value) || !isFinite(value)) {
    return 0;
  } else {
    return Number(value || 0);
  }
};

export const getRecordName = (arr, code) => {
  const obj = (arr || []).find(obj => obj.code === code);
  return obj ? obj.name : null;
};

export const getMultiValuesNames = (arr, multiValues) => {
  return multiValues.map(code => getRecordName(arr, code)).join(", ");
};

export const imageExists = async imgUrl => {
  if (!imgUrl) {
    return false;
  }

  const http = new XMLHttpRequest();

  http.open("HEAD", imgUrl, false);
  http.send();

  return http.status !== 404;
};
export const checkDuplicateTenorUnitValues = arr => {
  const seenValues = new Set();

  for (let i = 0; i < arr.length; i++) {
    const { tenor, unit } = arr[i];
    const key = `${tenor}-${unit}`;

    if (seenValues.has(key)) {
      return true; // Duplicate values found
    }

    seenValues.add(key);
  }

  return false; // No duplicate values found
};

export const convertUniqueArrayOfObjects = (array, value) => {
  const uniqueObjectsSet = new Set(array.map(obj => obj[value]));
  const uniqueObjects = Array.from(uniqueObjectsSet, code =>
    array.find(obj => obj[value] === code)
  );

  return uniqueObjects;
};

export const checkDuplicateValueInObject = obj => {
  const valuesSet = new Set(Object.values(obj));
  return valuesSet.size !== Object.values(obj).length;
};
export const convertFields = (
  fieldsDictionary,
  oldValue,
  updatedValue,
  records,
  code,
  withSpan = true,
  fetchRecords,
  dispatch
) => {
  let field = fieldsDictionary[code];
  let fieldType = inputTypes.find(item => item.id === field?.dataType)?.type;
  let fieldName = field?.name;
  let fromValue = IsJsonString(oldValue) || oldValue;
  let toValue = IsJsonString(updatedValue) || updatedValue;
  if (field) {
    let masterDataType = field?.masterDataType;

    if (masterDataType) {
      let masterDataArr = records[masterDataType]?.records;

      if (Array.isArray(fromValue)) {
        fromValue = getMultiValuesNames(masterDataArr, fromValue);
        toValue = getMultiValuesNames(masterDataArr, toValue);
      } else {
        fromValue = getRecordName(masterDataArr, fromValue);
        toValue = getRecordName(masterDataArr, toValue);
      }
    }

    if (fieldType === "datePicker") {
      fromValue =
        fromValue && moment(fromValue)?.isValid()
          ? moment(fromValue)
              .format("LL")
              ?.toString()
          : "";
      toValue =
        toValue && moment(toValue)?.isValid()
          ? moment(toValue)
              .format("LL")
              ?.toString()
          : "";
    } else if (["timePicker", "dateTimePicker"].includes(fieldType)) {
      fromValue =
        fromValue && moment(fromValue)?.isValid()
          ? moment
              .utc(fromValue)
              .toDate()
              .toString()
          : "";
      toValue =
        toValue && moment(toValue)?.isValid()
          ? moment
              .utc(toValue)
              .toDate()
              .toString()
          : "";
    } else if (fieldType === "richTextBox") {
      fromValue = ReactHtmlParser(fromValue || "N/A");
      toValue = ReactHtmlParser(toValue || "N/A");
    } else if (fieldType === "checkbox") {
      fromValue = Boolean(fromValue) ? "Yes" : "No";
      toValue = Boolean(toValue) ? "Yes" : "No";
    } else if (fieldType === "conditionBuilder") {
      fromValue =
        (fromValue?.representations || []).find(
          rep => rep.representationType === queryBuilderQueryTypeEnums["SQL"]
        )?.representationValue || "N/A";
      toValue =
        (toValue?.representations || []).find(
          rep => rep.representationType === queryBuilderQueryTypeEnums["SQL"]
        )?.representationValue || "N/A";
    }

    let { tags = [] } = field;

    if (!tags) tags = [];

    if ((tags || []).find(tg => tg === "maskedCNIC")) {
      fromValue = fromValue || "N/A";
      toValue = toValue || "N/A";
    } else {
      if ((tags || []).find(tg => tg === "FormattedNumber")) {
        fromValue = getFormattedNumber(fromValue);
        toValue = getFormattedNumber(toValue);
      }
      if ((tags || []).find(tg => tg === "amount")) {
        fromValue = getNumberInCurrencyFormat({
          number: fromValue,
          appendCurrency: true,
          isWholeNumber: (tags || []).find(item => item === "WholeNumber")
        });
        toValue = getNumberInCurrencyFormat({
          number: toValue,
          appendCurrency: true,
          isWholeNumber: (tags || []).find(item => item === "WholeNumber")
        });
      }
    }
  }
  return { fieldName, fromValue, toValue };
};

export const convertToOrdinal = number => {
  if (number >= 11 && number <= 13) {
    return `${number}th`;
  } else {
    switch (number % 10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  }
};

export const formatKeyToUpperCase = key => {
  return convertFirstToUpperCase(
    key
      .split(/(?=[A-Z])/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
  );
};

export const capitalizeString = str => {
  return str.replace(/\b\w/g, function(char) {
    return char.toUpperCase();
  });
};

export const isValueEmpty = value => {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    (typeof value === "string" && value?.trim() === "")
  );
};

export const replaceEmptyStringWithNull = obj => {
  for (let key in obj) {
    if (obj[key] === "") {
      obj[key] = null;
    }
  }
  return obj;
};

export const generateShortUUID = (length = 10) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const countHiddenFalseFields = (array, obj) => {
  return array?.reduce((count, field) => {
    if (obj[field] && obj[field]?.isHidden === false) {
      count++;
    }
    return count;
  }, 0);
};
