import CryptoJS from "crypto-js";
import moment from "moment";
import { Status, Type } from "../../_custom/_partials/grids/UIHelpers";

export const xOptionalUserIdGenerator = isCheckModules => {
  const currentDateTime = moment()
    .utc()
    .format("YYYYMMDDHHmmss");
  const result = `q4b71yha-${currentDateTime}-${isCheckModules ||
    false}-awsd8djq`;

  const key = CryptoJS.enc.Utf8.parse("xy52nc9sqbTvw4op");
  const iv = CryptoJS.enc.Utf8.parse("7061737323313233");
  const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(result), key, {
    keySize: 128 / 8,
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });

  return encrypted.toString();
};

export const createDictionary = (list = [], param) => {
  let dictionary = {};

  list.forEach(item => {
    dictionary = {
      ...dictionary,
      [item[param]]: item
    };
  });

  return dictionary;
};

export const calculateMonths = (fromDate, toDate, calculationType) => {
  let months = 0;

  if (fromDate && toDate) {
    const startDate = moment(
      moment(new Date(fromDate)).format("YYYYMMDD") + "000000",
      "YYYYMMDDHHmmss"
    );
    const maturityDate = moment(
        moment(new Date(toDate)).format("YYYYMMDD") + "000000",
        "YYYYMMDDHHmmss"
    );
    const endDate = calculationType === "S" ? moment(
        moment(new Date(toDate)).format("YYYYMMDD") + "000000",
        "YYYYMMDDHHmmss"
    ).add(1, "day") : moment(
      moment(new Date(toDate)).format("YYYYMMDD") + "000000",
      "YYYYMMDDHHmmss"
    ).add(5, "hours");

    const duration = moment.duration(endDate.diff(startDate));
    const totalMonths = calculationType === "S" ? endDate.diff(startDate, "months") : Number(duration.asMonths().toFixed(2));
    months = totalMonths;

    if (calculationType === "S" && maturityDate.isAfter(startDate)) {
      if (totalMonths < 1) {
        months = 1;
      }
    }
  }

  return months;
};

export const PMT = (ir, np, pv, fv, type) => {
  var pmt, pvif;

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) return -(pv + fv) / np;

  pvif = Math.pow(1 + ir, np);
  pmt = (-ir * pv * (pvif + fv)) / (pvif - 1);

  if (type === 1) pmt /= 1 + ir;

  return pmt;
};

export const roundUp = (figure, precision) => {
  return Math.ceil(figure * Math.pow(10, precision)) / Math.pow(10, precision);
};

export const calculateEMI = (markup, tenure, loanAmount, calculationType) => {
  let rate = calculationType === "S" ? markup / 12 / 100: roundUp(((markup / 365) * 360) / 12 / 100, 8);
  let presentValue = -1;
  let futureValue = 0;
  let pmt = PMT(rate, tenure, presentValue, futureValue, 0);
  let factor = roundUp(pmt, 6);
  let emi = calculationType === 'S' ? Math.round(pmt * loanAmount) : Math.round(factor * loanAmount);

  return emi || 0;
};

export const calculateEMIPercentageBase = (
  rate,
  loanAmount = 0,
  utilized = 0,
  amountBasedOn
) => {
  let amount = loanAmount;
  if (amountBasedOn === "2" || amountBasedOn === "" || amountBasedOn === null) {
    amount = Math.max(loanAmount, utilized);
  }

  let emi = (amount * rate) / 100;

  return emi || 0;
};

export const calculateMaturity = (maturityDate, utilized = 0) => {
  let isMature = false;

  if (maturityDate) {
    if (
      moment(new Date(maturityDate)).startOf('day').isBefore(moment().startOf('day')) &&
      utilized === 0
    ) {
      isMature = true;
    } else {
      isMature = false;
    }
  }

  return isMature;
};

export const calculateMaturityEMI = (maturityDate) => {
  let isMature = false;

  if (maturityDate) {
    if (
        moment(new Date(maturityDate)).startOf('day').isBefore(moment().startOf('day'))
    ) {
      isMature = true;
    } else {
      isMature = false;
    }
  }

  return isMature;
};

export const calculateExposure = (
  amount = 0,
  utilized = 0,
  exposure,
  calculationBased
) => {
  if (calculationBased === "EMI") {
    return amount + exposure;
  } else {
    return Math.max(amount, utilized) + exposure;
  }
};

export const calculateExposureParameters = (
  productFamiliesDictionary,
  previousLoans = [],
  currentLoanAmount = 0,
  currentLoanEmi = 0,
  emiOnSalarySlip = 0,
  isSecured
) => {
  let securedExposure = 0;
  let unsecuredExposure = 0;
  let securedEmi = 0;
  let unsecuredEmi = 0;
  let aggregateEmi = 0;
  let grossPayable = 0;
  let currentProductFamily = {};

  currentLoanEmi = ~~Number(currentLoanEmi);
  emiOnSalarySlip = ~~Number(emiOnSalarySlip);

  previousLoans.forEach(item => {
    currentProductFamily = productFamiliesDictionary?.[item.productFamily];

    // console.log(item)
    if (item.isSecured) {
      securedExposure = calculateExposure(
        item.amount,
        item.utilized,
        securedExposure,
        currentProductFamily?.values?.CalculationBased
      );
      securedEmi = item.emi ? item.emi + securedEmi : securedEmi;
    } else {
      unsecuredExposure = calculateExposure(
        item.amount,
        item.utilized,
        unsecuredExposure,
        currentProductFamily?.values?.CalculationBased
      );
      unsecuredEmi = item.emi ? item.emi + unsecuredEmi : unsecuredEmi;
    }
    aggregateEmi = item.emi ? item.emi + aggregateEmi : aggregateEmi;
  });

  grossPayable = currentLoanEmi + aggregateEmi + emiOnSalarySlip;

  if (isSecured) {
    securedExposure = securedExposure + ~~Number(currentLoanAmount);
    securedEmi = securedEmi + currentLoanEmi;
  } else {
    unsecuredExposure = unsecuredExposure + ~~Number(currentLoanAmount);
    unsecuredEmi = unsecuredEmi + currentLoanEmi;
  }

  return {
    currentLoanEmi,
    securedExposure,
    unsecuredExposure,
    securedEmi,
    unsecuredEmi,
    aggregateEmi,
    emiOnSalarySlip,
    grossPayable
  };
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const isRateOverride = (
  productFamily,
  currentRate,
  productFamiliesDictionary
) => {
  return (
    Number(productFamiliesDictionary?.[productFamily]?.values?.Rate || 0) !==
    currentRate
  );
};

export const keyboardShortcutsListener = ({ saveCallback, resetCallback }) => {
  document.onkeydown = e => {
    // if (e.ctrlKey && e.key === 'z') {
    //   e.preventDefault();
    //   resetCallback();
    // }
    if (e.ctrlKey && e.key === "s") {
      e.preventDefault();
      saveCallback();
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 83) {
      e.preventDefault();
      saveCallback(true);
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === 90) {
      e.preventDefault();
      resetCallback();
    }
  };
};

export const checkHiddenCondition = (dataForEdit, permissionsObject) => {
  if (permissionsObject?.update) {
    return !(
      (dataForEdit?.status === Status.Approved &&
        !dataForEdit?.isBlocked &&
        (dataForEdit?.type === Type.Active ||
          dataForEdit?.type === Type.AwaitingActivation)) ||
      dataForEdit?.status === Status.Draft
    );
  } else {
    return true;
  }
};

export const convertCamelCaseToNormalCase = text => {
  const result = text?.replace(/([A-Z])/g, " $1");
  return result?.charAt(0)?.toUpperCase() + result?.slice(1);
};

export const generateRandomString = length => {
  const charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    result += charset.charAt(randomIndex);
  }
  return result;
};
