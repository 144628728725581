import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { chunk } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { imageForDocument } from "../../grids/UIHelpers";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { GenericFileUpload } from "./GenericFileUpload";
import { Overlay } from "./Overlay";
import { CircularProgress } from "@material-ui/core";
import moment from "moment/moment";

export function Thumbnails({
  documents,
  loadMore,
  handleDownload,
  handleFileSelected,
  uploadingFile,
  isAllowUpload,
  viewType,
  handleCheckbox,
  acceptFileType,
  maxSizeOfFile,
  handleDelete,
  userId,
  permissionsObject,
  isWorkflowRoute,
  handleOpenDocumentInNewTab,
  isDocumentOpening,
  isEligibleForReUploadMyDocument,
  isEligibleForReUploadOthersDocument,
  handleOpenDocumentInNewWindow,
  setOpenInTabOrWindow,
  openInTabOrWindow,
  listLoading,
  totalCount,
  allUsers,
  documentLoader
}) {
  const [combinedDocuments, setCombinedDocuments] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (documents?.length) {
      setCombinedDocuments(chunk(documents, 2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents]);

  const handleFilter = e => {
    let filteredDocuments = [...(documents || [])];
    filteredDocuments = filteredDocuments.filter(function(data) {
      return (
        data.groupName
          ?.toLowerCase()
          ?.search(e.target.value && e.target.value.toLowerCase()) !== -1 ||
        data.code
          ?.toLowerCase()
          ?.search(e.target.value && e.target.value.toLowerCase()) !== -1
      );
    });

    setSearchValue(e.target.value);
    setCombinedDocuments(chunk(filteredDocuments, 2));
  };
  return (
    <>
      {documentLoader || !(documents?.length > 0) ? (
        <div className="text-center">
          <CircularProgress className="spinner-lg" />
        </div>
      ) : (
        <div style={{ height: 430 }}>
          <div className="form-group has-search">
            <span className="fa fa-search form-control-feedback"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              value={searchValue}
              onChange={e => handleFilter(e)}
            />
          </div>
          <InfiniteScroll
            dataLength={combinedDocuments.length}
            next={loadMore}
            hasMore={true}
            loader={false}
            scrollableTarget="scrollableDiv"
          >
            <div className="row">
              {combinedDocuments?.map(row => (
                <>
                  {row?.map(document => {
                    let creator =
                      (allUsers || []).find(
                        item => item?.id === document?.createdBy
                      )?.name || "N/A";

                    let updater =
                      (allUsers || []).find(
                        item => item?.id === document?.updatedBy
                      )?.name || "N/A";

                    const image =
                      imageForDocument.find(
                        a => a?.extension === document?.extension
                      )?.image || "/media/svg/files/default.svg";

                    return (
                      <div key={document.id} className="col-lg-6 md-6 sm-12">
                        <div className="card card-custom gutter-b card-stretch ribbon ribbon-right">
                          <div
                            className="ribbon-target bg-primary"
                            style={{
                              right: "-2px",
                              borderTopRightRadius: "6px"
                            }}
                          >
                            <small className="text-white">
                              Version {document.versionCount}
                            </small>
                          </div>

                          {/*</div>*/}
                          <div className="card-body p-5 d-flex flex-column justify-content-between">
                            <div className="d-flex align-items-center">
                              <label
                                className="checkbox"
                                style={{
                                  position: "absolute",
                                  top: "26px",
                                  left: "26px",
                                  zIndex: "4"
                                }}
                              >
                                <input
                                  type="checkbox"
                                  onClick={event => {
                                    handleCheckbox(
                                      document,
                                      event?.target?.checked
                                    );
                                  }}
                                />
                                <span
                                  style={{ border: "1px solid gray" }}
                                ></span>
                              </label>

                              {document?.versionCount > 1 ? (
                                <Dropdown drop={"right"} className="d-inline">
                                  <Dropdown.Toggle
                                    style={{
                                      background: "none",
                                      border: "none",
                                      padding: "0px"
                                    }}
                                    id={document?.code}
                                  >
                                    <div
                                      className="symbol  mr-3 align-self-start align-self-xxl-center"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <div
                                        className="symbol-label"
                                        style={{
                                          border: "2px solid #e5e5e5",
                                          overflow: "hidden",
                                          height: "120px",
                                          width: "120px"
                                        }}
                                      >
                                        {document.thumbnail ? (
                                          <img
                                            src={`data:image/png;base64, ${document.thumbnail}`}
                                            style={{ width: "100%" }}
                                            alt={document.name}
                                          />
                                        ) : (
                                          <img
                                            src={toAbsoluteUrl(image)}
                                            width="45"
                                            alt={document.name}
                                          />
                                        )}
                                      </div>
                                    </div>
                                    {/*  <i className="fas fa-file-download text-white"/>*/}
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {new Array(document?.versionCount)
                                      .fill(undefined)
                                      .map((value, index) => {
                                        const versionNumber = index + 1;
                                        const isDisabled = document?.deletedVersions.includes(
                                          versionNumber
                                        );
                                        return (
                                          <Dropdown.Item
                                            onClick={() => {
                                              if (!isDisabled) {
                                                handleDownload(
                                                  document,
                                                  index + 1,
                                                  true
                                                );
                                              }
                                            }}
                                            style={{
                                              pointerEvents: isDisabled
                                                ? "none"
                                                : "auto",
                                              opacity: isDisabled ? 0.6 : 1
                                            }}
                                          >
                                            {isDisabled
                                              ? `Version ${versionNumber} (Deleted)`
                                              : `Version ${versionNumber}`}
                                          </Dropdown.Item>
                                        );
                                      })}
                                  </Dropdown.Menu>
                                </Dropdown>
                              ) : (
                                <div
                                  className="symbol symbol-130 mr-3 align-self-start align-self-xxl-center"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    document["group"] = document.groupName;
                                    handleDownload(document, 1, true);
                                  }}
                                >
                                  <div
                                    className="symbol-label"
                                    style={{
                                      border: "1px solid #e5e5e5",
                                      overflow: "hidden"
                                    }}
                                  >
                                    {document.thumbnail ? (
                                      <img
                                        src={`data:image/png;base64, ${document.thumbnail}`}
                                        style={{ width: "100%" }}
                                        alt={document.name}
                                      />
                                    ) : (
                                      <img
                                        src={toAbsoluteUrl(image)}
                                        width="45"
                                        alt={document.name}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}

                              <div className="w-100">
                                <div
                                  style={{ maxWidth: "65%" }}
                                  className="font-weight-bolder text-dark-75 text-hover-primary"
                                >
                                  <h5
                                    style={{
                                      display: "inline-block",
                                      minWidth: "auto",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "100%"
                                    }}
                                  >
                                    {document?.name}
                                  </h5>
                                </div>
                                <h1
                                  className="badge bg-primary mt-n2"
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    display: "inline-block",
                                    maxWidth: "200px",
                                    color: "white"
                                  }}
                                >
                                  <span className="text-white">
                                    {document.groupName}
                                  </span>
                                </h1>

                                {document.groupName?.length > 25 ? (
                                  <Overlay popoverContent={document.groupName}>
                                    <i
                                      className={
                                        "fas fa-info-circle icon-nm text-hover-primary my-2 b-0"
                                      }
                                      style={{
                                        padding: "2px",
                                        position: "relative",
                                        top: "-12px",
                                        left: "5px"
                                      }}
                                    />
                                  </Overlay>
                                ) : null}

                                <div className="d-block mt-n2 mb-1">
                                  <h1 className="badge bg-light-primary d-inline-block">
                                    <span className="text-primary">
                                      Uploaded from{" "}
                                      {document.documentType === 0
                                        ? "Checklist"
                                        : document.documentType === 2
                                        ? "Field"
                                        : "Documents"}
                                    </span>
                                  </h1>
                                </div>

                                <div className="w-100 mt-2">
                                  {isAllowUpload ? (
                                    <GenericFileUpload
                                      acceptFileType={acceptFileType}
                                      code={document?.code}
                                      documentOwner={document?.ownership}
                                      isEligibleForReUploadMyDocument={
                                        isEligibleForReUploadMyDocument
                                      }
                                      isEligibleForReUploadOthersDocument={
                                        isEligibleForReUploadOthersDocument
                                      }
                                      handleFileSelected={handleFileSelected}
                                      uploadingFile={uploadingFile}
                                      viewType={viewType}
                                      maxSizeOfFile={maxSizeOfFile}
                                      groupCode={document?.groupCode}
                                      version={document?.versionCount}
                                    />
                                  ) : null}

                                  {document?.versionCount > 1 ? (
                                    <Dropdown
                                      drop={"start"}
                                      className="d-inline"
                                    >
                                      <Dropdown.Toggle
                                        className="btn btn-outline-warning btn-sm ml-2"
                                        id={document?.code}
                                      >
                                        Download
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        {new Array(document?.versionCount)
                                          .fill(undefined)
                                          .map((value, index) => {
                                            const versionNumber = index + 1;
                                            const isDisabled = document?.deletedVersions.includes(
                                              versionNumber
                                            );
                                            return (
                                              <Dropdown.Item
                                                onClick={() => {
                                                  if (!isDisabled) {
                                                    handleDownload(
                                                      document,
                                                      index + 1
                                                    );
                                                  }
                                                }}
                                                style={{
                                                  pointerEvents: isDisabled
                                                    ? "none"
                                                    : "auto",
                                                  opacity: isDisabled ? 0.6 : 1
                                                }}
                                              >
                                                {isDisabled
                                                  ? `Version ${versionNumber} (Deleted)`
                                                  : `Version ${versionNumber}`}
                                              </Dropdown.Item>
                                            );
                                          })}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  ) : null}

                                  {document?.versionCount > 1 ? (
                                    <div className="dropdown dropdown-inline ml-3">
                                      <button
                                        type="button"
                                        className="btn btn-light-primary btn-icon btn-sm"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip
                                              id={`fullscreen-tooltip-${document?.code}`}
                                            >
                                              Open in new tab
                                            </Tooltip>
                                          }
                                        >
                                          {isDocumentOpening ===
                                            document?.code &&
                                          openInTabOrWindow === "tab" ? (
                                            <div className="btn btn-sm btn-light-primary btn-hover-light-primary">
                                              <span className="btn btn-sm btn-light-primary btn-hover-light-primary spinner spinner-md spinner-primary"></span>
                                            </div>
                                          ) : (
                                            <button
                                              className="btn btn-sm btn-light-primary"
                                              disabled={
                                                isDocumentOpening !== ""
                                              }
                                            >
                                              <i className="fas fa-external-link-alt m-0 p-0"></i>
                                            </button>
                                          )}
                                        </OverlayTrigger>
                                      </button>
                                      <div className="dropdown-menu">
                                        {new Array(document?.versionCount)
                                          .fill(undefined)
                                          .map((value, index) => {
                                            const versionNumber = index + 1;
                                            const isDisabled = document?.deletedVersions.includes(
                                              versionNumber
                                            );
                                            return (
                                              <Dropdown.Item
                                                onClick={() => {
                                                  if (!isDisabled) {
                                                    handleOpenDocumentInNewTab(
                                                      document,
                                                      index + 1
                                                    );
                                                    setOpenInTabOrWindow("tab");
                                                  }
                                                }}
                                                style={{
                                                  pointerEvents: isDisabled
                                                    ? "none"
                                                    : "auto",
                                                  opacity: isDisabled ? 0.6 : 1
                                                }}
                                                key={`version-${index}`}
                                              >
                                                {isDisabled
                                                  ? `Version ${versionNumber} (Deleted)`
                                                  : `Version ${versionNumber}`}
                                              </Dropdown.Item>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  ) : null}

                                  {document?.versionCount > 1 ? (
                                    <div className="dropdown dropdown-inline ml-4">
                                      <button
                                        type="button"
                                        className="btn btn-light-warning btn-icon btn-sm"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip
                                              id={`fullscreen-tooltip-${document?.code}`}
                                            >
                                              Open in new window
                                            </Tooltip>
                                          }
                                        >
                                          {isDocumentOpening ===
                                            document?.code &&
                                          openInTabOrWindow === "window" ? (
                                            <div className="btn btn-sm btn-light-warning btn-hover-light-warning">
                                              <span className="btn btn-sm btn-light-warning btn-hover-light-warning spinner spinner-md spinner-warning"></span>
                                            </div>
                                          ) : (
                                            <button
                                              className="btn btn-sm btn-light-warning"
                                              disabled={
                                                isDocumentOpening !== ""
                                              }
                                            >
                                              <i className="fas fa-window-restore m-0 p-0"></i>
                                            </button>
                                          )}
                                        </OverlayTrigger>
                                      </button>
                                      <div className="dropdown-menu">
                                        {new Array(document?.versionCount)
                                          .fill(undefined)
                                          .map((value, index) => {
                                            const versionNumber = index + 1;
                                            const isDisabled = document?.deletedVersions.includes(
                                              versionNumber
                                            );
                                            return (
                                              <Dropdown.Item
                                                onClick={() => {
                                                  if (!isDisabled) {
                                                    handleOpenDocumentInNewWindow(
                                                      document,
                                                      index + 1
                                                    );
                                                    setOpenInTabOrWindow(
                                                      "window"
                                                    );
                                                  }
                                                }}
                                                style={{
                                                  pointerEvents: isDisabled
                                                    ? "none"
                                                    : "auto",
                                                  opacity: isDisabled ? 0.6 : 1
                                                }}
                                                key={`version-${index}`}
                                              >
                                                {isDisabled
                                                  ? `Version ${versionNumber} (Deleted)`
                                                  : `Version ${versionNumber}`}
                                              </Dropdown.Item>
                                            );
                                          })}
                                      </div>
                                    </div>
                                  ) : null}

                                  {/*<button className="btn btn-outline-danger">Button danger</button>*/}
                                  {document?.versionCount === 1 ? (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleDownload(document, 1);
                                      }}
                                      className="btn btn-outline-warning btn-sm ml-2"
                                    >
                                      Download
                                      {/*<i className="fas fa-file-download text-white"/>*/}
                                    </button>
                                  ) : null}
                                  {document?.versionCount === 1 ? (
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="fullscreen-tooltip">
                                          Open in new tab
                                        </Tooltip>
                                      }
                                    >
                                      {isDocumentOpening === document?.code &&
                                      openInTabOrWindow === "tab" ? (
                                        <div className="btn btn-sm btn-light-primary btn-hover-light-primary ml-2">
                                          <span className="btn btn-sm btn-light-primary btn-hover-light-primary spinner spinner-md spinner-primary"></span>
                                        </div>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            setOpenInTabOrWindow("tab");
                                            handleOpenDocumentInNewTab(
                                              document,
                                              1
                                            );
                                          }}
                                          className="btn btn-sm btn-light-primary ml-2"
                                          disabled={isDocumentOpening !== ""}
                                        >
                                          <i className="fas fa-external-link-alt m-0 p-0"></i>
                                        </button>
                                      )}
                                    </OverlayTrigger>
                                  ) : null}

                                  {document?.versionCount === 1 ? (
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="fullscreen-tooltip">
                                          Open in new window
                                        </Tooltip>
                                      }
                                    >
                                      {isDocumentOpening === document?.code &&
                                      openInTabOrWindow === "window" ? (
                                        <div className="btn btn-sm btn-light-warning btn-hover-light-warning ml-2">
                                          <span className="btn btn-sm btn-light-warning btn-hover-light-warning spinner spinner-md spinner-warning"></span>
                                        </div>
                                      ) : (
                                        <button
                                          onClick={() => {
                                            setOpenInTabOrWindow("window");
                                            handleOpenDocumentInNewWindow(
                                              document,
                                              1
                                            );
                                          }}
                                          className="btn btn-sm btn-light-warning ml-2"
                                          disabled={isDocumentOpening !== ""}
                                        >
                                          <i className="fas fa-window-restore m-0 p-0"></i>
                                        </button>
                                      )}
                                    </OverlayTrigger>
                                  ) : null}
                                  {isWorkflowRoute &&
                                    permissionsObject?.deletedocument &&
                                    document?.createdBy === userId &&
                                    (document?.versionCount > 1 ? (
                                      <Dropdown
                                        drop={"start"}
                                        className="d-inline"
                                      >
                                        <Dropdown.Toggle
                                          className="btn btn-outline-danger btn-sm ml-2"
                                          id={document?.code}
                                        >
                                          Delete
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => {
                                              handleDelete(document);
                                            }}
                                          >
                                            All Versions
                                          </Dropdown.Item>
                                          {new Array(document.versionCount)
                                            .fill(undefined)
                                            .map((_, index) => {
                                              const versionNumber = index + 1;
                                              const isDisabled = document?.deletedVersions.includes(
                                                versionNumber
                                              );

                                              return (
                                                <Dropdown.Item
                                                  key={index}
                                                  onClick={() => {
                                                    if (!isDisabled) {
                                                      handleDelete(
                                                        document,
                                                        versionNumber
                                                      );
                                                    }
                                                  }}
                                                  style={{
                                                    pointerEvents: isDisabled
                                                      ? "none"
                                                      : "auto",
                                                    opacity: isDisabled
                                                      ? 0.6
                                                      : 1
                                                  }}
                                                >
                                                  {isDisabled
                                                    ? `Version ${versionNumber} (Deleted)`
                                                    : `Version ${versionNumber}`}
                                                </Dropdown.Item>
                                              );
                                            })}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    ) : (
                                      <button
                                        className="btn btn-outline-danger btn-sm ml-2"
                                        onClick={() => {
                                          handleDelete(document);
                                        }}
                                      >
                                        Delete
                                      </button>
                                    ))}
                                </div>
                              </div>
                            </div>
                            <div className="card-footer p-2 d-flex justify-content-between align-items-center">
                              <div className="p-3 rounded shadow-sm flex-grow-1 mr-2">
                                <h6 className="text-muted">Created</h6>
                                <span className="text-success font-weight-bold font-size-lg">
                                  {moment(document?.createdAt).format(
                                    "DD MMMM YYYY, hh:mm a"
                                  )}
                                </span>
                                <Overlay popoverContent={creator}>
                                  <span className="text-dark text-hover-success cursor-pointer font-weight-bold mt-2 ml-4">
                                    {creator}
                                  </span>
                                </Overlay>
                              </div>

                              <div className="p-3 rounded shadow-sm flex-grow-1 ml-2">
                                <h6 className="text-muted">Updated</h6>
                                <span className="text-primary font-weight-bold font-size-lg">
                                  {moment(document?.lastUpdated).format(
                                    "DD MMMM YYYY, hh:mm a"
                                  )}
                                </span>
                                <Overlay popoverContent={updater}>
                                  <span className="text-dark text-hover-success cursor-pointer font-weight-bold mt-2 ml-4">
                                    {updater}
                                  </span>
                                </Overlay>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ))}
            </div>
          </InfiniteScroll>
          {listLoading && documents?.length !== totalCount && (
            <div className="text-center">
              <CircularProgress className="text-primary spinner-lg" />
            </div>
          )}
        </div>
      )}
    </>
  );
}
